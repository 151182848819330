import { SVGProps } from 'react';
const SvgIncorrectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M7.5 0C8.52 0 9.495 0.195 10.425 0.585C11.315 0.964999 12.105 1.505 12.795 2.205C13.495 2.895 14.035 3.685 14.415 4.575C14.805 5.505 15 6.48 15 7.5C15 8.52 14.805 9.495 14.415 10.425C14.035 11.315 13.495 12.105 12.795 12.795C12.105 13.495 11.315 14.035 10.425 14.415C9.495 14.805 8.52 15 7.5 15C6.48 15 5.505 14.805 4.575 14.415C3.685 14.035 2.89 13.495 2.19 12.795C1.5 12.105 0.965 11.315 0.585 10.425C0.195 9.495 0 8.52 0 7.5C0 6.48 0.195 5.505 0.585 4.575C0.965 3.685 1.5 2.895 2.19 2.205C2.89 1.505 3.685 0.964999 4.575 0.585C5.505 0.195 6.48 0 7.5 0ZM11.175 9.84L8.82 7.5L11.175 5.16L9.84 3.825L7.5 6.18L5.16 3.825L3.825 5.16L6.18 7.5L3.825 9.84L5.16 11.175L7.5 8.82L9.84 11.175L11.175 9.84Z"
      fill="#E0301E"
    />
  </svg>
);
export default SvgIncorrectIcon;
