import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { usePaginationHelper } from '@app/core/hooks/customUsePaginationHelper';
import { SearchParamKey } from '@app/shared/models/search-param-enum';
import { enumKeyByValue } from '@app/shared/helpers';
import { OrderType, Role } from '@app/shared/models/contracts/enums/shared-enums';
import { featureAvailabilityWithRegions } from '@app/core/utils/feature-availability';
import { withDebounce } from '@app/shared/helpers/withDebounce';
import styles from './user-management.module.scss';
import { SearchField } from '@app/shared/components/form-controls/search-field/SearchField';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { SEARCH_MIN_CHAR_COUNT } from '@app/shared/constants/configs/search';
import * as userListActions from '@core/store/user-list-slice';
import { Card } from '@app/shared/components/card/Card';
import { CardCornersVariation, CardVariation } from '@app/shared/components/card/card-enums';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { Alignment } from '@app/shared/enums/alignment.enum';
import TablePagination from '@app/shared/components/table/TablePagination';
import SvgSortingAscendant from '@app/shared/icons/SortingAscendant';
import SvgSorting from '@app/shared/icons/Sorting';
import SvgEditUser from '@app/shared/icons/EditUser';
import SvgEventDelete from '@app/shared/icons/EventDelete';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import SvgPlus from '@app/shared/icons/Plus';
import SvgMinusRounded from '@app/shared/icons/MinusRounded';
import { useLocale } from '@app/core/hooks/useLocale';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import { RouteConstants } from '../RouteConstants';

const PARENT_CLASSNAME = 'user-management';

type UserListFormModel = {
  search: string;
};

export const UserManagement: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { navigate } = usePwcNavigate();
  const {
    searchParams,
    checkIfItemsAreOutOfRange,
    initializePagination,
    getPagedRequestParams,
    setSearchParams,
  } = usePaginationHelper();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.userInfo);

  const formMethods = useForm<UserListFormModel>({
    ...formConfigBase,
    defaultValues: {
      search: searchParams.get(SearchParamKey.Search) ?? '',
    },
  });

  const { getValues, handleSubmit } = formMethods;

  const currentPage = searchParams.get(SearchParamKey.CurrentPage);
  const searchQuery = searchParams.get(SearchParamKey.Search);

  const userList = useAppSelector((state) => state.userPagedList.values);
  const [sort, setSort] = useState({ keyToSort: 'email', orderType: OrderType.ASC });

  const headers = [
    { label: l('_EmailAddress'), key: 'email', sortable: true },
    { label: l('_Regions'), key: 'regions', sortable: false },
    { label: l('_AccessLevel'), key: 'role', sortable: true },
    { label: l('_Actions'), key: 'actions', sortable: false },
  ];

  useEffect(() => {
    initializePagination();
    // hook is needed only on initial render
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentPage) {
      dispatch(
        userListActions.fetchUserPagedList({
          orderBy: sort.keyToSort,
          orderType: sort.orderType,
          search: searchQuery,
          ...getPagedRequestParams(userList),
        })
      );
    }
  }, [currentPage, searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitSearchData = useCallback(() => {
    const params = [
      {
        key: SearchParamKey.Search,
        value:
          getValues('search') && getValues('search').length >= SEARCH_MIN_CHAR_COUNT
            ? getValues('search')
            : null,
      },
      {
        key: SearchParamKey.CurrentPage,
        value: '0',
      },
    ];

    setSearchParams(...params);

    checkIfItemsAreOutOfRange(userList);
  }, [checkIfItemsAreOutOfRange, getValues, setSearchParams, userList]);

  const formOnChangeHandler = useMemo(
    () => withDebounce(handleSubmit(submitSearchData)),
    [handleSubmit, submitSearchData]
  );

  if (
    !featureAvailabilityWithRegions(
      [enumKeyByValue(Role, Role.Administrator), enumKeyByValue(Role, Role.RegionAdministrator)],
      userInfo
    )
  ) {
    return <></>;
  }

  const handleHeaderClick = (header: { label: string; key: string; sortable: boolean }) => {
    if (!header.sortable) {
      return;
    }

    const newOrderType =
      sort.keyToSort === header.key && sort.orderType === OrderType.ASC
        ? OrderType.DESC
        : OrderType.ASC;

    setSort({
      keyToSort: header.key,
      orderType: newOrderType,
    });

    dispatch(
      userListActions.fetchUserPagedList({
        orderBy: header.key,
        orderType: newOrderType,
        ...getPagedRequestParams(userList),
      })
    );
  };

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <FormProvider {...formMethods}>
        <form
          onChange={formOnChangeHandler}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          className={styles[`${PARENT_CLASSNAME}__flex-row`]}>
          <div className={styles[`${PARENT_CLASSNAME}__search`]}>
            <SearchField
              name="search"
              placeholder={l('_SearchUsers')}
              onClear={() => {
                setSearchParams({ key: SearchParamKey.Search, value: null });
              }}
            />
          </div>
          <div className={styles[`${PARENT_CLASSNAME}__flex-row`]}>
            <Button
              appearance={ButtonAppearance.DEFAULT_PRIMARY}
              startIcon={<SvgMinusRounded />}
              type="submit"
              disabled>
              {l('_DeactivateAllSelected')}
            </Button>
            <Button
              appearance={ButtonAppearance.DEFAULT_PRIMARY}
              startIcon={<SvgPlus />}
              type="submit"
              onClick={() => navigate(`${RouteConstants.AddUsers}`)}>
              {l('_AddUser')}
            </Button>
          </div>
        </form>
      </FormProvider>
      <div style={{ height: '10px' }} />
      <Card
        hasShadow
        cornersVariation={CardCornersVariation.Secondary}
        variation={CardVariation.TertiarySpacing}>
        <>
          <table className="table-primary table-primary--fixed table-primary--secondary-theme table-primary--list">
            <colgroup>
              <col />
              <col className="table-primary__col--additional-data" />
              <col className="table-primary__col--additional-data" />
              <col className="table-primary__col--additional-data" />
              <col className="table-primary__col--action" />
            </colgroup>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className={header.sortable ? styles[`${PARENT_CLASSNAME}__table-header`] : ''}
                    onClick={() => handleHeaderClick(header)}>
                    <span className="heading-3 heading--table-header">{header.label}</span>
                    {header.sortable &&
                      (sort.keyToSort !== header.key ? (
                        <SvgSorting
                          className={`${styles[`${PARENT_CLASSNAME}__icon`]} ${
                            styles[`${PARENT_CLASSNAME}__icon--default`]
                          }`}
                        />
                      ) : sort.orderType === OrderType.ASC ? (
                        <SvgSortingAscendant
                          className={`${styles[`${PARENT_CLASSNAME}__icon`]} ${
                            styles[`${PARENT_CLASSNAME}__icon--asc`]
                          }`}
                        />
                      ) : (
                        <SvgSortingAscendant
                          className={`${styles[`${PARENT_CLASSNAME}__icon`]} ${
                            styles[`${PARENT_CLASSNAME}__icon--desc`]
                          }`}
                        />
                      ))}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {userList.data?.map((user, index) => (
                <tr key={index}>
                  <td>
                    <CellValue
                      value={user.email}
                      theme={CellValueTheme.Secondary}
                      alignment={Alignment.Left}
                    />
                  </td>
                  <td>
                    <CellValue
                      value={(user.regions ?? []).map((region) => region.name).join(', ')}
                      theme={CellValueTheme.Secondary}
                      alignment={Alignment.Left}
                    />
                  </td>
                  <td>
                    <CellValue
                      value={user.role}
                      theme={CellValueTheme.Secondary}
                      alignment={Alignment.Left}
                    />
                  </td>
                  <td className={styles[`${PARENT_CLASSNAME}__flex-row`]}>
                    <SvgEditUser />
                    <SvgEventDelete />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {userList.metadata && <TablePagination paginationInfo={userList.metadata} />}
        </>
      </Card>
    </div>
  );
};
