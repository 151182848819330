import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { getRequiredValidator } from '@app/core/validations/hook-forms/validators';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import * as projectListActions from '@app/core/store/project-list-slice';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import { generatePath } from 'react-router-dom';
import { CopyProjectFormModel } from './copy-project-form-types';
import { useLocale } from '@app/core/hooks/useLocale';

interface CopyProjectFormModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  projectName?: string;
  projectId?: number;
}

const PARENT_CLASSNAME = 'form';
export const CopyProjectFormModal: FC<CopyProjectFormModalProps> = ({
  setIsOpen,
  isOpen,
  projectName,
  projectId,
}): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const { navigate } = usePwcNavigate();
  const { id, name } = useAppSelector((state) => state.project.projectDraft);

  const formMethods = useForm<CopyProjectFormModel>({
    ...formConfigBase,
    defaultValues: {
      copiedFromId: projectId ? projectId : id,
      copyName: `${projectName ? projectName : name} copy`,
    },
  });

  const formSubmitHandler: SubmitHandler<CopyProjectFormModel> = async () => {
    const data = formMethods.getValues();
    const projectId = await dispatch(projectListActions.copyProject(data)).unwrap();
    navigate(
      generatePath('/projects/:projectId/project-details', { projectId: projectId.toString() })
    );
  };

  useEffect(() => {
    formMethods.reset();
  }, [formMethods, isOpen]);

  return (
    <BaseModal
      size={ModalSize.Small}
      title="Give the copy a new name"
      isOpen={isOpen}
      onCloseButtonClick={() => setIsOpen(false)}
      ignoreReadOnly>
      <FormProvider {...formMethods}>
        <form
          data-testid="copy-project-form"
          onSubmit={formMethods.handleSubmit(formSubmitHandler)}
          onBlur={() => formMethods.trigger()}>
          <FormField
            name="copyName"
            label="Project Name"
            placeholder="Name"
            {...textFieldFormattingProps}
            rules={{
              ...getRequiredValidator(),
            }}
            ignoreReadOnly
          />
          <div className={`${PARENT_CLASSNAME}__actions`}>
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              data-testid="cancel-button"
              onClick={() => setIsOpen(false)}
              ignoreReadOnly>
              {l('_Cancel')}
            </Button>
            <Button
              type="submit"
              data-testid="submit-button"
              appearance={ButtonAppearance.DEFAULT_PRIMARY}
              ignoreReadOnly>
              {l('_MakeACopy')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </BaseModal>
  );
};
