import type { SVGProps } from 'react';
const SvgSetting = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 36" {...props}>
    <path
      fill="#D04A02"
      d="M1.12 22.11a1.574 1.574 0 0 1-.805-.524 1.486 1.486 0 0 1-.315-.91V14.2c0-.327.105-.619.315-.875.21-.257.478-.432.805-.525l1.365-.35a3.943 3.943 0 0 0 1.505-.735 4.57 4.57 0 0 0 1.155-1.26l.105-.175c.303-.514.49-1.05.56-1.61a4.253 4.253 0 0 0-.14-1.715L5.285 5.59a1.454 1.454 0 0 1 .035-.945c.117-.304.327-.549.63-.735l5.6-3.22a1.5 1.5 0 0 1 .945-.175c.327.023.607.163.84.42l1.05 1.05c.397.42.863.746 1.4.98a4.17 4.17 0 0 0 1.68.35h.07a4.17 4.17 0 0 0 1.68-.35 4.218 4.218 0 0 0 1.4-.98l1.05-1.05a1.22 1.22 0 0 1 .84-.42 1.5 1.5 0 0 1 .945.175l5.6 3.22c.303.186.513.431.63.735.117.303.128.618.035.945l-.385 1.365a4.254 4.254 0 0 0-.14 1.715c.093.56.292 1.096.595 1.61 0 .023.012.07.035.14l.035.035c.303.49.677.91 1.12 1.26.467.35.98.595 1.54.735l1.4.35c.327.093.583.268.77.525.21.256.315.548.315.875v6.475c0 .326-.105.63-.315.91a1.394 1.394 0 0 1-.77.525l-1.4.35c-.56.14-1.073.385-1.54.735a4.183 4.183 0 0 0-1.12 1.26.237.237 0 0 0-.07.175 4.325 4.325 0 0 0-.595 1.61 4.254 4.254 0 0 0 .14 1.715l.385 1.365c.093.326.082.641-.035.945a1.476 1.476 0 0 1-.63.735l-5.6 3.22a1.5 1.5 0 0 1-.945.175 1.221 1.221 0 0 1-.84-.42l-1.05-1.085a4.217 4.217 0 0 0-1.4-.98 4.57 4.57 0 0 0-1.68-.315h-.07a4.57 4.57 0 0 0-1.68.315 4.217 4.217 0 0 0-1.4.98l-1.05 1.085a1.221 1.221 0 0 1-.84.42 1.5 1.5 0 0 1-.945-.175L5.95 31a1.475 1.475 0 0 1-.63-.735 1.454 1.454 0 0 1-.035-.945l.385-1.365c.163-.56.21-1.132.14-1.715a3.905 3.905 0 0 0-.56-1.61.808.808 0 0 0-.105-.175 3.994 3.994 0 0 0-1.155-1.26 3.943 3.943 0 0 0-1.505-.735l-1.365-.35Zm16.38-9.03c-.793 0-1.528.199-2.205.596a4.529 4.529 0 0 0-1.575 1.61 4.1 4.1 0 0 0-.595 2.17c0 .793.198 1.528.595 2.205a4.625 4.625 0 0 0 1.575 1.575 4.284 4.284 0 0 0 2.205.595c.793 0 1.517-.199 2.17-.595a4.53 4.53 0 0 0 1.61-1.575 4.284 4.284 0 0 0 .595-2.205 4.1 4.1 0 0 0-.595-2.17 4.438 4.438 0 0 0-1.61-1.61 4.1 4.1 0 0 0-2.17-.595Z"
    />
  </svg>
);
export default SvgSetting;
