import {
  NetworkRolloutRegions,
  NetworkRolloutRegionsCode,
} from '@app/shared/models/contracts/enums/shared-enums';

export const NETWORK_ROLL_OUT_REGION_OPTIONS = [
  {
    viewValue: '',
    regionCode: '',
  },
  {
    viewValue: NetworkRolloutRegions.UnitedKingdom,
    regionCode: NetworkRolloutRegionsCode.UnitedKingdom,
  },
  { viewValue: NetworkRolloutRegions.Ireland, regionCode: NetworkRolloutRegionsCode.Ireland },
];
