// You can copy this straight from ErrorCodes.cs
export enum ErrorCodes {
  // Misc errors (0 - 999)
  MISC_UNSPECIFIED_ERROR = 0,
  MISC_LOCKABLE_COLLECTION_ACCESS_AFTER_LOCK = 1,
  MISC_OTS_ACCESSED_BEFORE_SET = 2,
  MISC_OTS_ATTEMPTED_SET_ON_ALREADY_SET = 3,
  MISC_LEGACY_INVALID_MODEL_ERROR = 4,
  MISC_LEGACY_CALCULATION_ENGINE_ERROR = 5,
  MISC_LEGACY_UNAUTHORIZED_ERROR = 6,
  MISC_LEGACY_INVALID_OPERATION_ERROR = 7,
  MISC_LEGACY_RESOURCE_NOT_FOUND_ERROR = 8,
  MISC_LEGACY_OTHER_DOMAIN_ERROR = 9,
  MISC_CONTEXT_ITEM_NOT_SET = 10,
  MISC_PROJECT_CLOSED = 11,

  // Input Validation Errors (1000 - 1999)
  INPUT_COLLARING_NULL_PERCENTAGES = 1000,
  INPUT_COLLARING_NULL_FIXED_CAP = 1001,
  INPUT_YEARS_MISMATCH_BETWEEN_FORECAST_LENGTH_AND_SUPPLIED_VALUES = 1002,
  INPUT_NON_ORDINARY_EQUITY_HAS_PAYOUT_LOGIC = 1003,
  INPUT_ORDINARY_EQUITY_HAS_COUPON = 1004,
  INPUT_CAPITAL_INSTRUMENT_HAS_NO_OWNERSHIP = 1005,
  INPUT_CAPITAL_PAYOUT_LOGIC_CUSTOM_VALUE_NULL = 1006,
  INPUT_CAPITAL_PAYOUT_LOGIC_MISSING_CONDITION = 1007,
  INPUT_CAPITAL_PAYOUT_LOGIC_MISSING_PAYOUT = 1008,
  INPUT_REQUIRED_INPUT_MISSING = 1009,
  INPUT_CAPITAL_PAYOUT_LOGIC_HURDLE_CONDITION_NULL = 1010,
  INPUT_CAPITAL_EMPTY_STRUCTURE = 1011,
  INPUT_CAPITAL_NO_ORDINARY_EQUITY = 1012,
  INPUT_CAPITAL_INSTRUMENT_HAS_NO_VALUE = 1013,
  INPUT_CAPITAL_INSTRUMENT_HAS_NO_SHARES = 1014,
  INPUT_OPM_REAL_WORLD_NOT_SPECIFIED_FOR_REAL_WORLD_CALCULATION = 1015,
  INPUT_DUPLICATE_DLOM_VALUE_KEYS = 1016,
  INPUT_REQUIRED_INPUT_NULL = 1017,
  INPUT_KEY_REFERENCE_DOES_NOT_EXIST = 1018,
  INPUT_DLOM_INSTRUMENT_MISSING = 1019,
  INPUT_DLOM_TRANCHE_MISSING = 1020,
  INPUT_SCHEDULE_EXPORT_TYPE_INVALID = 1021,

  // Calculation/Logic errors (2000 - 2999)
  CALC_UNSPECIFIED_ERROR = 2000,
  CALC_INSTITUTIONAL_MOM_NEGATIVE_NATURAL_LOG_COMPLEX_NUMBER = 2001,
  CALC_INVALID_VALUE_NEGATIVE = 2002,
  CALC_HURDLE_UNSUPPORTED_METRIC = 2003,
  CALC_HURDLE_UNSUPPORTED_CONJUNCTION = 2004,
  CALC_HURDLE_UNSUPPORTED_OPERATOR = 2005,
  CALC_HURDLE_MIXED_CONDITION_METRICS = 2006,
  CALC_PARTICIPATION_NO_COMBO_OF_HURDLES_IN_ORDER = 2007,
  CALC_PARTICIPATION_NORMALIZE_IMPOSSIBLE_NO_DILUTABLE_TRANCHES = 2008,
  CALC_PARTICIPATION_NORMALIZE_IMPOSSIBLE_ZERO_PARTICIPATION = 2009,
  CALC_PARTICIPATION_NORMALIZE_IMPOSSIBLE_FIXED_TOO_HIGH = 2010,
  CALC_TRANCHE_IDS_MISMATCH = 2011,
  CALC_COLLARING_CONFIG_COLLARS_ALL_ITERATIONS = 2012,
  CALC_COLLARING_NO_VALUES_TO_COLLAR = 2013,
  CALC_INVALID_HORIZON = 2014,
  CALC_INVALID_VALUE_OUT_OF_RANGE = 2015,
  CALC_CAPITALSTRUCTURE_EVENTSET_NOT_FOUND = 2016,
  CALC_NO_CAPITALSTRUCTURE_FOR_DATE = 2017,
  CALC_INTEREST_ACCRUAL_DATES_OUT_OF_BOUNDS = 2018,
  CALC_INTEREST_CASH_PAY_OUT_OF_BOUNDS = 2019,
  CALC_INTEREST_ACCRUAL_DATES_NOT_SEQUENCED_CORRECTLY = 2020,
  CALC_MORE_THAN_ONE_CAPITAL_STRUCTURE = 2021,
  CALC_FROM_DATE_MUST_BE_BEFORE_TO_DATE = 2022,
  CALC_HURDLE_CONDITIONS_IMPOSSIBLE = 2023,
  CALC_HURDLE_BOUNDS_OVERLAP = 2024,
  CALC_HURDLE_BOUNDS_NOT_CONTIGUOUS = 2025,
  CALC_HURDLE_BOUNDS_NOT_OPEN_ENDED = 2026,
  CALC_HURDLE_BOUNDS_UNSOLVABLE = 2027,
  CALC_PARTICIPATION_SOLVE_TIMEOUT = 2028,

  // Database/access errors (3000 - 3999)
  DATA_PROJECT_NOT_FOUND = 3000,
  DATA_RESULTSET_NOT_FOUND = 3001,
  DATA_RESULTSET_ALREADY_EXISTS_FOR_PROJECT_TYPE_COMBO = 3002,

  // Job / scheduling errors (4000-4999)
  JOB_SCHEDULING_INSUFFICIENT_BUDGET = 4000,
  JOB_DOWNLOAD_FILE_NOT_FOUND = 4001,

  // Capital Structure Builder / Equity Refinancing (5000 - 5999)
  CSB_MISC_ERROR = 5000,
  CSB_TOO_MANY_EVENTSETS = 5001,
  CSB_TOO_MANY_EVENTS = 5002,
  CSB_EVENTSET_NOT_FOUND = 5003,
  CSB_NO_STRUCTURE_FOR_DATE = 5004,

  CSB_DEF_INSTRUMENT_NARRATIVE_NOT_UNIQUE = 5020,
  CSB_DEF_ORDINARY_RANK_ON_NON_ORDINARY = 5021,
  CSB_DEF_RANK_OUT_OF_RANGE = 5022,
  CSB_DEF_VALUED_SET_ON_INSTRUMENT_NOT_PRESENT_AT_INVESTMENT = 5023,
  CSB_DEF_TOO_MANY_INSTRUMENTS = 5024,
  CSB_DEF_INSTRUMENT_NARRATIVE_TOO_LONG = 5026,

  CSB_INITIAL_INVESTED_MISSING_OR_INVALID = 5040,
  CSB_INITIAL_SHARES_MISSING_OR_INVALID = 5041,
  CSB_INITIAL_REFERENCE_NON_EXISTENT_DEF = 5042,
  CSB_INITIAL_INVESTED_AND_SHARES_VALUES_NOT_PROVIDED = 5043,
  CSB_INITIAL_COUPON_MISSING = 5044,
  CSB_INITIAL_COUPON_VALUE_INVALID = 5045,
  CSB_INITIAL_COUPON_DATE_PREDATES_INVESTMENT = 5046,
  CSB_INITIAL_COUPON_CASHPAY_INVALID = 5047,

  CSB_EVENTSET_REFERENCE_NON_EXISTENT_EVENT = 5060,
  CSB_EVENTSET_HAS_NO_EVENTS = 5061,
  CSB_EVENTSET_CONTAINS_DUPLICATE_EVENT_DATES = 5062,
  CSB_EVENTSET_TOO_MANY_EVENTS = 5063,
  CSB_EVENTSET_DUPLICATE_EVENT_REFERENCES = 5064,

  CSB_EVENT_INSTRUMENT_NO_MOVEMENT = 5080,
  CSB_EVENT_NO_INSTRUMENTS = 5081,
  CSB_EVENT_REFERENCE_NON_EXISTENT_INSTRUMENT_DEF = 5082,
  CSB_EVENT_DATE_PREDATES_INVESTMENT = 5083,
  CSB_EVENT_NARRATIVE_NOT_UNIQUE = 5084,
  CSB_EVENT_NARRATIVE_TOO_LONG = 5085,
  CSB_EVENT_LEAVES_NO_ORDINARY_IN_STRUCTURE = 5086,
  CSB_EVENT_LEAVES_NO_INSTRUMENTS_IN_STRUCTURE = 5087,
  CSB_EVENT_DATE_TOO_FAR_BEFORE_VALUATION_DATE = 5088,

  CSB_MOVEMENT_NEW_INSTRUMENT_ALREADY_EXISTS = 5100,
  CSB_MOVEMENT_ON_ZERO_VALUE_INSTRUMENT = 5101,
  CSB_MOVEMENT_VALUE_REDEMPTION_RESULTS_NEGATIVE_VALUE = 5102,
  CSB_MOVEMENT_QUANTITY_REDEMPTION_RESULTS_NEGATIVE_SHARES = 5103,
  CSB_MOVEMENT_COUPON_UPDATE_ON_INSTRUMENT_NOT_PRESENT = 5104,
  CSB_MOVEMENT_UPDATE_ON_NON_EXISTENT_INSTRUMENT_IMPLICIT_ADD = 5105,
  CSB_MOVEMENT_HAS_NO_EFFECT = 5106,
  CSB_MOVEMENT_COUPON_UPDATE_ON_NON_COUPONABLE_INSTRUMENT = 5107,
  CSB_MOVEMENT_CAUSES_OVERFLOW = 5108,
  CSB_MOVEMENT_COUPON_DATE_PREDATES_PREVIOUS = 5110,
  CSB_MOVEMENT_SPECIFIES_BOTH_ADJUSTMENT_AND_ABSOLUTE_VALUES = 5111,
  CSB_MOVEMENT_ADJUSTMENT_CAUSES_FULL_REDEMPTION = 5112,
  CSB_MOVEMENT_NEW_INSTRUMENT_INITIAL_VALUE_NOT_PROVIDED = 5113,
  CSB_MOVEMENT_NEW_INSTRUMENT_INITIAL_SHARES_NOT_PROVIDED = 5114,
  CSB_MOVEMENT_VALUE_NOT_PROVIDED_FOR_OWNER = 5115,
  CSB_MOVEMENT_ADJUSTMENT_CAUSES_INCOMPLETE_REDEMPTION = 5116,
  CSB_MOVEMENT_COUPON_VALUE_INVALID = 5117,
  CSB_MOVEMENT_COUPON_CASHPAY_INVALID = 5118,
  CSB_MOVEMENT_COUPON_DATE_PREDATES_INVESTMENT = 5119,
  // Add new categories here as necessary, generally grouped in 1000s
}
