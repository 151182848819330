import { FC } from 'react';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { getRequiredValidator } from '@core/validations/hook-forms/validators';
import { numberPattern } from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { EquitySourceDto, SourceDto } from '@app/shared/models/contracts/project-dto';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import Button from '@app/shared/components/button/Button';
import MinusRoundedSvg from '@app/shared/icons/MinusRounded';
import PlusSvg from '@app/shared/icons/Plus';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { useLocale } from '@app/core/hooks/useLocale';

interface SourcesFormSectionProps {
  defaultEquitySourcesFieldValue: EquitySourceDto;
  defaultFieldValue: SourceDto;
  onRowDelete: () => void;
}

export const SourcesFormSection: FC<SourcesFormSectionProps> = ({
  defaultEquitySourcesFieldValue,
  defaultFieldValue,
  onRowDelete,
}): JSX.Element => {
  const { l } = useLocale();
  const { control } = useFormContext();
  const project = useAppSelector((state) => state.project.projectDraft);
  const {
    fields: equitySourcesFields,
    append: appendEquitySourcesField,
    remove: removeEquitySourcesField,
  } = useFieldArray({
    name: 'equitySources',
    control,
  });
  const {
    fields: otherSourcesFields,
    append: appendOtherSourcesField,
    remove: removeOtherSourcesField,
  } = useFieldArray({
    name: 'otherSources',
    control,
  });

  const totalEquity = project.sourcesAndUses.equitySources.reduce(
    (acc, current) => acc + Number(current.amount ?? 0),
    0
  );

  const removeEquitySourcesRow = (index: number) => (): void => {
    removeEquitySourcesField(index);
    onRowDelete();
  };

  const removeOtherSourcesRow = (index: number) => (): void => {
    removeOtherSourcesField(index);
    onRowDelete();
  };

  return (
    <table className="table-primary table-primary--fluid table-primary--zebra">
      <colgroup>
        <col />
        <col />
        <col />
        <col className="table-primary__col-action" />
      </colgroup>
      <thead>
        <tr>
          <th colSpan={4} className="table-primary__cell--header">
            {l('_Sources')}
          </th>
        </tr>
        <tr>
          <th colSpan={2} className="table-primary__cell--header-secondary">
            {l('_TypeAndNarrative')}
          </th>
          <th className="table-primary__cell--header-secondary table-primary__cell--right">
            {l('_Amount')}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={4} className="table-primary__cell--section-start-separator">
            {l('_Equity')}
          </td>
        </tr>
        {equitySourcesFields.map((field, index) => (
          <tr key={field.id}>
            <td />
            <td>
              <GridTextField
                name={`equitySources.${index}.narrative`}
                isInputMasked={false}
                rules={{
                  ...getRequiredValidator(),
                }}
                placeholder="Add narrative"
              />
            </td>
            <td>
              <GridTextField
                name={`equitySources.${index}.amount`}
                fieldValueMask={numberPattern}
                placeholder="0"
                alignment={Alignment.Right}
              />
            </td>
            <td className="table-primary__cell--actions">
              <Button
                appearance={ButtonAppearance.CLEAN}
                size={ButtonSize.TABLE_ACTION}
                title="Delete item"
                onClick={removeEquitySourcesRow(index)}>
                <MinusRoundedSvg />
              </Button>
            </td>
          </tr>
        ))}
        <tr className="table-primary__row--plain">
          <td colSpan={4} className="table-primary__cell--actions">
            <Button
              appearance={ButtonAppearance.DEFAULT_TERTIARY}
              size={ButtonSize.TABLE_ACTION}
              startIcon={<PlusSvg />}
              onClick={() => appendEquitySourcesField(defaultEquitySourcesFieldValue)}>
              {l('_AddEquity')}
            </Button>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr className="table-primary__row--secondary">
          <th
            colSpan={2}
            className="table-primary__cell--section-start-separator table-primary__cell--strong">
            <span>{l('_TotalEquityTitle')}</span>
          </th>
          <td className="table-primary__cell--section-start-separator table-primary__cell--strong">
            <CellValue {...amountRules} value={totalEquity} />
          </td>
          <td className="table-primary__cell--section-start-separator" />
        </tr>
        <tr className="table-primary__row--plain">
          <td colSpan={4} />
        </tr>
        {otherSourcesFields.map((field, index) => (
          <tr key={field.id}>
            <td>
              <GridTextField
                name={`otherSources.${index}.type`}
                isInputMasked={false}
                rules={{
                  ...getRequiredValidator(),
                }}
                placeholder="Add type"
              />
            </td>
            <td>
              <GridTextField
                name={`otherSources.${index}.narrative`}
                isInputMasked={false}
                rules={{
                  ...getRequiredValidator(),
                }}
                placeholder="Add narrative"
              />
            </td>
            <td>
              <GridTextField
                name={`otherSources.${index}.amount`}
                fieldValueMask={numberPattern}
                placeholder="0"
                alignment={Alignment.Right}
              />
            </td>
            <td className="table-primary__cell--actions">
              <Button
                appearance={ButtonAppearance.CLEAN}
                size={ButtonSize.TABLE_ACTION}
                title="Delete item"
                onClick={removeOtherSourcesRow(index)}>
                <MinusRoundedSvg />
              </Button>
            </td>
          </tr>
        ))}
        <tr className="table-primary__row--plain">
          <td colSpan={4} className="table-primary__cell--actions">
            <Button
              appearance={ButtonAppearance.DEFAULT_TERTIARY}
              size={ButtonSize.TABLE_ACTION}
              startIcon={<PlusSvg />}
              onClick={() => appendOtherSourcesField(defaultFieldValue)}>
              {l('_AddSource')}
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
