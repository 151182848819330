import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import SidebarCollapseButton from '@app/shared/components/sidebar/SidebarCollapseButton';
import useCollapsableSidebarState from '@app/core/hooks/useLocalStoreSidebarCollapse';
import AdminNavigationSidebar from '@app/shared/layout/admin-navigation-sidebar/AdminNavigationSidebar';
import styles from './admin-routes-wrapper.module.scss';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import { featureAvailabilityWithRegions } from '@app/core/utils/feature-availability';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { RootAppRoutes } from '@app/shared/enums/root-app-routes';
import { Role } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';

const PARENT_CLASSNAME = 'admin-routes-wrapper';

type PageLayoutProps = {
  children: ReactNode;
  contentHeader?: ReactNode;
  rightSidebar?: ReactNode;
};

const PageLayout: FC<PageLayoutProps> = ({
  children,
  contentHeader,
  rightSidebar,
}): JSX.Element => {
  const contentSection = (
    <>
      {contentHeader && (
        <div className={classNames(styles[`${PARENT_CLASSNAME}__header-container`])}>
          {contentHeader}
        </div>
      )}
      {children}
    </>
  );

  return (
    <>
      <main className={styles[`${PARENT_CLASSNAME}__main`]}>{contentSection}</main>
      {rightSidebar}
    </>
  );
};

type AdminRoutesWrapperProps = {
  children: ReactNode;
};

const AdminRoutesWrapper: FC<AdminRoutesWrapperProps> & {
  PageLayout: FC<PageLayoutProps>;
  // eslint-disable-next-line react/prop-types
} = ({ children }): JSX.Element | null => {
  const [isCollapsed, toggleSidebar] = useCollapsableSidebarState('collapsed-sidebar');

  const { navigate, generatePath } = usePwcNavigate();
  const { userInfo } = useAppSelector((state) => state.userInfo);

  if (
    !featureAvailabilityWithRegions(
      [enumKeyByValue(Role, Role.Administrator), enumKeyByValue(Role, Role.RegionAdministrator)],
      userInfo
    )
  ) {
    navigate(generatePath('/' + RootAppRoutes.Forbidden));
    return null;
  }

  return (
    <div className={classNames(styles[PARENT_CLASSNAME])}>
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}__sidebar-container`], {
          [styles['collapsed']]: isCollapsed,
        })}
        data-testid="sidebar-container">
        <AdminNavigationSidebar />
        <SidebarCollapseButton toggleSidebar={toggleSidebar} isSidebarCollapsed={isCollapsed} />
      </div>
      {children}
    </div>
  );
};

AdminRoutesWrapper.PageLayout = PageLayout;

export default AdminRoutesWrapper;
