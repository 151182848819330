import { RouteItem } from '@app/Routes';
import { FC } from 'react';
import styles from './admin-pages-list.module.scss';
import { AdminMenuItem } from './admin-menu-item/AdminMenuItem';

export const PARENT_CLASSNAME = 'admin-pages-list';

interface AdminPagesListProps {
  routes: RouteItem[];
  className?: string;
  subtitle?: string;
  indented?: boolean;
}

const AdminPagesList: FC<AdminPagesListProps> = ({
  routes,
  subtitle,
  className,
  indented,
}): JSX.Element => {
  return (
    <div className={`${styles[PARENT_CLASSNAME]} ${className}`}>
      <ul className={`${styles[`${PARENT_CLASSNAME}__list`]}`}>
        {subtitle && <div className={styles[`${PARENT_CLASSNAME}__subtitle`]}>{subtitle}</div>}
        {routes.map((link: RouteItem) => (
          <li
            key={link.title}
            className={`${styles[`${PARENT_CLASSNAME}__list-item`]} ${
              indented && styles[`${PARENT_CLASSNAME}__indented`]
            }`}>
            <AdminMenuItem route={link.route} title={link.title} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminPagesList;
