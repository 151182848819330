import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '@app/shared/models/contracts/enums/shared-enums';
import env from '@environment';
import { RootState } from './store';
import api from '@server/api-config';
import { enumKeyByValue } from '@app/shared/helpers';

export type User = {
  email: string;
  role: Role;
};

export type NewUser = User & {
  validationMessages?: string[];
};

export type AddUsersState = {
  newUsers: NewUser[];
  role: Role;
  regionCodes: string[];
};

const initialState: AddUsersState = {
  newUsers: [],
  role: Role.TeamMember,
  regionCodes: [],
};

export const postNewUsers = createAsyncThunk<NewUser[], void, { state: RootState }>(
  'users/addUsers',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const users = state.userManagement.newUsers;

    const payload = {
      users: users.map((user) => ({
        email: user.email,
        role: enumKeyByValue(Role, user.role),
      })),
      regionCodes: state.userManagement.regionCodes,
    };

    const response = await api.post(`${env.apiUrl}/users`, JSON.stringify(payload));
    const validationResults = response.data;

    const updatedUsers = users.map((user) => {
      const validationResult = validationResults.find(
        (result: NewUser) => result.email === user.email
      );

      return validationResult
        ? { ...user, validationMessages: validationResult.validationMessages ?? [] }
        : user;
    });

    thunkAPI.dispatch(updateUsersValidation(updatedUsers));

    return response.data;
  }
);

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    addNewUser: (state, action: PayloadAction<{ email: string }>) => {
      state.newUsers.push({
        ...action.payload,
        role: state.role,
      });
    },

    deleteNewUser: (state, action: PayloadAction<number>) => {
      state.newUsers = state.newUsers.filter((_, i) => i !== action.payload);
    },

    setRegionCodes: (state, action: PayloadAction<string[]>) => {
      state.regionCodes = action.payload;
    },

    setRole: (state, action: PayloadAction<Role>) => {
      state.role = action.payload;
      state.newUsers = state.newUsers.map((user) => ({
        ...user,
        role: state.role,
      }));
    },

    updateUsersValidation: (state, action: PayloadAction<NewUser[]>) => {
      state.newUsers = action.payload;
    },

    resetAddUsersState: () => {
      return initialState;
    },
  },
});

export const {
  addNewUser,
  deleteNewUser,
  setRegionCodes,
  setRole,
  updateUsersValidation,
  resetAddUsersState,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
