import ProjectList from '@app/modules/projects/projects/project-list/ProjectList';
import { FC, useState } from 'react';
import Button from '@app/shared/components/button/Button';
import PlusSvg from '@app/shared/icons/legacy/PlusSvg';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import NewProjectFormModal from '@app/modules/projects/inputs/project-admin-new/project-form/NewProjectFormModal';
import EntryPageRoutesWrapper from '@app/shared/components/entry-page-routes-wrapper/EntryPageRoutesWrapper';
import { featureAvailability } from '@core/utils/feature-availability';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';

const Projects: FC = (): JSX.Element => {
  const { l } = useLocale();
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
  const { userInfo } = useAppSelector((state) => state.userInfo);

  return (
    <EntryPageRoutesWrapper
      actions={
        featureAvailability(['TeamMember'], userInfo) && (
          <Button
            autoIconSize
            startIcon={<PlusSvg />}
            appearance={ButtonAppearance.DEFAULT_PRIMARY}
            onClick={() => setIsCreateProjectModalOpen(true)}>
            {l('_NewProject')}
            <NewProjectFormModal
              setIsOpen={setIsCreateProjectModalOpen}
              isOpen={isCreateProjectModalOpen}
            />
          </Button>
        )
      }>
      <ProjectList />
    </EntryPageRoutesWrapper>
  );
};

export default Projects;
