import { SVGProps } from 'react';
const SvgCopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M17.504 0L22.376 5.4V19.2H17.576V24H2.624V4.8H7.424V0H17.504ZM11.744 5.928H3.848V22.824H16.448V10.92H11.744V5.928ZM16.544 1.128H8.552V4.8H12.704L17.576 10.2V18.072H21.248V6.12H16.544V1.128ZM12.872 6.384V10.08H15.896L12.872 6.384ZM17.672 1.584V5.28H20.696L17.672 1.584Z"
      fill="#474747"
    />
  </svg>
);
export default SvgCopyIcon;
