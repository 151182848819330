import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { calculateCapitalStructureDimensions } from '@app/modules/projects/inputs/capital-structure/capital-structure-block-size-calculator';
import {
  CapitalStructure,
  Instrument,
} from '@app/modules/projects/inputs/capital-structure/capital-structure-types';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import { abbreviatedValueFormatter, amountValueFormatterProps } from '@app/shared/formatters';
import { enumKeyByValue, groupBy, sumBy } from '@app/shared/helpers';
import { labelTopValue } from '@app/shared/helpers/labeling-helpers';
import MinusIcon from '@app/shared/icons/MinusIcon';
import SvgPlus from '@app/shared/icons/Plus';
import {
  DenominationMultiplier,
  InstrumentType,
} from '@app/shared/models/contracts/enums/shared-enums';
import { OpmForecastDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import { MAX_COLLAPSED_ITEMS, MAX_LABEL_ITEMS } from '../constants';
import OpmInstrumentBlockComponent from '@app/modules/projects/dashboard/widgets/total-equity/opm/OpmInstrumentBlock';
import NetDebtColumn from '@app/modules/projects/dashboard/widgets/total-equity/NetDebtTooltip';
import styles from '../total-equity-chart.module.scss';
import {
  InstrumentDefinitionAndInitialValues,
  selectInstrumentsWithInitialValues,
  selectOrdinaryEquityInstruments,
  selectPrefShareInstruments,
  selectSlnInstruments,
} from '@app/core/store/capital-structure-slice-selectors';

const PARENT_CLASSNAME = 'total-equity-chart';

interface OpmTotalEquityChartProps {
  isExpanded: boolean;
  forecastData: OpmForecastDto[];
  setIsExpanded?: (isExpanded: boolean) => void;
}

const OpmTotalEquityChart: FC<OpmTotalEquityChartProps> = ({
  isExpanded,
  setIsExpanded,
  forecastData,
}): JSX.Element => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const allInstruments = useAppSelector(selectInstrumentsWithInitialValues);
  const slnInstruments = useAppSelector(selectSlnInstruments);
  const prefSharesInstruments = useAppSelector(selectPrefShareInstruments);
  const ordInstruments = useAppSelector(selectOrdinaryEquityInstruments);
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];
  const getOpmForecastByYear = (forecastYear: string) =>
    forecastData.find((forecast) => forecast.forecastDate === forecastYear);
  const chartForecastsCount = isExpanded
    ? forecastData.length
    : forecastData.length > MAX_COLLAPSED_ITEMS
    ? MAX_COLLAPSED_ITEMS
    : forecastData.length;

  const getCaseOrProjectNetDebt = (forecastIndex: number) =>
    forecastData[forecastIndex].totalNetDebt;

  const createStructureForInstruments = <T extends Instrument>(instruments: T[]) =>
    calculateCapitalStructureDimensions(instruments, 100, 100, 0, 0, false);

  const getStructureForForecastStack = (forecastYear: string) => {
    const forecast = getOpmForecastByYear(forecastYear);
    const distributions = forecast?.instrumentDistributions ?? [];

    const distributionsByInstrument = groupBy(distributions, (x) => x.instrumentId!);

    return createStructureForInstruments(
      Object.values(distributionsByInstrument).map((ins) => {
        const definitionAndInitialValues = allInstruments.find(
          (item) => item.instrumentId === ins[0].instrumentId
        );
        return {
          ...definitionAndInitialValues!,
          amount: ins[0].distributionAmount,
        };
      })
    );
  };

  const renderRanks = (
    structure: CapitalStructure<InstrumentDefinitionAndInitialValues>,
    forecastIndex?: number
  ) => {
    return structure.ranks.map((rank, index) => {
      return (
        <Fragment key={`instrument-${index}`}>
          <div
            key={'rank-block-' + index}
            style={{ height: rank.height + '%' }}
            className={classNames(styles['graph__rank-block'], {
              [styles['graph__rank-block--expanded']]: isExpanded,
            })}>
            {rank.instrumentBlocks.map((instrument, index) => {
              return (
                <OpmInstrumentBlockComponent
                  key={'instrument-block-component' + index + instrument.instrumentId}
                  forecastData={forecastData}
                  forecastIndex={forecastIndex}
                  instrument={instrument}
                  chartForecastsCount={chartForecastsCount}
                />
              );
            })}
          </div>
        </Fragment>
      );
    });
  };

  const entryStackStructure = createStructureForInstruments(
    allInstruments.map((ins) => {
      return {
        ...ins,
        amount: ins.initialValues?.ownership
          ? sumBy(Object.values(ins.initialValues.ownership), (c) => c.amount ?? 0)
          : 0,
      };
    })
  );
  const totalInvestments = sumBy(
    allInstruments.flatMap((x) =>
      x.initialValues?.ownership ? Object.values(x.initialValues.ownership) : []
    ),
    (x) => x.amount ?? 0
  );

  const stacksPerForecast = forecastData.map((f) => getStructureForForecastStack(f.forecastDate));
  const totalEquityPerForecast = stacksPerForecast.map((x) =>
    sumBy(
      x.ranks.flatMap((r) => r.instrumentBlocks),
      (i) => i.amount
    )
  );

  const getInitialNetDebtValue = project.opmInput.initialTotalNetDebt;

  const getMaxDebtValue = () => Math.max(...forecastData.map((forecast) => forecast.totalNetDebt));

  const maxEquity = Math.max(totalInvestments, ...totalEquityPerForecast);

  const calculatedLabelTopValue = labelTopValue(maxEquity);

  const createDebtTooltip = (debtAmount: string | undefined) => {
    return (
      <div>
        <h2 className="heading-2 heading-2--alternate">{l('_NetDebt')}</h2>
        <dl className="definition-list">
          <dt>{l('_TotalNetDebtTitle')}</dt>
          <dd>
            {abbreviatedValueFormatter({
              value: Number(debtAmount) * multiplier,
            })}
          </dd>
        </dl>
      </div>
    );
  };

  const getMaxNetDebtAxisCount = () => {
    // eslint-disable-next-line big-number-rules/rounding
    const maxNetDebtLabels = Math.ceil(
      Math.max(getMaxDebtValue(), getInitialNetDebtValue ?? 0) /
        (calculatedLabelTopValue / MAX_LABEL_ITEMS)
    );
    return maxNetDebtLabels > MAX_LABEL_ITEMS ? MAX_LABEL_ITEMS : maxNetDebtLabels;
  };

  const shlInstrumentsExist = slnInstruments.length > 0;
  const prefShareInstrumentsExist = prefSharesInstruments.length > 0;
  const ordEquityInstrumentsExist = ordInstruments.some((i) => !i.isSweetEquity);
  const sweetEquityInstrumentsExist = ordInstruments.some((i) => i.isSweetEquity);

  return (
    <div aria-hidden className={styles[PARENT_CLASSNAME]}>
      <div className={styles[`${PARENT_CLASSNAME}__legend`]}>
        <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
          <span
            className={classNames([
              styles[`${PARENT_CLASSNAME}__icon`],
              styles[`${PARENT_CLASSNAME}__icon--net-debt`],
            ])}
          />
          {l('_NetDebt')}
        </Button>
        {shlInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--sln`],
              ])}
            />
            {l(`_${enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)}`)}
          </Button>
        )}
        {prefShareInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--pref`],
              ])}
            />
            {l(`_${enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)}`)}
          </Button>
        )}
        {ordEquityInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--ord`],
              ])}
            />
            {l(`_${enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)}`)}
          </Button>
        )}
        {sweetEquityInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--sweet`],
              ])}
            />
            {l('_SweetEquityTitle')}
          </Button>
        )}
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__chart`]}>
        {[...Array(MAX_LABEL_ITEMS)].map((_, i) => (
          <div
            key={`grid-line-${i}`}
            className={classNames([
              styles[`${PARENT_CLASSNAME}__grid-line`],
              styles[`${PARENT_CLASSNAME}__grid-line--${i + 1}`],
            ])}
            data-grid-value={abbreviatedValueFormatter({
              value: (calculatedLabelTopValue / MAX_LABEL_ITEMS) * (i + 1) * multiplier,
              ...amountValueFormatterProps,
            })}
          />
        ))}
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
              [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
            })}
            style={{
              height: (100 / calculatedLabelTopValue) * totalInvestments + '%',
            }}>
            {renderRanks(entryStackStructure)}
          </div>
          {[...Array(chartForecastsCount)].map((_, i) => (
            <Fragment key={'instrument-wrapper-' + i}>
              <div
                className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
                  [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
                })}
                style={{
                  height: (100 / calculatedLabelTopValue) * totalEquityPerForecast[i] + '%',
                }}>
                {renderRanks(stacksPerForecast[i], i)}
              </div>
              {i === MAX_COLLAPSED_ITEMS - 1 && forecastData.length > MAX_COLLAPSED_ITEMS && (
                <div
                  className={classNames(styles['graph__expander-container'], {
                    [styles['graph__expander-container--secondary']]: !isExpanded,
                  })}
                />
              )}
            </Fragment>
          ))}
        </div>
      </div>
      <div
        className={classNames([
          styles[`${PARENT_CLASSNAME}__chart`],
          styles[`${PARENT_CLASSNAME}__chart--alternate`],
          styles[`${PARENT_CLASSNAME}__chart--${getMaxNetDebtAxisCount()}`],
        ])}>
        {[...Array(getMaxNetDebtAxisCount() + 1)].map((_, i) => (
          <Fragment key={`grid-line-debt-${i}`}>
            <div
              className={classNames([
                styles[`${PARENT_CLASSNAME}__grid-line`],
                styles[`${PARENT_CLASSNAME}__grid-line--${i}`],
                styles[`${PARENT_CLASSNAME}__grid-line--${i}-negative`],
              ])}
              data-grid-value={abbreviatedValueFormatter({
                value: (calculatedLabelTopValue / MAX_LABEL_ITEMS) * -i * multiplier,
                ...amountValueFormatterProps,
              })}
            />
          </Fragment>
        ))}
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
              [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
            })}>
            <div
              className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
                [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
              })}
              style={{
                height:
                  (100 / ((calculatedLabelTopValue / MAX_LABEL_ITEMS) * getMaxNetDebtAxisCount())) *
                    (getInitialNetDebtValue ?? 0) +
                  '%',
              }}>
              <Tooltip
                key="debt-tooltip"
                placement={TooltipPlacement.Right}
                content={createDebtTooltip(getInitialNetDebtValue?.toString())}
                className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
                  [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
                })}>
                <div className={styles[`${PARENT_CLASSNAME}__tooltip-placeholder`]} />
              </Tooltip>
            </div>
            <div className={styles[`${PARENT_CLASSNAME}__column-legend`]}>{l('_Entry')}</div>
          </div>
          {[...Array(chartForecastsCount)].map((_, i) => (
            <Fragment key={'debt-item-' + i}>
              <NetDebtColumn
                maxNetDebtAxisCount={getMaxNetDebtAxisCount()}
                isExpanded={isExpanded}
                chartForecastsCount={chartForecastsCount}
                forecastYear={forecastData[i]?.forecastDate}
                columnIndex={i}
                netDebt={Number(getCaseOrProjectNetDebt(i))}
                tooltipContent={createDebtTooltip(getCaseOrProjectNetDebt(i)?.toString())}
                labelTopValue={calculatedLabelTopValue}
              />
              {i === MAX_COLLAPSED_ITEMS - 1 && forecastData.length > MAX_COLLAPSED_ITEMS && (
                <div
                  className={classNames(styles['graph__expander-container'], {
                    [styles['graph__expander-container--secondary']]: !isExpanded,
                  })}>
                  <button
                    onClick={() => setIsExpanded && setIsExpanded(!isExpanded)}
                    className={styles['graph__expander']}>
                    {isExpanded ? <MinusIcon /> : <SvgPlus />}
                  </button>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OpmTotalEquityChart;
