import { FC, useCallback, useEffect } from 'react';
import styles from './benchmarking-inputs.module.scss';
import DealOverview from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/DealOverview';
import DealMetrics from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/DealMetrics';
import ManagementParticipation from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/ManagementParticipation';
import SweetEquityDetails from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/SweetEquityDetails';
import TaxJurisdictions from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/TaxJurisdictions';
import SponsorInvestment from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/SponsorInvestment';
import VestingAndLiquidity from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/VestingAndLiquidity';
import TaxValuation from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/TaxValuation';
import SchemeDesignAndManagementAdvice from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/SchemeDesignAndManagementAdvice';
import ReturnAnalysis, {
  AnalysisType,
} from '@app/modules/projects/inputs/benchmarking-inputs-2/sections/ReturnAnalysis';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import * as projectActions from '@core/store/project-slice';
import { BenchmarkingDto } from '@app/shared/models/contracts/project-dto';
import notificationStyles from '@app/shared/components/notification-message/notification-message.module.scss';
import { NotificationMessage } from '@app/shared/components/notification-message/NotificationMessage';
import { NotificationMessageType } from '@app/shared/components/notification-message/notification-message-enums';
import {
  readOnlyGreaterThanValidator,
  readOnlyPercentageValidator,
} from '@core/validations/read-only-validations';
import { useDefaultCalculatedBenchmarking2 } from './utils/useDefaultCalculatedBenchmarking2';
import { mergeDeep } from '@app/shared/helpers/deep-merge/merge-deep';
import { setMultipleFormValues } from '@app/shared/helpers/set-multiple-form-values/set-multiple-form-values';
import { useLocale } from '@app/core/hooks/useLocale';
import { generatePath, Navigate } from 'react-router-dom';
import { RouteConstants } from '../../RouteConstants';
import useTriggerEffect from '@app/core/hooks/useTriggerEffect';

const PARENT_CLASSNAME = 'benchmarking-inputs';
const NOTIFICATION_CLASSNAME = 'notification-message';

const BenchmarkingInputs2: FC = () => {
  const { benchmarkingInputs } = useAppSelector((state) => state.benchmarking);
  const defaultCalculatedBenchmarking = useDefaultCalculatedBenchmarking2(benchmarkingInputs);
  const dispatch = useAppDispatch();
  const { isBenchmarkingInputsValid, projectDraft: project } = useAppSelector(
    (state) => state.project
  );
  const benchmarking = useAppSelector((state) => state.project.projectDraft.benchmarking);
  const formMethods = useForm<BenchmarkingDto>({
    ...formConfigBase,
    defaultValues: {
      ...benchmarking,
    },
  });
  const { l } = useLocale();

  const { handleSubmit, getValues, trigger } = formMethods;

  useTriggerEffect(trigger);

  const submitData = useCallback(async () => {
    const formData = getValues();
    const mergedValues = mergeDeep(benchmarking, formData);
    await dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          benchmarking: {
            ...mergedValues,
          },
        },
      })
    ).unwrap();
  }, [benchmarking, dispatch, getValues, project]);

  const validatePercentageOfFixedReturnInstrumentWithinStrip = readOnlyPercentageValidator(
    benchmarkingInputs?.fixedReturnInstrumentWithinStripPc ?? 0
  );

  const managementStripTotalSubscriptionCost = readOnlyGreaterThanValidator(
    0,
    benchmarkingInputs?.managementStripTotalSubscriptionCost ?? 0
  );

  useEffect(() => {
    // If benchmarking is not the sole focus (benchmarkingOnly is false),
    // we reset the form fields to their default calculated values
    if (!benchmarking.benchmarkingOnly) {
      // Set the form fields to the combination of current benchmarking state
      // and default calculated values for non-editable fields
      const mergedValues = mergeDeep(benchmarking, defaultCalculatedBenchmarking);
      setMultipleFormValues(mergedValues, formMethods);
      // Trigger data submission after resetting the form to update the Redux store
      submitData();
    }
    // When the benchmarkingOnly toggle value is set to true, we set formMethods to
    // pick up the correct value for benchmarkingOnly
    else {
      // Set form fields to the current state of 'benchmarkingOnly' and re-submit so
      // that the calculated field values get persisted in the projectDraft to replace
      // the null values set when 'benchmarkingOnly' is false
      formMethods.setValue('benchmarkingOnly', benchmarking.benchmarkingOnly);
      submitData();
    }
    // We only want to trigger this effect if 'benchmarking.benchmarkingOnly' changes,
    // or if the default calculation values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benchmarking.benchmarkingOnly, defaultCalculatedBenchmarking]);

  if (!isBenchmarkingInputsValid) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <FormProvider {...formMethods}>
      <form className={styles[PARENT_CLASSNAME]} onBlur={handleSubmit(submitData, submitData)}>
        {project.storeBenchmarkingData && (
          <div className={styles[NOTIFICATION_CLASSNAME]}>
            <NotificationMessage type={NotificationMessageType.warning}>
              <div>
                <div>{l('_BenchmarkingDataWillBeStoredWhenTheProjectIsClosed')}</div>
                <div className={notificationStyles[`${NOTIFICATION_CLASSNAME}__sub-title`]}>
                  {l('_CheckThatAllInformationIsCorrectBeforeClosing')}
                </div>
              </div>
            </NotificationMessage>
          </div>
        )}
        {(validatePercentageOfFixedReturnInstrumentWithinStrip ||
          managementStripTotalSubscriptionCost) && (
          <div className={styles['notification']}>
            <NotificationMessage type={NotificationMessageType.warning}>
              {validatePercentageOfFixedReturnInstrumentWithinStrip && (
                <>
                  {l('_NamedFieldValueMustBeBetweenMinValueAndMaxValue', {
                    fieldName: l('_PercentageOfFixedReturnInstrumentWithinTheStrip'),
                    minValue: '0%',
                    maxValue: '100%',
                  })}
                </>
              )}
              {validatePercentageOfFixedReturnInstrumentWithinStrip &&
                managementStripTotalSubscriptionCost && <br />}
              {managementStripTotalSubscriptionCost && (
                <>
                  {l('_FieldNameMustBeGreaterThanValue', {
                    fieldName: l('_ManagementStripTotalSubscriptionCost'),
                    value: '0',
                  })}
                </>
              )}
            </NotificationMessage>
          </div>
        )}
        <DealOverview />
        <DealMetrics />
        <SponsorInvestment />
        <ManagementParticipation />
        <SweetEquityDetails />
        <TaxJurisdictions />
        <VestingAndLiquidity />
        <TaxValuation />
        <SchemeDesignAndManagementAdvice />
        <ReturnAnalysis analysisType={AnalysisType.Low} />
        <ReturnAnalysis analysisType={AnalysisType.Mid} />
        <ReturnAnalysis analysisType={AnalysisType.High} />
      </form>
    </FormProvider>
  );
};

export default BenchmarkingInputs2;
