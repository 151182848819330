import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  addNewUser,
  AddUsersState,
  resetAddUsersState,
  updateUsersValidation,
  deleteNewUser,
  postNewUsers,
  setRegionCodes,
  setRole,
  User,
  NewUser,
} from '@app/core/store/user-management-slice';
import { getRequiredValidator } from '@app/core/validations/hook-forms/validators';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { Card } from '@app/shared/components/card/Card';
import { CardCornersVariation, CardVariation } from '@app/shared/components/card/card-enums';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { NETWORK_ROLL_OUT_REGION_OPTIONS } from '@app/shared/constants/dropdown-values/network-rollout-regions';
import { ROLE_OPTIONS } from '@app/shared/constants/dropdown-values/role-options';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import SvgCircleMinus from '@app/shared/icons/CircleMinus';
import SvgPlus from '@app/shared/icons/Plus';
import {
  AllowedEmailDomains,
  Role,
  StructureDimension,
} from '@app/shared/models/contracts/enums/shared-enums';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './add-users.module.scss';
import SvgWarning from '@app/shared/icons/Warning';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import { RouteConstants } from '../../RouteConstants';
import store from '@app/core/store/store';
import * as notificationActions from '@core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import { uiValuesSlice } from '@app/core/store/ui-values-slice';
import NoAccessPage from '@app/shared/components/status-pages/NoAccessPage';

const PARENT_CLASSNAME = 'add-users';

export const AddUsers: FC = (): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const { navigate } = usePwcNavigate();
  const { newUsers, regionCodes, role } = useAppSelector((state) => state.userManagement);

  const currentUserRole = useAppSelector((state) => state.userInfo).userInfo.role as Role;
  const isGlobalAdmin = currentUserRole === Role.Administrator;
  const isRegionAdmin = currentUserRole === Role.RegionAdministrator;

  const [isDisabled, setIsDisabled] = useState(false);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  const roleOptions = ROLE_OPTIONS.filter(
    (option) => option.value !== Role.Administrator && option.value !== currentUserRole
  );

  const userRegions = useAppSelector((state) => state.userInfo).userInfo.regions;
  const regionOptions = isGlobalAdmin
    ? NETWORK_ROLL_OUT_REGION_OPTIONS
    : NETWORK_ROLL_OUT_REGION_OPTIONS.filter(
        (option) =>
          option.regionCode === '' ||
          userRegions?.some((region) => region.code === option.regionCode)
      );

  const formMethods = useForm<User>({
    ...formConfigBase,
    defaultValues: {
      email: '',
    },
  });
  const accessLevelFormMethods = useForm<AddUsersState>({
    defaultValues: {
      regionCodes: regionCodes,
      role: role,
    },
  });

  const hasEmailInput = formMethods.watch('email') !== '';
  const columns = [
    { label: l('_EmailAddress'), key: 'email' },
    { key: 'validations', width: '40%' },
    { key: 'delete', width: '10%' },
  ];

  const submitForm = (data: { email: string }) => {
    if (!data.email.includes(AllowedEmailDomains.PwC)) {
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.error,
          message: l('_InvalidEmailNotification'),
          autoHide: true,
        })
      );
      return;
    }
    dispatch(addNewUser({ email: data.email }));
    formMethods.reset();
  };

  const handleRoleChange = (value: string) => {
    const roleKey = Object.keys(Role).find((key) => key === value);
    const roleValue = Role[roleKey as keyof typeof Role];
    dispatch(setRole(roleValue));
  };

  const handleRegionsChange = (value: string) => {
    const selectedRegion = NETWORK_ROLL_OUT_REGION_OPTIONS.find(
      (region) => region.viewValue === value
    );
    const regionId = selectedRegion ? selectedRegion.regionCode : '';
    dispatch(setRegionCodes(regionId ? [regionId] : []));

    const updatedUsers = newUsers.map((user) => ({
      ...user,
      validationMessages: [],
    }));

    dispatch(updateUsersValidation(updatedUsers));
  };

  const handleDeleteUser = (index: number) => {
    dispatch(deleteNewUser(index));
  };

  const handleConfirmUsers = async () => {
    const result = await dispatch(postNewUsers()).unwrap();
    if (!result.some((user: NewUser) => (user.validationMessages?.length ?? 0) > 0)) {
      dispatch(resetAddUsersState());
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.success,
          message: newUsers.length > 1 ? l('_UserAddedSuccessfully') : l('_UsersAddedSuccessfully'),
          autoHide: true,
        })
      );
      navigate(`../../${RouteConstants.UserManagement}`);
    } else {
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.error,
          message: l('_UserExistsInTheSelectedTerritoryError'),
          autoHide: true,
        })
      );
    }
  };

  const handleCancelSelection = () => {
    navigate(`../../${RouteConstants.UserManagement}`);
    dispatch(resetAddUsersState());
  };

  useEffect(() => {
    if (newUsers.length === 0 || regionCodes.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [newUsers, regionCodes]);

  useEffect(() => {
    setHasValidationErrors(newUsers.some((user) => (user.validationMessages?.length ?? 0) > 0));
  }, [newUsers]);

  useEffect(() => {
    dispatch(uiValuesSlice.actions.setIsLoggedIn());
  }, [dispatch]);

  if (!isGlobalAdmin && !isRegionAdmin) {
    <NoAccessPage />;
  }

  return (
    <div style={{ maxWidth: StructureDimension.ModalWidth }}>
      <FormProvider {...formMethods}>
        <h2>{l('_AddUsers')}</h2>
        <Card
          hasShadow
          cornersVariation={CardCornersVariation.Secondary}
          variation={CardVariation.SecondarySpacing}>
          <form onSubmit={formMethods.handleSubmit(submitForm)}>
            <div className={styles[`${PARENT_CLASSNAME}__form-container`]}>
              <FormField
                name="email"
                label={l('_EmailAddress')}
                isGapless
                {...textFieldFormattingProps}
              />
              <Button
                appearance={ButtonAppearance.DEFAULT_PRIMARY}
                type="submit"
                startIcon={<SvgPlus />}
                ignoreReadOnly
                disabled={!hasEmailInput}>
                {l('_Add')}
              </Button>
            </div>
          </form>
          <div className={styles[`${PARENT_CLASSNAME}__table-spacing`]} />
          {newUsers.length > 0 && (
            <table className="table-primary table-primary--fixed table-primary--secondary-theme table-primary--list table-primary--remove-outer-padding">
              <colgroup>
                {columns.map((col, index) => (
                  <col
                    key={index}
                    className="table-primary__col--additional-data"
                    style={{ width: col.width }}
                  />
                ))}
              </colgroup>
              <thead>
                <tr>
                  {columns.map((col, index) => (
                    <th key={index}>
                      <span className="strong strong--alternate">{l(col.label)}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {newUsers.map((user, index) => {
                  const showShowWarning =
                    user.validationMessages && user.validationMessages.length > 0;
                  return (
                    <tr key={index}>
                      <td>{user.email}</td>
                      <td className={styles[`${PARENT_CLASSNAME}__validation`]}>
                        {showShowWarning && (
                          <SvgWarning className={styles[`${PARENT_CLASSNAME}__icon`]} />
                        )}
                        {user.validationMessages}
                      </td>
                      <td>
                        <SvgCircleMinus
                          className={styles[`${PARENT_CLASSNAME}__icon`]}
                          onClick={() => handleDeleteUser(index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Card>
      </FormProvider>
      <h2 className={styles[`${PARENT_CLASSNAME}__table-spacing`]}>{l('_AccessLevel')}</h2>
      <FormProvider {...accessLevelFormMethods}>
        <Card
          className={styles[`${PARENT_CLASSNAME}__form-container`]}
          hasShadow
          cornersVariation={CardCornersVariation.Secondary}
          variation={CardVariation.SecondarySpacing}>
          <FormSelect
            name="regions"
            label={l('_Regions')}
            options={regionOptions}
            rules={{ ...getRequiredValidator() }}
            isGapless
            onChange={(e) => handleRegionsChange(e.target.value)}
          />
          <FormSelect
            name="role"
            label={l('_Role')}
            options={roleOptions}
            rules={{ ...getRequiredValidator() }}
            isGapless
            onChange={(e) => handleRoleChange(e.target.value)}
          />
        </Card>
      </FormProvider>
      <div className={styles[`${PARENT_CLASSNAME}__action-buttons`]}>
        <Button
          appearance={ButtonAppearance.DEFAULT_SECONDARY}
          onClick={() => handleCancelSelection()}>
          {l('_Cancel')}
        </Button>
        <Button
          appearance={ButtonAppearance.DEFAULT_PRIMARY}
          onClick={handleConfirmUsers}
          disabled={isDisabled || hasValidationErrors}>
          {l('_Confirm')}
        </Button>
      </div>
    </div>
  );
};

export default AddUsers;
