import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import {
  EquitySourceDto,
  SourceDto,
  SourcesAndUsesDto,
} from '@app/shared/models/contracts/project-dto';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import * as projectActions from '@app/core/store/project-slice';
import { cloneDeep } from '@app/shared/helpers';
import TotalSources from '@app/modules/projects/inputs/capital-structure/tabs/sources-and-uses-tab/TotalSources';
import styles from './sources-and-uses-content.module.scss';
import { SourcesFormSection } from '@app/modules/projects/inputs/capital-structure/tabs/sources-and-uses-tab/SourcesFormSection';
import { UsesFormSection } from '@app/modules/projects/inputs/capital-structure/tabs/sources-and-uses-tab/UsesFormSection';
import TotalUses from '@app/modules/projects/inputs/capital-structure/tabs/sources-and-uses-tab/TotalUses';
import { Card } from '@app/shared/components/card/Card';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { setCapitalStructureSelectedTab } from '@app/core/store/ui-values-slice';
import useTriggerEffect from '@app/core/hooks/useTriggerEffect';

export const SourcesAndUsesContent = () => {
  useSetSelectedTab(RouteConstants.SourcesAndUses, setCapitalStructureSelectedTab);

  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const {
    equitySources,
    otherSources,
    uses,
    dayOneTransactionPurchasePrice,
    currentValuation,
    updatedSpotEquity,
  } = project.sourcesAndUses;

  const defaultEquitySourcesFieldValue = { narrative: '', amount: null };
  const defaultFieldValue = { type: '', narrative: '', amount: null };

  const formMethods = useForm<SourcesAndUsesDto>({
    ...formConfigBase,
    defaultValues: {
      equitySources: equitySources.length ? equitySources : [],
      uses: uses.length ? uses : [],
      otherSources: otherSources.length ? otherSources : [],
      dayOneTransactionPurchasePrice:
        dayOneTransactionPurchasePrice || dayOneTransactionPurchasePrice === 0
          ? dayOneTransactionPurchasePrice
          : null,
      currentValuation: currentValuation || currentValuation === 0 ? currentValuation : null,
      updatedSpotEquity: updatedSpotEquity || updatedSpotEquity === 0 ? updatedSpotEquity : null,
    },
  });
  const { handleSubmit, getValues, trigger } = formMethods;

  useTriggerEffect(trigger);

  const submitData = async () => {
    const formData = getValues();

    await dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          sourcesAndUses: {
            ...project.sourcesAndUses,
            equitySources: [...cloneDeep(formData.equitySources)],
            otherSources: [...cloneDeep(formData.otherSources)],
            uses: [...cloneDeep(formData.uses)],
            dayOneTransactionPurchasePrice: formData.dayOneTransactionPurchasePrice,
            currentValuation: formData.currentValuation,
            updatedSpotEquity: formData.updatedSpotEquity,
          },
        },
      })
    ).unwrap();
  };

  const totalUses = project.sourcesAndUses.uses.reduce(
    (acc, current) => acc + Number(current.amount ?? 0),
    0
  );

  const totalSources: number = [
    ...project.sourcesAndUses.equitySources,
    ...project.sourcesAndUses.otherSources,
  ].reduce((acc, current: EquitySourceDto | SourceDto) => acc + Number(current.amount ?? 0), 0);

  const shouldShowTotalsWarning = totalUses !== totalSources;

  return (
    <Card>
      <FormProvider {...formMethods}>
        <form
          onBlur={handleSubmit(
            submitData as SubmitHandler<SourcesAndUsesDto>,
            submitData as SubmitErrorHandler<SourcesAndUsesDto>
          )}>
          <div className={styles['layout']}>
            <div className={styles['layout__row']}>
              <div className={styles['layout__column']}>
                <SourcesFormSection
                  defaultEquitySourcesFieldValue={defaultEquitySourcesFieldValue}
                  defaultFieldValue={defaultFieldValue}
                  onRowDelete={submitData}
                />
              </div>
              <div className={styles['layout__column']}>
                <UsesFormSection defaultFieldValue={defaultFieldValue} onRowDelete={submitData} />
              </div>
            </div>
            <div className={styles['layout__row']}>
              <div className={styles['layout__column']}>
                <TotalSources value={totalSources} showWarning={shouldShowTotalsWarning} />
              </div>
              <div className={styles['layout__column']}>
                <TotalUses value={totalUses} showWarning={shouldShowTotalsWarning} />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};
