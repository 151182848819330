import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  greaterDateThanValidator,
  lesserOrEqualDateThanValidator,
  getRequiredValidator,
} from '@app/core/validations/hook-forms/validators';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

const NewProjectFormElements: FC = (): JSX.Element => {
  const { watch } = useFormContext();
  const { l } = useLocale();
  const { maxMonthsBetweenValuationAndFYED } = useAppSelector((state) => state.uiValues);
  const valuationDate = isFinite(new Date(watch('valuationDate')).getTime())
    ? new Date(watch('valuationDate'))
    : null;
  const latestAllowedFYEDDate = valuationDate?.addMonths(maxMonthsBetweenValuationAndFYED);

  return (
    <>
      <FormField
        name="projectName"
        label="Project Name"
        placeholder="Name"
        {...textFieldFormattingProps}
        rules={{
          ...getRequiredValidator(),
        }}
      />
      <FormDatePicker
        data-testid="valuationDate"
        name="valuationDate"
        label={l('_ValuationDate')}
        rules={{
          ...getRequiredValidator(),
        }}
      />
      <FormDatePicker
        data-testid="yearEndDate"
        name="yearEndDate"
        label={l('_FirstYearEndDate')}
        rules={{
          ...getRequiredValidator(),
          validate: {
            greaterDateThanValuationDate: greaterDateThanValidator(
              watch('valuationDate'),
              l('_Field1MustBeGreaterThanField2', {
                field1: l('_FirstYearEndDate'),
                field2: l('_ValuationDate'),
              })
            ).validate,
            notTooFarAfterValuationDate: lesserOrEqualDateThanValidator(
              latestAllowedFYEDDate?.toISOString() ?? null,
              l('_FirstYearEndDateCannotBeMoreThanXMonthsAfterValuationDate', {
                maxMonthsBetweenValuationAndFYED,
              })
            ).validate,
          },
        }}
      />
    </>
  );
};

export default NewProjectFormElements;
