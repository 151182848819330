import { SVGProps } from 'react';
const SvgShowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M17.664 2.976L24 8.99999L17.664 15.024C16.64 16 15.432 16.664 14.04 17.016C12.68 17.352 11.328 17.352 9.984 17.016C8.576 16.664 7.36 16 6.336 15.024L0 8.99999L6.336 2.976C7.36 1.99999 8.576 1.33599 9.984 0.983995C11.328 0.647995 12.68 0.647995 14.04 0.983995C15.432 1.33599 16.64 1.99999 17.664 2.976ZM21.816 8.99999L16.632 4.05599C15.8 3.27199 14.808 2.73599 13.656 2.44799C12.552 2.17599 11.448 2.17599 10.344 2.44799C9.192 2.73599 8.2 3.27199 7.368 4.05599L2.184 8.99999L7.368 13.944C8.2 14.728 9.192 15.264 10.344 15.552C11.448 15.824 12.552 15.824 13.656 15.552C14.808 15.264 15.8 14.728 16.632 13.944L21.816 8.99999ZM12 5.25599C12.672 5.25599 13.296 5.42399 13.872 5.75999C14.448 6.09599 14.904 6.55199 15.24 7.12799C15.576 7.70399 15.744 8.32799 15.744 8.99999C15.744 9.67199 15.576 10.296 15.24 10.872C14.904 11.448 14.448 11.904 13.872 12.24C13.296 12.576 12.672 12.744 12 12.744C11.328 12.744 10.704 12.576 10.128 12.24C9.552 11.904 9.096 11.448 8.76 10.872C8.424 10.296 8.256 9.67199 8.256 8.99999C8.256 8.32799 8.424 7.70399 8.76 7.12799C9.096 6.55199 9.552 6.09599 10.128 5.75999C10.704 5.42399 11.328 5.25599 12 5.25599ZM12 11.256C12.624 11.256 13.152 11.04 13.584 10.608C14.032 10.16 14.256 9.62399 14.256 8.99999C14.256 8.37599 14.032 7.84799 13.584 7.416C13.152 6.96799 12.624 6.74399 12 6.74399C11.376 6.74399 10.84 6.96799 10.392 7.416C9.96 7.84799 9.744 8.37599 9.744 8.99999C9.744 9.62399 9.96 10.16 10.392 10.608C10.84 11.04 11.376 11.256 12 11.256Z"
      fill="#474747"
    />
  </svg>
);
export default SvgShowIcon;
