import { SVGProps } from 'react';
const SvgCircleMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g
      id="Icon/Outline/circle-minus-outline"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g id="Group" transform="translate(-0.000000, -0.000000)" fill="#474747" fillRule="nonzero">
        <g id="circle-minus">
          <path
            d="M20.4855001,3.51450001 C25.1715001,8.20050003 25.1715001,15.7995001 20.4855001,20.4855001 C18.1425001,22.8285001 15.0720001,24 12,24 C8.92950004,24 5.85750002,22.8285001 3.51450001,20.4855001 C-1.1715,15.7995001 -1.1715,8.20050003 3.51450001,3.51450001 C5.85750002,1.1715 8.92950004,0 12,0 C15.0720001,0 18.1425001,1.1715 20.4855001,3.51450001 Z M19.4250001,19.4250001 C23.5185001,15.3315001 23.5185001,8.67000004 19.4250001,4.57500002 C17.4420001,2.59200001 14.8050001,1.50000001 12,1.50000001 C9.19500004,1.50000001 6.55800003,2.59200001 4.57500002,4.57500002 C2.59200001,6.55800003 1.50000001,9.19500004 1.50000001,12 C1.50000001,14.8050001 2.59200001,17.4420001 4.57500002,19.4250001 C6.55800003,21.4080001 9.19500004,22.5 12,22.5 C14.8050001,22.5 17.4420001,21.4080001 19.4250001,19.4250001 Z M5.25015002,12.75015 L5.25015002,11.25015 L18.75015,11.25015 L18.75015,12.75015 L5.25015002,12.75015 Z"
            id="ic_ol_Circle-Minus"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgCircleMinus;
