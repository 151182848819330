import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { ClientScheduleExportType } from '@app/shared/models/contracts/enums/shared-enums';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import FormPasswordField from '@app/shared/components/form-controls/form-field/FormPasswordField';
import { useLocale } from '@app/core/hooks/useLocale';
import SvgLockIcon from '@app/shared/icons/LockIcon';
import styles from './export-password-protect-modal.module.scss';
import { Trans } from 'react-i18next';
import FormCheckbox from '@app/shared/components/form-controls/form-checkbox/FormCheckbox';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import * as exportSlice from '@app/core/store/export-slice';

interface ExportPasswordProtectModalProps {
  onClose: () => void;
  isOpen: boolean;
  exportFileType: Nullable<ClientScheduleExportType>;
}

const ExportPasswordProtectModal: FC<ExportPasswordProtectModalProps> = ({
  onClose,
  isOpen,
  exportFileType,
}) => {
  const dispatch = useAppDispatch();
  const { projectDraft } = useAppSelector((state) => state.project);
  const { l } = useLocale();

  const formMethods = useForm({
    ...formConfigBase,
    mode: 'onChange',
    defaultValues: {
      password: '',
      isPasswordProtected: ['isPasswordProtected'],
    },
  });

  if (exportFileType === null) {
    return null;
  }

  const { watch, handleSubmit, setValue, getValues } = formMethods;

  const validatePassword = (password: string) => {
    const minLength = /.{8,}/;
    const upperCase = /[A-Z]/;
    const lowerCase = /[a-z]/;
    const number = /\d/;
    const symbol = /[\W_]/;

    const checks = [upperCase, lowerCase, number, symbol].filter((regex) =>
      regex.test(password)
    ).length;

    const types = [
      { regex: upperCase, isValid: upperCase.test(password), name: l('_UpperCase') },
      { regex: lowerCase, isValid: lowerCase.test(password), name: l('_LowerCase') },
      { regex: number, isValid: number.test(password), name: l('_Numbers') },
      { regex: symbol, isValid: symbol.test(password), name: l('_Symbols') },
    ];

    const hasMinLength = minLength.test(password);
    const isValid = checks >= 3 && hasMinLength;
    return { isValid, types, hasMinLength };
  };

  const formSubmitHandler: SubmitHandler<{ password: string }> = () => {
    dispatch(
      exportSlice.exportClientSchedule({
        projectId: projectDraft.id,
        type: exportFileType,
        password:
          getValues('password') === undefined || getValues('password') === ''
            ? null
            : getValues('password'),
      })
    );
    handleModalClose();
  };

  const handleModalClose = () => {
    setValue('password', '');
    setValue('isPasswordProtected', ['isPasswordProtected']);
    onClose();
  };

  const modalTitle = (
    <>
      <div className={styles['modal-title']}>
        <SvgLockIcon />
        <h2>{l('_SetExportPassword')}</h2>
      </div>
    </>
  );

  const passwordProtectSelected = watch('isPasswordProtected').length > 0;
  const currentPasswordState = validatePassword(watch('password'));

  return (
    <BaseModal
      size={ModalSize.Small}
      onCloseButtonClick={handleModalClose}
      onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick
      isOpen={isOpen}>
      {modalTitle}
      <Trans i18nKey="_ExportPasswordDisclaimer" />
      <FormProvider {...formMethods}>
        <form data-testid="new-event-form" onSubmit={handleSubmit(formSubmitHandler)}>
          <FormCheckbox
            name="isPasswordProtected"
            options={[{ viewValue: l('_EnablePasswordProtection'), value: 'isPasswordProtected' }]}
            onChange={() => {
              setValue('password', '');
            }}
          />
          {passwordProtectSelected && (
            <div className={styles['password-input-section']}>
              <FormPasswordField
                name="password"
                isValid={currentPasswordState.isValid}
                minLength={8}
                hasMinLength={currentPasswordState.hasMinLength}
                requiredCriteriaCount={3}
                types={currentPasswordState.types}
                placeholder={l('_EnterAPassword')}
              />
              {l('_PasswordProtectionWarning')}
            </div>
          )}
          <Button
            type="submit"
            data-testid="password-modal-submit-button"
            appearance={ButtonAppearance.DEFAULT_SECONDARY}
            size={ButtonSize.FULL_WIDTH}
            className={styles['password-confirm-button']}
            disabled={passwordProtectSelected ? !currentPasswordState.isValid : false}>
            {l('_DownloadFile')}
          </Button>
        </form>
      </FormProvider>
    </BaseModal>
  );
};

export default ExportPasswordProtectModal;
