import { FC, Fragment, useEffect } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { participationTableInstrumentByTranchesSortFn } from '@app/shared/helpers';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import {
  CalcMethod,
  ConjunctionType,
  EventKey,
  Operator,
  OwnerType,
  TargetMetric,
  TrancheKey,
} from '@app/shared/models/contracts/enums/shared-enums';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import styles from './participation-table.module.scss';
import { EmptyValues } from '@app/shared/constants/empty-values';
import classNames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import {
  amountValueFormatterProps,
  calculatedMultipleValueFormatterProps,
  dateFormatter,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import { GridSelectField } from '@app/shared/components/grid-controls/grid-select-field/GridSelectField';
import {
  setCapitalStructureParticipationTableSelectedYear,
  setCapitalStructureSelectedTab,
} from '@app/core/store/ui-values-slice';
import {
  CapitalStructureParticipationTableEventSetDto,
  CapitalStructureParticipationTableTierDto,
  CapitalStructureParticipationTableTierInstrumentDto,
  CapitalStructureParticipationTableTierInstrumentTrancheDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { ParticipationTableRowErf } from './ParticipationTableRowErf';
import { useLocale } from '@app/core/hooks/useLocale';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import { SelectOption } from '@app/shared/models/option';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';
import { selectCapitalStructures } from '@app/core/store/capital-structure-slice-selectors';

const PARENT_CLASSNAME = 'participation-table';

export type OrdinaryEquityInstrument = {
  hasPayoutLogic: boolean;
  instrumentId: string;
  instrumentNarrative: string;
  tranches: OrdinaryEquityInstrumentTranche[];
};

type OrdinaryEquityInstrumentTranche =
  CapitalStructureParticipationTableTierInstrumentTrancheDto & {
    id: string;
  };

interface ParticipationTableProps {
  shouldRenderStandaloneTable?: boolean;
  injectedEventSetId?: string;
  injectedForecastYear?: string;
}

export const ParticipationTableErf: FC<ParticipationTableProps> = ({
  shouldRenderStandaloneTable = true,
  injectedEventSetId,
  injectedForecastYear,
}) => {
  useSetSelectedTab(RouteConstants.ParticipationTable, setCapitalStructureSelectedTab);
  const { caseId } = useParams();
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const { participationTable: tableResults, buildStructures: buildStructureResults } =
    useAppSelector((state) => state.capitalStructure.values);
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructures = useAppSelector(selectCapitalStructures);
  const capitalStructureId = Object.keys(capitalStructures)[0];
  const isErfBuildStructure = buildStructureResults?.[capitalStructureId].isErf;
  const storedYear = useAppSelector(
    (state) => state.uiValues.userSelections.capitalStructure.participationTable.selectedYear
  );
  const { isCapitalStructureValid } = useAppSelector((state) => state.project);
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;
  const selectedCase =
    project.pwermInput.cases.find((item) => item.caseId === caseId) ?? project.pwermInput.cases[0];

  let forecastOptions: SelectOption[] = [];
  let eventSetId: string;
  let caseNames: string;

  if (isOpmOnly) {
    eventSetId =
      tableResults && Object.keys(tableResults.eventSets).length > 1
        ? EventKey.OpmOnly
        : EventKey.EmptyEventSet;
    forecastOptions = project.opmInput.perYearInputs.map((perYearInput) => ({
      value: perYearInput.forecastDate!,
      viewValue: dateFormatter(perYearInput.forecastDate!, { year: 'numeric' }),
    }));
  } else {
    // if we have the initial cap structure event ID, then the cases themselves will have a null eventSetId
    eventSetId = injectedEventSetId ?? selectedCase.eventSetId ?? EventKey.EmptyEventSet;
    const casesWithSameEventSet = project.pwermInput.cases.filter((item) => {
      if (eventSetId === EventKey.EmptyEventSet) {
        return item.eventSetId === null;
      }

      return item.eventSetId === eventSetId;
    });
    const caseInEventSetWithMostForecasts = getCaseWithMostForecasts(casesWithSameEventSet, true);

    forecastOptions = caseInEventSetWithMostForecasts!.forecasts.map((forecast) => ({
      value: forecast.forecastYear,
      viewValue: dateFormatter(forecast.forecastYear, { year: 'numeric' }),
    }));
    caseNames = casesWithSameEventSet.map((item) => item.narrative).join(', ');
  }

  const caseSelectorShown =
    isErfBuildStructure && project.pwermInput.cases.length > 1 && !isOpmOnly;
  const isStoredYearValid = forecastOptions.some((item) => item.value === storedYear);
  const defaultYear = isStoredYearValid
    ? storedYear
    : forecastOptions[forecastOptions.length - 1].value;
  const formMethods = useForm<{ selectedForecastYear: string }>({
    ...formConfigBase,
    defaultValues: {
      selectedForecastYear: defaultYear?.toString(),
    },
  });
  const { reset } = formMethods;
  const { selectedForecastYear } = formMethods.watch();
  const isSelectedForecastIdValid = forecastOptions.some(
    (option) => option.value === selectedForecastYear
  );

  useEffect(() => {
    // if the selected option is no longer relevant (i.e. forecast doesn't exist for the current case)
    // then reset the selection to the default value
    if (!isSelectedForecastIdValid) {
      reset();
    }
  }, [reset, isSelectedForecastIdValid]);

  useEffect(() => {
    if (selectedForecastYear !== storedYear) {
      dispatch(setCapitalStructureParticipationTableSelectedYear(selectedForecastYear));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedForecastYear]);

  if (!tableResults) {
    return null;
  }

  const activeForecast = injectedForecastYear ?? selectedForecastYear;

  const eventSet = tableResults.eventSets[eventSetId ?? EventKey.EmptyEventSet];
  const zeroTranchesKey = TrancheKey.DefaultTrancheKey;
  const getForecastYearOrDefault = (
    eventSet: CapitalStructureParticipationTableEventSetDto,
    activeForecast: string
  ) => {
    if (activeForecast in eventSet.years) {
      return eventSet.years[activeForecast];
    }

    return null;
  };
  const preHurdleParticipationTier = getForecastYearOrDefault(
    eventSet,
    activeForecast
  )?.tiers.findIndex((tier) => tier.conditions.length === 0);
  const sortedTranches =
    getForecastYearOrDefault(eventSet, activeForecast)
      ?.tiers.filter((tier) => Object.keys(tier.associatedInstruments).length > 0)
      .map((tier) => {
        const instrumentId = Object.keys(tier.associatedInstruments)[0];
        return {
          instrumentId: instrumentId,
          instrumentNarrative: eventSet.instrumentNarratives[instrumentId],
          trancheIndex: tier.associatedInstruments[instrumentId],
          ...tier,
        };
      })
      .sort((a, b) => a.order - b.order) ?? [];

  const equityInstruments: OrdinaryEquityInstrument[] = Object.keys(
    getForecastYearOrDefault(eventSet, activeForecast)?.tiers[0].instruments ?? {}
  ).map((instrument) => ({
    hasPayoutLogic: eventSet.years[activeForecast].tiers[0].instruments[instrument].hasPayoutLogic,
    instrumentId: instrument,
    instrumentNarrative: eventSet.instrumentNarratives[instrument],
    tranches: Object.entries(
      eventSet.years[activeForecast].tiers[0].instruments[instrument].tranches
    ).map(([key, value]) => ({ ...value, id: key })),
  }));

  const columnsCount = 2 + sortedTranches.length;

  const getConditionString = (tier: CapitalStructureParticipationTableTierDto) => {
    const getValueFormat = (targetMetric: TargetMetric) => {
      switch (targetMetric) {
        case TargetMetric.InstitutionalIRR:
          return percentageValueFormatterProps;
        case TargetMetric.InstitutionalMoM:
          return calculatedMultipleValueFormatterProps;
        default:
          return amountValueFormatterProps;
      }
    };

    return tier.conditions.map((condition, conditionIndex) => {
      const formattedValue =
        condition.testValue !== null
          ? numberValueFormatter({
              value:
                TargetMetric[condition.metric] === TargetMetric.InstitutionalIRR
                  ? condition.testValue * 100
                  : condition.testValue,
              ...getValueFormat(TargetMetric[condition.metric]),
            })
          : EmptyValues.EnDash;

      return (
        <Fragment key={conditionIndex}>
          {`${TargetMetric[condition.metric]} ${Operator[condition.operator]} ${formattedValue}`}
          {tier.conditions.length - 1 !== conditionIndex && tier.conjunction && (
            <span className={styles['conjunction']}>{` ${
              ConjunctionType[tier.conjunction]
            } `}</span>
          )}
        </Fragment>
      );
    });
  };

  const getPreHurdleParticipationTotalPerTranche = (instrumentId: string, trancheId: string) => {
    if (preHurdleParticipationTier !== undefined) {
      return Object.values(
        eventSet.years[activeForecast].tiers[preHurdleParticipationTier].instruments[instrumentId]
          .tranches[trancheId].owners
      ).reduce((acc, owner) => acc + owner, 0);
    }

    return null;
  };

  const getPreHurdleParticipationInstrumentTotalValue = (
    instrumentId: string,
    valueSelector: (instrument: CapitalStructureParticipationTableTierInstrumentDto) => number
  ) => {
    if (preHurdleParticipationTier !== undefined) {
      return valueSelector(
        eventSet.years[activeForecast].tiers[preHurdleParticipationTier].instruments[instrumentId]
      );
    }

    return null;
  };

  const getPreHurdleParticipationInstrumentValueByOwner = (
    instrumentId: string,
    trancheId: string,
    ownerKey: keyof typeof OwnerType
  ) => {
    if (preHurdleParticipationTier !== undefined) {
      return eventSet.years[activeForecast].tiers[preHurdleParticipationTier].instruments[
        instrumentId
      ].tranches[trancheId].owners[ownerKey];
    }

    return null;
  };

  const getPreHurdleParticipationTotal = (
    valueSelector: (tier: CapitalStructureParticipationTableTierDto) => number
  ) => {
    if (preHurdleParticipationTier !== undefined) {
      return valueSelector(eventSet.years[activeForecast].tiers[preHurdleParticipationTier]);
    }

    return null;
  };

  const getTierInstrumentTotalPerTranche = (
    instrumentId: string,
    trancheId: string,
    instruments: Dictionary<CapitalStructureParticipationTableTierInstrumentDto>
  ) =>
    Object.values(instruments[instrumentId].tranches[trancheId].owners).reduce(
      (acc, owner) => acc + owner,
      0
    );

  const getTierInstrumentValue = (
    instrumentId: string,
    instruments: Dictionary<CapitalStructureParticipationTableTierInstrumentDto>,
    valueSelector: (instrument: CapitalStructureParticipationTableTierInstrumentDto) => number
  ) => valueSelector(instruments[instrumentId]);

  const getTierInstrumentValueByOwner = (
    instrumentId: string,
    trancheId: string,
    instruments: Dictionary<CapitalStructureParticipationTableTierInstrumentDto>,
    owner: keyof typeof OwnerType
  ) => instruments[instrumentId].tranches[trancheId].owners[owner];

  const getTierInstrumentTotal = (
    tierOrder: number,
    valueSelector: (tier: CapitalStructureParticipationTableTierDto) => number
  ) => valueSelector(eventSet.years[activeForecast].tiers[tierOrder]);

  const renderOwnerships = (
    instrument: OrdinaryEquityInstrument,
    tranche: OrdinaryEquityInstrumentTranche
  ) =>
    Object.keys(tranche.owners).map((owner) => {
      return (
        <ParticipationTableRowErf
          key={owner}
          allSortedTranches={sortedTranches}
          renderLabelCellValue={() => <>{OwnerType[owner as keyof typeof OwnerType]}</>}
          renderPreHurdleCellValue={() => (
            <CellValue
              value={getCalculatedProjectValueInPercent(
                getPreHurdleParticipationInstrumentValueByOwner(
                  instrument.instrumentId,
                  tranche.id,
                  owner as keyof typeof OwnerType
                )
              )}
              {...enteredPercentageViewRules}
            />
          )}
          renderCellValue={({ item }) => (
            <CellValue
              value={getCalculatedProjectValueInPercent(
                getTierInstrumentValueByOwner(
                  instrument.instrumentId,
                  tranche.id,
                  item.instruments,
                  owner as keyof typeof OwnerType
                )
              )}
              {...enteredPercentageViewRules}
            />
          )}
          labelCellClassnames={classNames({
            ['table-primary__cell--indentation']: Boolean(instrument.hasPayoutLogic),
          })}
        />
      );
    });

  const trancheCount = 1 + sortedTranches.length; // + 1 for the pre-hurdle

  const renderTableContent = () => {
    return (
      <>
        <colgroup>
          <col className={styles[`${PARENT_CLASSNAME}__labels-col`]} />
          <col className={styles[`${PARENT_CLASSNAME}__pre-hurdle-col`]} />
          {sortedTranches.map((tranche) => (
            <col
              className={styles[`${PARENT_CLASSNAME}__tranches-col`]}
              key={tranche.instrumentId + tranche.trancheIndex}
            />
          ))}
        </colgroup>
        <thead className="table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
          {!shouldRenderStandaloneTable && (
            <tr>
              <th
                className={classNames(
                  'table-primary__cell--header-tertiary table-primary__cell--vertical-separator',
                  styles[`${PARENT_CLASSNAME}__header-cell`]
                )}>
                <strong>{l('_CaseNarratives')}</strong>
              </th>
              <th
                className={classNames(
                  'table-primary__cell--header-tertiary table-primary__cell--vertical-separator',
                  styles[`${PARENT_CLASSNAME}__header-cell`]
                )}
                colSpan={trancheCount}>
                <strong>{caseNames}</strong>
              </th>
            </tr>
          )}
          <tr>
            <th
              className={classNames(
                'table-primary__cell--header-tertiary table-primary__cell--vertical-separator',
                styles[`${PARENT_CLASSNAME}__header-cell`]
              )}>
              <strong>{l('_ForecastYear')}</strong>
            </th>
            <th
              className={classNames(
                'table-primary__cell--header-tertiary table-primary__cell--vertical-separator table-primary__cell--right',
                styles[`${PARENT_CLASSNAME}__header-cell`]
              )}>
              <strong>{l('_PreHurdleParticipation')}</strong>
            </th>
            {sortedTranches.map((item, index) => (
              <Fragment key={item.instrumentId + item.trancheIndex}>
                <th
                  className={classNames(
                    'table-primary__cell--header-tertiary table-primary__cell--right table-primary__cell--vertical-separator table-primary__cell--wrap',
                    styles[`${PARENT_CLASSNAME}__header-cell`]
                  )}>
                  <div className={styles[`${PARENT_CLASSNAME}__header-pre-title`]}>
                    {l('_HurdleNumber', { number: index + 1 })}
                  </div>
                  <strong className={styles[`${PARENT_CLASSNAME}__header-title`]}>
                    {item.instrumentNarrative} {l('_Tranche')} {item.trancheIndex + 1}
                  </strong>
                </th>
              </Fragment>
            ))}
          </tr>
          <tr>
            <th className="table-primary__cell--vertical-separator">
              {shouldRenderStandaloneTable ? (
                <GridSelectField
                  name="selectedForecastYear"
                  options={forecastOptions}
                  disabled={forecastOptions.length === 0}
                  ignoreReadOnly
                />
              ) : (
                <>{forecastOptions?.find((item) => item.value === activeForecast)?.viewValue}</>
              )}
            </th>
            <th className="table-primary__cell--vertical-separator" />
            {sortedTranches.map((item) => (
              <td
                key={item.instrumentId + item.trancheIndex}
                className="table-primary__cell--vertical-separator table-primary__cell--right">
                {getConditionString(item)}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {equityInstruments
            .sort(participationTableInstrumentByTranchesSortFn)
            .map((instrument) => (
              <Fragment key={instrument.instrumentId}>
                <tr>
                  <th colSpan={columnsCount} className="table-primary__cell--header">
                    {instrument.instrumentNarrative}
                  </th>
                </tr>
                {instrument.hasPayoutLogic
                  ? instrument.tranches?.map((tranche, index) => (
                      <Fragment key={tranche.id}>
                        <ParticipationTableRowErf
                          allSortedTranches={sortedTranches}
                          renderLabelCellValue={() => (
                            <>{l('_TrancheN', { n: tranche.index + 1 })}</>
                          )}
                          renderPreHurdleCellValue={() =>
                            Object.keys(tranche.owners).length > 1 ? (
                              <CellValue
                                strong
                                value={getCalculatedProjectValueInPercent(
                                  getPreHurdleParticipationTotalPerTranche(
                                    instrument.instrumentId,
                                    tranche.id
                                  )
                                )}
                                {...enteredPercentageViewRules}
                              />
                            ) : (
                              <></>
                            )
                          }
                          renderCellValue={({ item }) =>
                            Object.keys(tranche.owners).length > 1 ? (
                              <CellValue
                                strong
                                value={getCalculatedProjectValueInPercent(
                                  getTierInstrumentTotalPerTranche(
                                    instrument.instrumentId,
                                    tranche.id,
                                    item.instruments
                                  )
                                )}
                                {...enteredPercentageViewRules}
                              />
                            ) : (
                              <></>
                            )
                          }
                          labelCellClassnames="table-primary__cell--strong"
                          cellClassnames={classNames({
                            ['table-primary__cell--section-start-separator']: index > 0,
                          })}
                        />
                        {renderOwnerships(instrument, tranche)}
                      </Fragment>
                    ))
                  : renderOwnerships(
                      instrument,
                      instrument.tranches.find((t) => t.id === zeroTranchesKey)!
                    )}
                {((instrument.tranches && instrument.hasPayoutLogic) ||
                  Object.keys(instrument.tranches[0].owners).length > 1) && (
                  <ParticipationTableRowErf
                    allSortedTranches={sortedTranches}
                    renderLabelCellValue={() => <>{l('_Total')}</>}
                    renderPreHurdleCellValue={() => (
                      <CellValue
                        strong
                        value={getCalculatedProjectValueInPercent(
                          getPreHurdleParticipationInstrumentTotalValue(
                            instrument.instrumentId,
                            (instrument) => instrument.totalParticipation
                          )
                        )}
                        {...enteredPercentageViewRules}
                      />
                    )}
                    renderCellValue={({ item }) => (
                      <CellValue
                        strong
                        value={getCalculatedProjectValueInPercent(
                          getTierInstrumentValue(
                            instrument.instrumentId,
                            item.instruments,
                            (instrument) => instrument.totalParticipation
                          )
                        )}
                        {...enteredPercentageViewRules}
                      />
                    )}
                    labelCellClassnames="table-primary__cell--strong"
                    cellClassnames="table-primary__cell--section-start-separator"
                    rowClassnames="table-primary__row--header"
                  />
                )}
                <tr>
                  {[...Array(columnsCount)].map((_, i) => (
                    <th
                      key={i}
                      className="table-primary__cell--vertical-separator table-primary__cell--section-separator"
                    />
                  ))}
                </tr>
              </Fragment>
            ))}
          <ParticipationTableRowErf
            allSortedTranches={sortedTranches}
            renderLabelCellValue={() => <>{l('_TotalParticipation')}</>}
            renderPreHurdleCellValue={() => (
              <CellValue
                value={getCalculatedProjectValueInPercent(
                  getPreHurdleParticipationTotal((tier) => tier.totalParticipation)
                )}
                strong
                {...enteredPercentageViewRules}
              />
            )}
            renderCellValue={({ item }) => (
              <CellValue
                value={getCalculatedProjectValueInPercent(
                  getTierInstrumentTotal(item.order, (tier) => tier.totalParticipation)
                )}
                strong
                {...enteredPercentageViewRules}
              />
            )}
            rowClassnames="table-primary__row--header"
            labelCellClassnames="table-primary__cell--strong"
            cellClassnames="table-primary__cell--section-start-separator"
          />
          <ParticipationTableRowErf
            allSortedTranches={sortedTranches}
            renderLabelCellValue={() => <>{l('_SponsorGrossUpPc')}</>}
            renderPreHurdleCellValue={() => (
              <CellValue
                value={getCalculatedProjectValueInPercent(
                  getPreHurdleParticipationTotal((tier) => tier.sponsorGrossUp)
                )}
                strong
                {...enteredPercentageViewRules}
              />
            )}
            renderCellValue={({ item }) => (
              <CellValue
                value={getCalculatedProjectValueInPercent(
                  getTierInstrumentTotal(item.order, (tier) => tier.sponsorGrossUp)
                )}
                strong
                {...enteredPercentageViewRules}
              />
            )}
            labelCellClassnames="table-primary__cell--strong"
            cellClassnames="table-primary__cell--background-tertiary"
          />
          {!shouldRenderStandaloneTable && (
            <>
              <tr>
                <td />
                <td />
                {sortedTranches.map((tranche) => (
                  <td key={tranche.instrumentId + tranche.trancheIndex} />
                ))}
              </tr>
              <tr>
                <td />
                <td />
                {sortedTranches.map((tranche) => (
                  <td key={tranche.instrumentId + tranche.trancheIndex} />
                ))}
              </tr>
              <tr>
                <td />
                <td />
                {sortedTranches.map((tranche) => (
                  <td key={tranche.instrumentId + tranche.trancheIndex} />
                ))}
              </tr>
            </>
          )}
        </tbody>
      </>
    );
  };

  if (!isCapitalStructureValid) {
    const newPath = caseId
      ? generatePath(`../../${RouteConstants.BuildStructure}`)
      : generatePath(`../${RouteConstants.BuildStructure}`);
    return <Navigate to={newPath} />;
  }

  return shouldRenderStandaloneTable ? (
    <>
      <div
        className={classNames('main-container-padding-remove', {
          [styles[`${PARENT_CLASSNAME}__container`]]: caseSelectorShown,
        })}>
        <FormProvider {...formMethods}>
          <form>
            <table className="table-primary table-primary--framed table-primary--zebra">
              {renderTableContent()}
            </table>
          </form>
        </FormProvider>
      </div>
    </>
  ) : (
    <>{renderTableContent()}</>
  );
};
