import { FC } from 'react';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import * as projectListActions from '@app/core/store/project-list-slice';
import * as projectActions from '@core/store/project-slice';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { usePwcNavigate } from '@core/hooks/routing-hooks';
import { usePaginationHelper } from '@app/core/hooks/customUsePaginationHelper';
import { SearchParamKey } from '@app/shared/models/search-param-enum';
import { useLocale } from '@app/core/hooks/useLocale';

interface DeleteProjectConfirmModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  projectName?: string;
  projectId?: number;
  shouldRedirectAfter: boolean;
}

export const DeleteProjectConfirmModal: FC<DeleteProjectConfirmModalProps> = ({
  isOpen,
  projectId,
  projectName,
  setIsOpen,
  shouldRedirectAfter,
}): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const { navigate, generatePath } = usePwcNavigate();
  const projectList = useAppSelector((state) => state.projectList.values);
  const { searchParams, setSearchParams, getPagedRequestParams } = usePaginationHelper();

  const onlyMyProjects = searchParams.get(SearchParamKey.FilterMyProjects);

  const projectIdUsage = projectId ? projectId : project.id;
  const projectNameUsage = projectName ? projectName : project.name;

  const handleProjectDelete = async () => {
    if (shouldRedirectAfter) {
      dispatch(projectActions.deleteExistingProject())
        .unwrap()
        .then(() => {
          navigate(generatePath('/projects'));
        });
    } else {
      await dispatch(projectListActions.deleteProject(projectIdUsage))
        .unwrap()
        .then(() => {
          dispatch(
            projectListActions.fetchProjectList({
              ...getPagedRequestParams(projectList),
              filterMyProjects: onlyMyProjects !== 'false',
            })
          );
          if (projectList.data?.length === 1) {
            setSearchParams({ key: SearchParamKey.CurrentPage, value: '0' });
          }
          setIsOpen(false);
        });
    }
  };

  return (
    <BaseModal
      size={ModalSize.Small}
      onCloseButtonClick={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      onConfirm={handleProjectDelete}
      title={l('_ProjectNameWillBePermanentlyDeleted', { projectName: projectNameUsage })}
      isOpen={isOpen}
      buttonConfirmLabel={l('_PermanentlyDelete')}
    />
  );
};
