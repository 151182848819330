import { FC, useEffect, useState } from 'react';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { ProjectAccessUserDto } from '@app/shared/models/contracts/project-access-user-dto';
import * as projectActions from '@core/store/project-slice';
import * as userListActions from '@core/store/user-list-slice';
import { ErrorMessageModel } from '@app/core/utils/error-handling';
import styles from './project-access.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { FormSelectAdvanced } from '@app/shared/components/form-controls/form-select-advanced/FormSelectAdvanced';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import {
  entryMustExist,
  getRequiredValidator,
  uniquenessValidator,
} from '@core/validations/hook-forms/validators';
import { featureAvailability } from '@core/utils/feature-availability';
import classNames from 'classnames';
import { moveCurrentUserToTheTopOfTheList } from '@app/shared/helpers/sort/move-current-user-to-the-top';
import { useLocale } from '@app/core/hooks/useLocale';

interface ProjectAccessModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  projectName?: string;
  projectId?: number;
  userHasAccess?: boolean;
}

const PARENT_CLASSNAME = 'project-access';
export const ProjectAccessModal: FC<ProjectAccessModalProps> = ({
  isOpen,
  projectId,
  setIsOpen,
  projectName,
  userHasAccess,
}): JSX.Element => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const dispatch = useAppDispatch();
  const projectIdUsage = projectId ? projectId : project.id;
  const projectNameUsage = projectName ? projectName : project.name;
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const fullUserList = useAppSelector((state) => state.userList.values);
  const showActions =
    featureAvailability(['Administrator'], userInfo) ||
    featureAvailability(['RegionAdministrator'], userInfo) ||
    featureAvailability(['TeamMember'], userInfo, userHasAccess);

  const [userList, setUserList] = useState<
    ProjectAccessUserDto[] | undefined | ErrorMessageModel
  >();

  useEffect(() => {
    retrieveFullUserList();
    retrieveProjectUsersList();
    // hook is needed only on initial launch
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formMethods = useForm<any>({
    ...formConfigBase,
    defaultValues: {
      email: '',
    },
  });

  const retrieveProjectUsersList = () => {
    dispatch(projectActions.fetchProjectUserList(projectIdUsage)).then((result) => {
      const returnedResult = result.payload;

      if (Array.isArray(returnedResult)) {
        const sortedArray = moveCurrentUserToTheTopOfTheList(returnedResult, userInfo.email);
        setUserList(sortedArray);
      }
    });
  };

  const retrieveFullUserList = async () => {
    await dispatch(userListActions.fetchUserList()).unwrap();
  };

  const removeUser = async (userEmail: string) => {
    await dispatch(
      projectActions.removeUsersAccessFromProject({
        projectId: projectIdUsage,
        removeUsers: [{ email: userEmail }],
      })
    ).unwrap();
    retrieveProjectUsersList();
  };

  const submitForm = async () => {
    await dispatch(
      projectActions.giveUserAccessToProject({
        projectId: projectIdUsage,
        addUsers: [{ email: formMethods.getValues().email.value }],
      })
    ).unwrap();
    retrieveProjectUsersList();
    formMethods.reset();
  };

  const filterUserList = () => {
    if (Array.isArray(fullUserList) && Array.isArray(userList)) {
      return fullUserList.filter(
        (fullUserItem) =>
          !userList.find(
            (userItem) =>
              fullUserItem?.email.toLowerCase() === userItem?.email.toLowerCase() ||
              fullUserItem?.role !== 'TeamMember'
          )
      );
    }
  };

  const formSubmitHandler = async () => {
    await submitForm();
  };

  const PROJECT_USER_ACCESS_OPTIONS = filterUserList()?.map((x) => {
    return {
      value: x.email,
      viewValue: x.email,
    };
  });

  return (
    <BaseModal
      size={ModalSize.Small}
      onCloseButtonClick={() => setIsOpen(false)}
      title="Project Access"
      isOpen={isOpen}
      ignoreReadOnly>
      <div className={styles[`${PARENT_CLASSNAME}`]}>
        <p className={styles[`${PARENT_CLASSNAME}__description`]}>
          {showActions ? 'Control who can edit' : 'Users who have access to'} {projectNameUsage}.{' '}
          {showActions && <>{l('_AdministratorsCanNotBeAddedToProjects')}</>}
        </p>
        {showActions && (
          <FormProvider {...formMethods}>
            <form
              data-cy="user-add-form"
              className={styles[`${PARENT_CLASSNAME}__form`]}
              onSubmit={formMethods.handleSubmit(formSubmitHandler)}>
              {Array.isArray(userList) && PROJECT_USER_ACCESS_OPTIONS && (
                <FormSelectAdvanced
                  options={PROJECT_USER_ACCESS_OPTIONS}
                  placeholder="Search for an email address..."
                  rules={{
                    ...entryMustExist(
                      PROJECT_USER_ACCESS_OPTIONS,
                      'value',
                      'This user does not exist in the platform.'
                    ),
                    ...getRequiredValidator(),
                    ...uniquenessValidator(
                      userList,
                      'email',
                      'This user has already been added to the project.'
                    ),
                  }}
                  noOptionsMessage={() => {
                    return 'No users found, please ensure that they have access to Equity Reward Analytics';
                  }}
                  name="email"
                  ignoreReadOnly
                />
              )}
              <Button appearance={ButtonAppearance.DEFAULT_PRIMARY} type="submit" ignoreReadOnly>
                {l('_AddUser')}
              </Button>
            </form>
          </FormProvider>
        )}
        {Array.isArray(userList) && userList.length > 0 && userList[0] !== undefined && (
          <>
            <table className="table-primary table-primary--fixed table-primary--secondary-theme table-primary--list table-primary--remove-outer-padding">
              <thead>
                <tr>
                  <th>
                    <span className="strong strong--alternate">{l('_EmailAddress')}</span>
                  </th>
                </tr>
              </thead>
            </table>
            <div className={styles[`${PARENT_CLASSNAME}__scroll-container`]}>
              <table className="table-primary table-primary--fixed table-primary--secondary-theme table-primary--list table-primary--remove-outer-padding">
                <tbody>
                  {userList.length > 0 &&
                    userList[0] !== undefined &&
                    userList.map((user: ProjectAccessUserDto, index: number) => (
                      <tr key={'user-' + index}>
                        <td
                          className={classNames([
                            'table-primary__cell--truncate',
                            styles[`${PARENT_CLASSNAME}__list-item`],
                          ])}
                          title={user?.email}>
                          {user?.email}
                          {userInfo?.email === user?.email && (
                            <span className="secondary-text"> ({l('_You')})</span>
                          )}
                        </td>
                        <td style={{ width: '104px' }}>
                          {userInfo?.email !== user?.email && showActions && (
                            <div className={styles[`${PARENT_CLASSNAME}__list-actions`]}>
                              <Button
                                onClick={() => removeUser(user.email)}
                                appearance={ButtonAppearance.DANGER_TERTIARY}
                                ignoreReadOnly>
                                {l('_Remove')}
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {(Array.isArray(userList) && userList.length === 0) ||
          (Array.isArray(userList) && userList[0] === undefined && (
            <p className="no-data-message">{l('_ThereAreNoUsersAssignedToThisProject')}</p>
          ))}
      </div>
    </BaseModal>
  );
};
