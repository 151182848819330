import { RouteObject } from 'react-router-dom';
import projectsRoutes from '@app/modules/projects/ProjectsRoutes';
import { DevFeatureSetup } from '@app/modules/dev-feature-setup/DevFeatureSetup';
import App from '@app/App';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import NotFoundPage from '@app/shared/components/status-pages/NotFoundPage';
import NoAccessPage from '@app/shared/components/status-pages/NoAccessPage';
import ErrorPage from '@app/shared/components/status-pages/ErrorPage';
import { RootAppRoutes } from '@app/shared/enums/root-app-routes';
import usersRoutes from '@app/modules/users/UsersRoutes';
import { GlobalHistory } from '@app/shared/components/global-navigate/GlobalNavigate';
import RedirectPage from './shared/components/status-pages/RedirectPage';
import env from '@environment';
import UnderMaintenancePage from './modules/projects/maintenance/UnderMaintenancePage';
import adminRoutes from './modules/admin/AdminRoutes';
export interface RouteItem {
  route: string;
  title: string;
}
let projectRoutes: RouteObject[];
if (env.redirectUrl && env.redirectUrl.length > 0) {
  projectRoutes = [
    {
      path: '*',
      element: (
        <>
          <GlobalHistory />
          <App />
        </>
      ),
      children: [{ path: '*', element: <RedirectPage /> }],
    },
  ];
} else {
  projectRoutes = [
    {
      path: '/',
      element: (
        <>
          <GlobalHistory />
          <App />
        </>
      ),
      children: [
        {
          index: true,
          element: <PwcNavigate route="/projects" />,
        },
        {
          path: 'projects',
          children: [...projectsRoutes],
          handle: {
            crumb: { title: () => 'Projects' },
          },
        },
        {
          path: 'users',
          children: [...usersRoutes],
          handle: {
            crumb: { title: () => 'Users' },
          },
        },
        {
          path: 'admin',
          children: [...adminRoutes],
          handle: {
            crumb: { title: () => 'Admin' },
          },
        },
        { path: 'commercial-design', element: <div>Commercial Design</div> },
        { path: 'benchmarking', element: <div>Benchmarking</div> },
        { path: 'dev-feature-setup', element: <DevFeatureSetup /> },
        {
          path: RootAppRoutes.Forbidden,
          element: <NoAccessPage />,
        },
        {
          path: RootAppRoutes.Error,
          element: <ErrorPage />,
        },
        {
          path: RootAppRoutes.NotFound,
          element: <NotFoundPage />,
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
        {
          path: RootAppRoutes.Maintenance,
          element: <UnderMaintenancePage />,
        },
      ],
    },
  ];
}

export const setAppRoutes = (): RouteObject[] => {
  return projectRoutes;
};
