import { FC } from 'react';
import { SUPPORT_EMAIL } from '@app/shared/constants/emails';
import { useLocale } from '@app/core/hooks/useLocale';

const NoAccessContent: FC = (): JSX.Element => {
  const { l } = useLocale();

  return (
    <>
      <h1 className="heading-2">{l('_YouDoNotHaveAccessToEquityRewardAnalytics')}</h1>
      <p>
        {l('_ToGetAccessPlease')}
        <a href={`mailto:${SUPPORT_EMAIL}`}>{l('_ContactTheDealRewardTeam')}</a>
      </p>
    </>
  );
};

export default NoAccessContent;
