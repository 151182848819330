import { FC, InputHTMLAttributes } from 'react';
import { useController } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import FieldLabel from '../field-label/FieldLabel';
import { FormControlPropsBase } from '../shared-types';
import FieldValidationMessage from '../fidl-validation-message/FieldValidationMessage';
import styles from './form-checkbox.module.scss';

export interface FormCheckboxOption {
  viewValue: string;
  value?: string;
  checked?: boolean;
}

interface FormCheckboxProps extends InputHTMLAttributes<HTMLInputElement>, FormControlPropsBase {
  options: FormCheckboxOption[];
  name: string;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
  label?: string;
}

const FormCheckbox: FC<FormCheckboxProps> = ({
  options,
  name,
  label,
  rules,
  required,
  onChange,
  ignoreReadOnly = false,
  disabled = false,
}) => {
  const {
    field: { value: fieldValue = [], onChange: fieldOnChange },
    fieldState: { error },
  } = useController({ name, rules });

  const isReadOnly = useIsReadOnly();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const updatedValue = isChecked
      ? [...fieldValue, event.target.name]
      : fieldValue.filter((value: string) => value !== event.target.name);
    fieldOnChange(updatedValue);
    if (onChange) {
      onChange(event);
    }
    event.target.blur();
  };

  const handleLabelClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div>
      {label && (
        <div>
          <FieldLabel name={name} required={required ?? (rules && 'required' in rules)}>
            {label}
          </FieldLabel>
        </div>
      )}
      <FormGroup>
        {options.map((checkboxOption, index) => (
          <FormControlLabel
            key={'form-checkbox' + index + checkboxOption.value}
            className={styles['checkbox-item']}
            control={
              <Checkbox
                id={
                  checkboxOption.value
                    ? checkboxOption.value?.toString()
                    : 'checkboxField' + index + name
                }
                data-testid="password-protect-checkbox"
                name={checkboxOption.value?.toString()}
                checked={
                  fieldValue.includes(checkboxOption.value) || Boolean(checkboxOption.checked)
                }
                onChange={handleCheckboxChange}
                disabled={disabled || (!ignoreReadOnly && isReadOnly)}
                color="primary"
                value={checkboxOption.value}
              />
            }
            label={<span onClick={handleLabelClick}>{checkboxOption.viewValue}</span>}
          />
        ))}
      </FormGroup>
      {error && (
        <div>
          <FieldValidationMessage error={error} />
        </div>
      )}
    </div>
  );
};

export default FormCheckbox;
