import { FC, ReactNode } from 'react';
import styles from './entry-page-routes-wrapper.module.scss';
import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import classNames from 'classnames';
import {
  featureAvailability,
  featureAvailabilityWithRegions,
} from '@core/utils/feature-availability';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { Role } from '@app/shared/models/contracts/enums/shared-enums';

const PARENT_CLASSNAME = 'entry-page-routes-wrapper';

type EntryPageRoutesWrapperProps = {
  children: ReactNode;
  actions?: ReactNode;
};

const EntryPageRoutesWrapper: FC<EntryPageRoutesWrapperProps> = ({
  children,
  actions,
}): JSX.Element => {
  const { l } = useLocale();
  document.title = 'Equity Reward Analytics';
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const navigationItems: NavigationItem[] = [
    {
      label: 'Projects',
      route: '../projects',
    },
    // if user is an admin and GlobalApi feature is not available, show the users navigation item
    // if user is an admin and GlobalApi feature is available, do not show the users navigation item, as users are managed in the Admin interface
    ...(featureAvailability([Role.Administrator], userInfo) &&
    !featureAvailabilityWithRegions([Role.Administrator], userInfo)
      ? [
          {
            label: 'Users',
            route: '../users',
          },
        ]
      : []),
  ];

  return (
    <>
      <div className={styles[PARENT_CLASSNAME]}>
        <div className={styles[`${PARENT_CLASSNAME}__header`]}>
          <div
            className={classNames([
              styles[`${PARENT_CLASSNAME}__content`],
              styles[`${PARENT_CLASSNAME}__content--header`],
            ])}>
            {navigationItems.length > 1 ? (
              <SecondaryNavigation navigationItems={navigationItems} />
            ) : (
              <h1 className={styles[`${PARENT_CLASSNAME}__title`]}>{l('_AllProjects')}</h1>
            )}
            {actions && <>{actions}</>}
          </div>
        </div>
        <div className={styles[`${PARENT_CLASSNAME}__content`]}>{children}</div>
      </div>
    </>
  );
};

export default EntryPageRoutesWrapper;
