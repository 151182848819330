import { FC } from 'react';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import * as notificationActions from '@core/store/notification-slice';
import { useAppDispatch } from '@core/hooks/redux-hooks';
import { usePwcNavigate } from '@core/hooks/routing-hooks';
import { useLocale } from '@app/core/hooks/useLocale';

const NotFoundContent: FC = (): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const { navigate } = usePwcNavigate();

  return (
    <>
      <h1 className="heading-2">{l('_ThatPageCouldNotBeFound')}</h1>
      <p className="heading-2 heading-2--light">{l('_PleaseTryTheFollowingOptions')}</p>
      <ul className="list-primary">
        <li>{l('_CheckUrlIsCorrect')}</li>
        <li>{l('_GoBackToThePreviousPage')}</li>
      </ul>
      <p>
        {l('_RedirectHomepageMessage')}
        <Button
          appearance={ButtonAppearance.LINK}
          onClick={() => {
            navigate('/');
            dispatch(notificationActions.closeNotificationModal());
          }}>
          {l('_HomePage')}
        </Button>
      </p>
    </>
  );
};

export default NotFoundContent;
