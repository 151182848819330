import { FC, useEffect } from 'react';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { selectValuedInstruments } from '@app/core/store/capital-structure-slice-selectors';
import { instrumentByTypeOrTranchesOrDefaultSortFn, mapAsRecord } from '@app/shared/helpers';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { CaseDto } from '@app/shared/models/contracts/project-dto';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import styles from '../../widgets/valuation-estimates-by-instrument/valuation-estimates-by-instrument.module.scss';
import classNames from 'classnames';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import { setPwermDashboard2ValuationEstimatesTableSelectedInstrument } from '@app/core/store/ui-values-slice';

export const Pwerm2ValuationEstimatesByInstrument: FC = (): JSX.Element => {
  const results = useAppSelector(
    (state) => state.pwerm2Calculation.calculatedResults.dashboard.valuationInstruments
  );
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const sortedProjectByCase = sortedProjectByCases(project);
  const storedInstrument = useAppSelector(
    (state) =>
      state.uiValues.userSelections.pwerm2Dashboard.valuationEstimatesTable.selectedInstrument
  );

  const valuedInstruments = useAppSelector(selectValuedInstruments);
  const sortedValuedInstruments = valuedInstruments.sort(instrumentByTypeOrTranchesOrDefaultSortFn);

  const instrumentOptions = [
    ...sortedValuedInstruments.map((instrument) => ({
      value: instrument.instrumentId,
      viewValue: instrument.instrumentNarrative,
    })),
  ];

  const isStoredInstrumentValid = sortedValuedInstruments.some(
    (instrument) => instrument.instrumentId === storedInstrument
  );

  const defaultViewOption = isStoredInstrumentValid
    ? storedInstrument
    : instrumentOptions[0]?.value;

  const formMethods = useForm<{ viewOption: string }>({
    ...formConfigBase,
    defaultValues: {
      viewOption: defaultViewOption,
    },
  });
  const { watch } = formMethods;
  const selectedViewOption = watch('viewOption');

  useEffect(() => {
    if (selectedViewOption !== storedInstrument) {
      dispatch(setPwermDashboard2ValuationEstimatesTableSelectedInstrument(selectedViewOption));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViewOption]);

  const selectedInstrument =
    sortedValuedInstruments.find((instrument) => instrument.instrumentId === selectedViewOption) ??
    sortedValuedInstruments[0];

  const { cases } = sortedProjectByCase.pwermInput;
  const getInstrumentData = (caseItem: CaseDto) => {
    const netExitWeightedProceedsPerInstrument =
      results[selectedInstrument?.instrumentId]?.cases[caseItem.caseId].weightedNetProceeds;

    const exitWeightedCostOfEquity =
      results[selectedInstrument?.instrumentId]?.cases[caseItem.caseId].weightedCostOfEquity;

    const presentValue =
      results[selectedInstrument?.instrumentId]?.cases[caseItem.caseId].weightedFairValue;

    const minorityDiscounts =
      results[selectedInstrument?.instrumentId]?.cases[caseItem.caseId]?.weightedMinorityDiscount;

    const marketValue =
      results[selectedInstrument?.instrumentId]?.cases[caseItem.caseId].weightedDiscountedFairValue;

    return [
      {
        value: netExitWeightedProceedsPerInstrument,
        formattingRules: amountRules,
      },
      {
        value: getCalculatedProjectValueInPercent(exitWeightedCostOfEquity),
        formattingRules: enteredPercentageViewRules,
      },
      {
        value: presentValue,
        formattingRules: amountRules,
      },
      {
        value: getCalculatedProjectValueInPercent(minorityDiscounts),
        formattingRules: enteredPercentageViewRules,
      },
      {
        value: marketValue,
        formattingRules: amountRules,
      },
    ];
  };

  const dataByCaseId = mapAsRecord(
    cases,
    (item) => getInstrumentData(item),
    (item) => item.caseId
  );

  const weightedAverageRowData = [
    {
      value: results[selectedInstrument?.instrumentId]?.weightedNetProceeds,
      formattingRules: amountRules,
    },
    {
      value: getCalculatedProjectValueInPercent(
        results[selectedInstrument?.instrumentId]?.weightedCostOfEquity
      ),
      formattingRules: enteredPercentageViewRules,
    },
    {
      value: results[selectedInstrument?.instrumentId]?.weightedFairValue,
      formattingRules: amountRules,
    },
    {
      value: getCalculatedProjectValueInPercent(
        results[selectedInstrument?.instrumentId]?.weightedMinorityDiscount
      ),
      formattingRules: enteredPercentageViewRules,
    },
    {
      value: results[selectedInstrument?.instrumentId]?.weightedDiscountedFairValue,
      formattingRules: amountRules,
    },
  ];

  return (
    <DashboardWidgetContainer
      title={l('_ValuationEstimatesByInstrument')}
      actions={
        <FormProvider {...formMethods}>
          <form className="dashboard-widget-controls">
            <div className="dashboard-widget-controls__item">
              <FormSelect
                ariaLabel={l('_SelectorAriaLabel', { label: l('_ChartView') })}
                name="viewOption"
                required
                options={instrumentOptions}
                disabled={instrumentOptions.length === 0}
                isGapless
                ignoreReadOnly
              />
            </div>
          </form>
        </FormProvider>
      }>
      <table className="table-primary table-primary--fixed table-primary--secondary-theme">
        <thead>
          <tr>
            <th className="table-primary__cell--header-quinary">{l('_Case')}</th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              {l('_NetExitWeightedProceeds')}
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              {l('_ExitWeightedCostOfEquity')}
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              {l('_PresentValue')}
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              {l('_MinorityDiscounts')}
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              {l('_MarketValue')}
            </th>
          </tr>
        </thead>
        <tbody>
          {getCasesWithNonZeroProbability(cases).map((caseItem, index) => {
            return (
              <tr key={index}>
                <td>
                  <span className="heading-2 heading-2--alternate">{caseItem.narrative}</span>
                </td>
                {dataByCaseId[caseItem.caseId].map((item, valueIndex) => (
                  <td key={valueIndex}>
                    <CellValue
                      value={item.value}
                      theme={CellValueTheme.Secondary}
                      className={classNames({
                        [styles['market-value-cell']]:
                          dataByCaseId[caseItem.caseId].length - 1 === valueIndex,
                      })}
                      {...item.formattingRules}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th className="table-primary__cell--header-quinary">{l('_CaseWeightedAverage')}</th>
            {weightedAverageRowData.map((item, index) => (
              <td key={index}>
                <CellValue
                  value={item.value}
                  theme={CellValueTheme.Secondary}
                  className={classNames(styles['weighted-value-cell'], {
                    [styles['market-value-cell']]: weightedAverageRowData.length - 1 === index,
                  })}
                  {...item.formattingRules}
                />
              </td>
            ))}
          </tr>
        </tfoot>
      </table>
    </DashboardWidgetContainer>
  );
};
