export enum GridFieldVariation {
  TableHeader = 'table-header',
}

export enum GridFieldTooltipVariation {
  FitContainer = 'fit-container',
  Right = 'right',
  Top = 'top',
  FixedPosition = 'fixed-position',
}
