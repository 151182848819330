import { SVGProps } from 'react';
const SvgLockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.99999 0C11.072 0 12.056 0.264 12.952 0.792C13.864 1.32 14.584 2.04 15.112 2.952C15.64 3.848 15.904 4.832 15.904 5.904V9.048H19.984V22.464C19.952 22.928 19.8 23.296 19.528 23.568C19.224 23.856 18.856 24 18.424 24H1.57599C1.12799 24 0.751991 23.848 0.447991 23.544C0.159991 23.256 0.0159912 22.896 0.0159912 22.464V9.048H4.09599V5.904C4.09599 4.832 4.35999 3.848 4.88799 2.952C5.41599 2.04 6.12799 1.32 7.02399 0.792C7.93599 0.264 8.92799 0 9.99999 0ZM18.424 10.584H1.57599V22.464H18.424V10.584ZM10.984 13.992V19.224H9.01599V13.992H10.984ZM9.99999 1.896C9.26399 1.896 8.58399 2.08 7.95999 2.448C7.33599 2.8 6.83199 3.28 6.44799 3.888C6.07999 4.496 5.87999 5.168 5.84799 5.904V9.048H14.152V5.904C14.136 5.168 13.936 4.496 13.552 3.888C13.168 3.28 12.664 2.8 12.04 2.448C11.416 2.08 10.736 1.896 9.99999 1.896Z"
      fill="#D04A02"
    />
  </svg>
);
export default SvgLockIcon;
