import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { enumKeyByValue, instrumentOwnersSortFn, sumBy } from '@app/shared/helpers';
import {
  EventKey,
  InstrumentType,
  OwnerType,
} from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { CapitalStructureDebtInstrumentEventSetDto } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { slugifyStrings } from '@app/shared/helpers/formatting/slugify';
import { selectSlnAndPrefSharesInstrumentsWithInitialValues } from '@app/core/store/capital-structure-slice-selectors';

export const SummaryShareholderDebt = () => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const debtInstruments = useAppSelector(selectSlnAndPrefSharesInstrumentsWithInitialValues);
  const opmInput = { ...project.opmInput };
  const selectedEventSetId = opmInput.selectedEventSetId ?? EventKey.EmptyEventSet;
  const capitalStructureResults = useAppSelector((state) => state.capitalStructure.values);
  const debtInstrumentResults =
    capitalStructureResults.debtInstrumentsByYear.eventSets[selectedEventSetId];
  const slnInstruments = debtInstruments.filter(
    (ins) => ins.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
  );
  const prefSharesInstruments = debtInstruments.filter(
    (ins) => ins.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
  );

  const sectionColCount =
    (opmInput?.perYearInputs.length ?? 0) + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  const historicalSummaries: {
    [key in Exclude<InstrumentType, InstrumentType.OrdinaryEquity>]: {
      [key in OwnerType]: number;
    };
  } = {
    [InstrumentType.ShareholderLoanNotes]: {
      [OwnerType.Institution]: 0,
      [OwnerType.Management]: 0,
      [OwnerType.CoInvestor]: 0,
      [OwnerType.Other]: 0,
    },
    [InstrumentType.PreferredShares]: {
      [OwnerType.Institution]: 0,
      [OwnerType.Management]: 0,
      [OwnerType.CoInvestor]: 0,
      [OwnerType.Other]: 0,
    },
  };

  debtInstruments.forEach((i) => {
    const instrumentType = InstrumentType[i.type as keyof typeof InstrumentType];

    // this won't ever happen but avoids some typescript gymnastics!
    if (instrumentType === InstrumentType.OrdinaryEquity) {
      return;
    }

    if (!i.initialValues?.ownership) {
      return;
    }

    for (const [owner, ownership] of Object.entries(i.initialValues.ownership)) {
      const ownerType = OwnerType[owner as keyof typeof OwnerType];
      const amount = Number(ownership.amount);

      historicalSummaries[instrumentType][ownerType]
        ? (historicalSummaries[instrumentType][ownerType] += !Number.isNaN(amount) ? amount : 0)
        : (historicalSummaries[instrumentType][ownerType] = !Number.isNaN(amount) ? amount : 0);
    }
  });

  const getUniqueOwnersByType = (instrumentType: keyof typeof InstrumentType) => {
    const ownersAcrossType = Object.values(debtInstrumentResults.years)
      .flatMap((y) => Object.keys(y.summary[instrumentType]?.perOwner ?? {}))
      .map((o) => o as keyof typeof OwnerType);
    return new Set([...ownersAcrossType]);
  };

  const ownersByType = {
    [enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)]: getUniqueOwnersByType(
      enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
    ),
    [enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)]: getUniqueOwnersByType(
      enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
    ),
  };

  const renderSummarySection = (
    capitalStructureData: CapitalStructureDebtInstrumentEventSetDto,
    type: keyof typeof InstrumentType,
    historicalSummary: { [key in OwnerType]?: number },
    title: string
  ): JSX.Element => {
    return (
      <>
        <tr className="table-primary__row--header">
          <th className="table-primary__cell--header-tertiary" colSpan={sectionColCount}>
            {title}
          </th>
        </tr>
        {[...ownersByType[type]].sort(instrumentOwnersSortFn).map((ownerType) => {
          const ownerTypeValue = OwnerType[ownerType as keyof typeof OwnerType];
          return (
            <tr key={ownerType}>
              <th
                className={classNames(
                  'table-primary__cell--vertical-separator table-primary__cell--indentation'
                )}>
                {ownerType} {l('_Total')}
              </th>
              <td>
                <CellValue
                  {...amountRules}
                  dataTestId={slugifyStrings(title, ownerTypeValue, 'historical-summary-values')}
                  value={historicalSummary[ownerTypeValue] ?? 0}
                />
              </td>
              {project.opmInput.perYearInputs.map((forecast) => (
                <td key={forecast.forecastDate}>
                  <CellValue
                    {...amountRules}
                    dataTestId={slugifyStrings(
                      title,
                      ownerTypeValue,
                      'summary-values',
                      forecast.forecastDate ?? ''
                    )}
                    value={
                      capitalStructureData.years[forecast.forecastDate ?? '']?.summary[type]
                        .perOwner[ownerType] ?? 0
                    }
                  />
                </td>
              ))}
              <td className={classNames('table-primary__cell--vertical-separator')} />
            </tr>
          );
        })}

        <tr className="table-primary__row--plain">
          <th className="table-primary__cell--vertical-separator">
            <strong>{l('_SummaryTitleTotal', { summaryTitle: title })}</strong>
          </th>
          <td>
            <CellValue
              {...amountRules}
              dataTestId={slugifyStrings(title, 'history-total')}
              value={sumBy(Object.values(historicalSummary), (i) => i)}
              strong
            />
          </td>
          {project.opmInput.perYearInputs.map((forecast) => (
            <td key={forecast.forecastDate + 'sln-totals-sum'}>
              <CellValue
                {...amountRules}
                dataTestId={slugifyStrings(title, forecast.forecastDate ?? '', 'total')}
                value={
                  capitalStructureData.years[forecast.forecastDate ?? '']?.summary[type]
                    ?.closingBalance ?? 0
                }
                strong
              />
            </td>
          ))}
          <td />
        </tr>
      </>
    );
  };

  const showSlnSection = slnInstruments.length > 0;
  const showPrefSharesSection = prefSharesInstruments.length > 0;

  return (
    <>
      <tr>
        <th
          className="table-primary__cell--header table-primary__cell--header-primary"
          colSpan={sectionColCount}
          data-testid="summary-shareholder-debt">
          {l('_SummaryShareholderDebt')}
        </th>
      </tr>
      {showPrefSharesSection &&
        renderSummarySection(
          debtInstrumentResults,
          enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
          historicalSummaries[InstrumentType.PreferredShares],
          l('_PreferenceShares')
        )}
      {showSlnSection &&
        renderSummarySection(
          debtInstrumentResults,
          enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes),
          historicalSummaries[InstrumentType.ShareholderLoanNotes],
          l('_ShareholderLoanNotes')
        )}
    </>
  );
};
