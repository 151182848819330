import { useAppSelector } from '@app/core/hooks/redux-hooks';
import styles from './compatibility-level-upgrade.module.scss';
import Button from '../button/Button';
import { ButtonAppearance, ButtonSize } from '../button/button-enums';
import {
  selectCurrentProjectCompatibilityLevel,
  selectLatestProjectCompatibilityLevel,
} from '@app/core/store/ui-values-slice-selectors';
import { FC } from 'react';
import { useLocale } from '@app/core/hooks/useLocale';
import { Tooltip } from '../tooltip/Tooltip';
import SvgInformation from '@app/shared/icons/Information';

const PARENT_CLASSNAME = 'compatibility-level-upgrade';

interface CompatibilityLevelUpgradeNoticeProps {
  setShowModal: (showModal: boolean) => void;
}

export const CompatibilityLevelUpgradeNotice: FC<CompatibilityLevelUpgradeNoticeProps> = ({
  setShowModal,
}) => {
  const { l } = useLocale();
  const projectLevel = useAppSelector(selectCurrentProjectCompatibilityLevel);
  const latestLevel = useAppSelector(selectLatestProjectCompatibilityLevel);

  return (
    <>
      <div className={styles[`${PARENT_CLASSNAME}__upgrade-container`]}>
        <Tooltip
          content={l('_ProjectLevelsTooltip', {
            currentLevel: projectLevel?.longLabel,
            latestLevel: latestLevel?.shortLabel,
          })}>
          <Button
            appearance={ButtonAppearance.CLEAN}
            size={ButtonSize.FLUID}
            aria-label={l('_MoreInformation')}>
            <SvgInformation className={styles[`${PARENT_CLASSNAME}__upgrade-tooltip`]} />
          </Button>
        </Tooltip>
        <span className={styles[`${PARENT_CLASSNAME}__upgrade-label`]}>
          {l('_ProjectInLevel', { level: projectLevel?.longLabel })}
        </span>
        <Button
          onClick={() => setShowModal(true)}
          appearance={ButtonAppearance.DEFAULT_SECONDARY}
          size={ButtonSize.SMALL}>
          {l('_Upgrade')}
        </Button>
      </div>
    </>
  );
};
