import styles from '@app/modules/projects/outputs/outputs-table.module.scss';
import { FC, Fragment } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  monthDayCellViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import classNames from 'classnames';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import { useLocale } from '@app/core/hooks/useLocale';
import { getInstrumentsCaseYear } from '@app/core/store/pwerm2-calculation-slice-selectors';
import { useParams } from 'react-router-dom';

export const InstrumentLevelDataHeader: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { instrumentId: currentInstrumentId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const sortedProjectByCase = sortedProjectByCases(project);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);

  return (
    <>
      <colgroup>
        <col className={styles['labels-col']} />
        {sortedProjectByCase.pwermInput.cases.map((caseItem, primaryIndex) => (
          <Fragment key={'colspan-layer' + caseItem.caseId}>
            {caseItem?.forecasts.map((forecast, index) => (
              <Fragment
                key={'colspan' + caseItem.caseId + forecast.forecastId + index + primaryIndex}>
                <col />
              </Fragment>
            ))}
          </Fragment>
        ))}
      </colgroup>
      <thead className="table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
        <tr>
          <th className="table-primary__cell--vertical-separator" />
          {sortedProjectByCase.pwermInput.cases.map((caseItem, index) => (
            <Fragment key={'narrative-values' + index + caseItem.caseId}>
              <th className="table-primary__cell--vertical-separator" />
              <th
                colSpan={caseItem.forecasts.length}
                className="table-primary__cell--header-tertiary table-primary__cell--center table-primary__cell--vertical-separator">
                <span className={styles['case-name']}>{caseItem.narrative}</span>
              </th>
            </Fragment>
          ))}
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">{l('_ExitDate')}</th>
          {sortedProjectByCase.pwermInput.cases.map((caseItem) => (
            <Fragment key={'exit-date' + caseItem.caseId}>
              {caseItem?.forecasts.map((forecast, index) => (
                <Fragment
                  key={'forecast-exit-date' + caseItem.caseId + forecast.forecastId + index}>
                  {index === 0 && (
                    <td className="table-primary__cell--vertical-separator">
                      <CellValue
                        value={project.valuationDate}
                        {...monthDayCellViewRules}
                        theme={CellValueTheme.Tertiary}
                      />
                    </td>
                  )}
                  <td
                    className={classNames({
                      'table-primary__cell--vertical-separator':
                        index === caseItem.forecasts.length - 1,
                    })}>
                    <CellValue value={forecast.forecastYear} {...monthDayCellViewRules} />
                  </td>
                </Fragment>
              ))}
            </Fragment>
          ))}
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">{l('_TimeToExit')}</th>
          {sortedProjectByCase.pwermInput.cases.map((caseItem, caseIndex) => (
            <Fragment key={'time-exit-items' + caseIndex}>
              {caseItem?.forecasts.map((forecast, index) => (
                <Fragment
                  key={'time-to-exit' + caseItem.caseId + forecast.forecastId + index + caseIndex}>
                  {index === 0 && <td className="table-primary__cell--vertical-separator" />}
                  <td
                    className={classNames({
                      'table-primary__cell--vertical-separator':
                        index === caseItem.forecasts.length - 1,
                    })}>
                    <CellValue
                      value={getInstrumentsCaseYear(
                        results,
                        currentInstrumentId!,
                        caseItem.caseId,
                        forecast.forecastId,
                        (year) => year.timeToExit
                      )}
                      {...numberCalculatedViewRules}
                      normalize
                    />
                  </td>
                </Fragment>
              ))}
            </Fragment>
          ))}
        </tr>
      </thead>
    </>
  );
};
