export enum InstrumentType {
  ShareholderLoanNotes = 'Shareholder Loan Notes',
  PreferredShares = 'Preference Shares',
  OrdinaryEquity = 'Ordinary Equity',
}

export enum OwnerType {
  Institution = 'Sponsor',
  Management = 'Management',
  CoInvestor = 'Co-Investor',
  Other = 'Other',
}

export enum OwnershipSplitType {
  ProRata = 'ProRata',
  Manual = 'Manual',
}

export enum PredefinedVariableNames {
  ProbabilityOfSecondaryTradeSale = 'Probability of Secondary/Trade sale',
  TransactionCostsOfEV = 'Transaction costs (% of EV)',
  IPODiscountOfEV = 'IPO discount (% of EV)',
  ImpliedEquityRetainedOnIPO = 'Implied equity retained on IPO',
  ProbabilityWeightedDLOMForPostIPOSaleRestrictions = 'Probability weighted DLOM for post IPO sale restrictions',
}

export enum PayoutValue {
  Shareholding = '% Shareholding',
  Custom = '% Custom',
}

export enum PayoutDriver {
  UpAndIn = 'Up and In',
  Incremental = 'Incremental',
}

export enum ConjunctionType {
  And = 'And',
  Or = 'Or',
}

export enum Operator {
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqual = '>=',
  LessThanOrEqual = '<=',
}

export enum TargetMetric {
  TotalOrdinaryEquity = 'Total Ordinary Equity',
  InstitutionalIRR = 'Sponsor IRR',
  InstitutionalMoM = 'Sponsor MoM',
}

export enum Else {
  Zero = '0',
  Shareholding = '% Shareholding',
}

export enum Currency {
  PoundSterling = '£',
  UsDollar = '$',
  Euro = '€',
  SwissFranc = 'CHF',
}

export enum Denomination {
  Units = 'units',
  Thousands = 'k',
  Millions = 'm',
  Billions = 'bn',
}

export enum MipEvent {
  DealEntry = 'Deal Entry',
  MipReset = 'MIP Reset',
  Joiner = 'Joiner',
  Leaver = 'Leaver',
  NonDealMip = 'Non-Deal MIP',
}
export enum CalcMethod {
  PWERM = 'PWERM',
  OPM = 'OPM',
  PWERM_AND_OPM = 'PWERM & OPM',
}

export enum CollaringMode {
  Off = 'Off',
  Percentage = 'Percentage',
  FixedValue = 'FixedValue',
}

export enum AdjustmentMode {
  Relative = 'Relative',
  Absolute = 'Absolute',
}

export enum Sector {
  EnergyUtilitiesResourcesInfrastructure = 'Energy, Utilities, Resources & Infrastructure',
  FinancialServices = 'Financial Services',
  HealthcarePharma = 'Healthcare & Pharma',
  IndustrialProducts = 'Industrial Products',
  RealEstate = 'Real Estate',
  RetailConsumerLeisure = 'Retail, Consumer & Leisure',
  TechnologyMediaTelecommunications = 'Technology, Media & Telecommunications',
}

export enum SubSector {
  Energy = 'Energy',
  Utilities = 'Utilities',
  Resources = 'Resources',
  CapitalProjectsInfrastructure = 'Capital Projects & Infrastructure',
  Insurance = 'Insurance',
  AssetWealthManagement = 'Asset & Wealth Management',
  BankingCapitalMarkets = 'Banking & Capital Markets',
  PharmaceuticalsLifeSciences = 'Pharmaceuticals & Life Sciences',
  GovernmentPublicServices = 'Government & Public Services',
  AerospaceDefence = 'Aerospace & Defence',
  Automotive = 'Automotive',
  EngineeringConstruction = 'Engineering & Construction',
  ForestPaperPackaging = 'Forest, Paper & Packaging',
  IndustrialManufacturing = 'Industrial Manufacturing',
  TransportationLogistics = 'Transportation & Logistics',
  RealEstate = 'Real Estate',
  HospitalityLeisure = 'Hospitality & Leisure',
  ConsumerMarkets = 'Consumer Markets',
  Media = 'Media',
  Technology = 'Technology',
  Telecommunications = 'Telecommunications',
}

export enum RegionOfOperations {
  UnitedKingdom = 'United Kingdom',
  UnitedStates = 'United States of America',
  European = 'European',
  Nordics = 'Nordics',
  Asia = 'Asia',
  Australasia = 'Australasia',
  Africa = 'Africa',
  SouthAmerica = 'South America',
  NorthAmerica = 'North America',
  SouthEastAsia = 'South-East Asia',
  Global = 'Global',
}

export enum NetworkRolloutRegions {
  UnitedKingdom = 'United Kingdom',
  Ireland = 'Ireland',
}

export enum NetworkRolloutRegionsCode {
  UnitedKingdom = 'UK',
  Ireland = 'IE',
}

export enum Country {
  Argentina = 'Argentina',
  Australia = 'Australia',
  Austria = 'Austria',
  Belgium = 'Belgium',
  Brazil = 'Brazil',
  Bulgaria = 'Bulgaria',
  Canada = 'Canada',
  China = 'China',
  Croatia = 'Croatia',
  Denmark = 'Denmark',
  Egypt = 'Egypt',
  Estonia = 'Estonia',
  Finland = 'Finland',
  France = 'France',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Greece = 'Greece',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Ireland = 'Ireland',
  Italy = 'Italy',
  Japan = 'Japan',
  Latvia = 'Latvia',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Mexico = 'Mexico',
  Netherlands = 'Netherlands',
  NewZealand = 'New Zealand',
  Norway = 'Norway',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Qatar = 'Qatar',
  Romania = 'Romania',
  Russia = 'Russia',
  Singapore = 'Singapore',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SouthAfrica = 'South Africa',
  Spain = 'Spain',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Turkey = 'Turkey',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United Arab Emirates',
  UnitedKingdom = 'United Kingdom',
  UnitedStatesOfAmerica = 'United States of America',
  Other = 'Other',
}

export enum ProjectStatus {
  Draft = 'Draft',
  Finalised = 'Finalised',
  Closed = 'Closed',
}

export enum DealEvent {
  Primary = '_Primary',
  Secondary = '_Secondary',
  PublicToPrivate = '_PublicToPrivate',
  MergerBuyAndBuild = '_MergerBuyAndBuild',
  CorporateAndStrategic = '_CorporateAndStrategic',
  RefinancingCashExtraction = '_RefinancingCashExtraction',
  FundToFundTransfer = '_FundToFundTransfer',
  Other = '_Other',
}

export enum InvestmentMetric {
  Sales = '_Sales',
  EBITDA = '_EBITDA',
  ProfitAfterTax = '_ProfitAfterTax',
  BookValue = '_BookValue',
  AssetsUnderManagement = '_AssetsUnderManagement',
}

export interface DealOverviewDto {
  dealEvent: Nullable<keyof typeof DealEvent>;
}

export enum InvestmentHorizon {
  LessThanTwo = '<2',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  MoreThanFive = '>5',
  IndefiniteHold = '_IndefiniteHold',
}

export enum MIPDesignOptions {
  InHouseStyle = '_InHouseStyle',
  StandardPEStructure = '_StandardPEStructure',
  Other = '_Other',
}

export enum YesNoOptions {
  Yes = '_Yes',
  No = '_No',
}

export enum YesNoUnknownOptions {
  Yes = '_Yes',
  No = '_No',
  Unknown = '_Unknown',
}

export enum SponsorInvestmentStructure {
  OrdinaryEquityOnly = '_OrdinaryEquityOnly',
  OrdinaryEquityAndPreferredInstruments = '_OrdinaryEquityAndPreferredInstruments',
  OrdinaryEquityAndShareholderLoans = '_OrdinaryEquityAndShareholderLoans',
  OrdinaryEquityPreferredInstrumentsAndShareholderLoans = '_OrdinaryEquityPreferredInstrumentsAndShareholderLoans',
}

export enum NumberOfParticipantsInTheMIP {
  ZeroToTen = '0 - 10',
  ElevenToTwenty = '11 - 20',
  TwentyOneToFifty = '21 - 50',
  FiftyOneToHundred = '51 - 100',
  MoreThanHundred = '>100',
}

export enum NatureOfSweetEquityParticipationReturn {
  OrdinaryEquityOnly = '_OrdinaryEquityOnly',
  OrdinaryEquityPlusRatchets = '_OrdinaryEquityPlusRatchets',
  RatchetSharesOnly = '_RatchetSharesOnly',
  GrowthSharesOnly = '_GrowthSharesOnly',
  ShareOptions = '_ShareOptions',
  ProfitsInterest = '_ProfitsInterest',
}

export enum BasisOfHurdle {
  IRROnly = '_IRROnly',
  MMOnly = '_MMOnly',
  IRRAndMM = '_IRRAndMM',
  AbsoluteValue = '_AbsoluteValue',
  Other = '_Other',
  NoHurdle = '_NoHurdle',
}

export enum StructureOfRatchetMechanism {
  IRROnly = '_IRROnly',
  MMOnly = '_MMOnly',
  IRRAndMM = '_IRRAndMM',
  AbsoluteValue = '_AbsoluteValue',
  Other = '_Other',
}

export enum VestingCondition {
  TimeBased = '_TimeBased',
  PerformanceBased = '_PerformanceBased',
  TimeAndPerformanceBased = '_BothTimeAndPerformanceBased',
  NA = '_NA',
}

export enum ESGTermsInherentOrVestingRelated {
  Inherent = '_Inherent',
  Vesting = '_Vesting',
  NA = '_NA',
}

export enum ThirdPartyTaxValuationUndertakenOnEntry {
  YesOnEntryOnly = '_YesOnEntryOnly',
  YesOnEntryAndPeriodicJoinerEvents = '_YesOnEntryAndPeriodicJoinerEvents',
  No = '_No',
  Unknown = '_Unknown',
}

export enum TaxValuationMethodologyAdopted {
  ExpectedReturnsApproach = '_ExpectedReturnsApproach',
  OptionPricingMethodology = '_OptionPricingMethodology',
  ExpectedReturnsAndOptionPricingMethodology = '_ExpectedReturnsAndOptionPricingMethodology',
  AssumedMoUCompliant = '_AssumedMoUCompliantUK',
  ProfitInterest = '_ProfitInterestUS',
  Other = '_Other',
}

export enum Role {
  Administrator = 'Administrator',
  TeamMember = 'Team Member',
  RegionAdministrator = 'Region Administrator',
}

export enum AllowedEmailDomains {
  PwC = '@pwc.com',
}

export enum MultipleType {
  Ltm = 'LTM',
  ForecastYearPlus1 = 'FY + 1',
  ForecastYearPlus2 = 'FY + 2',
}

export enum WaterfallSections {
  ExitMultiples = 'Exit Multiples',
  EnterpriseValueEV = 'Enterprise Value [EV]',
  NetExitProceeds = 'Net Exit Proceeds',
  TotalEquityProceeds = 'Total Equity Proceeds',
  ShareholderDebtDistributions = 'Shareholder Debt Distributions',
  OrdinaryEquity = 'Ordinary Equity',
  Valuation = 'Valuation',
  SponsorReturn = 'Sponsor Return',
  TotalEquityDCFCrossCheck = 'Total Equity DCF Cross Check',
}

export enum WaterfallSectionsErf {
  ExitMultiples = 'Exit Multiples',
  EnterpriseValueEV = 'Enterprise Value [EV]',
  NetExitProceeds = 'Net Exit Proceeds',
  TotalEquityProceeds = 'Total Equity Proceeds',
  ShareholderDebtDistributions = 'Shareholder Debt Distributions',
  OrdinaryEquity = 'Ordinary Equity',
  OtherCashflowMovement = 'Other Cashflow Movement',
  Valuation = 'Valuation',
  SponsorReturn = 'Sponsor Return',
  TotalEquityDCFCrossCheck = 'Total Equity DCF Cross Check',
}

export enum OpmSimulatedValue {
  EnterpriseValue = 'Enterprise Value',
  Equity = 'Equity',
}

export enum OpmInputDataSource {
  FromPWERM = 'FromPWERM',
  FromCapitalStructure = 'FromCapitalStructure',
  Override = 'Override',
}

export enum DenominationMultiplier {
  'Units' = 1,
  'Thousands' = 1e3,
  'Millions' = 1e6,
  'Billions' = 1e9,
}

export enum EquityRefinancingMovementType {
  NewInstrument = 'NewInstrument',
  FullyRedeemInstrument = 'FullyRedeemInstrument',
  ValueAdjustment = 'ValueAdjustment',
}

export enum CapitalStructureEventSeverity {
  Error = 'Error',
  Warning = 'Warning',
  Information = 'Information',
}

export enum EventChangedType {
  None = 0,
  InitialEventChanges = 1,
  InstrumentAdded = 2,
  AmountAmended = 4,
  SharesAmended = 8,
  FullyRedeemed = 16,
  DividendToggled = 32,
  CouponUpdated = 64,
}

export enum CouponType {
  CompoundInterest = 'Compound Interest',
  SimpleInterest = 'Simple Interest',
}

export enum InterestPaymentType {
  CashPay = 'Cash Pay',
  PaymentInKind = 'Payment in Kind',
  PaymentInKindAndCashPay = 'Payment in Kind and Cash Pay',
}

export enum ContainerTestId {
  Main = 'main',
}

export enum ValidationJobType {
  OpmCalculation = 'OpmCalculation',
  OpmExport = 'OpmExport',
  Save = 'Save',
  ClientScheduleExport = 'ClientScheduleExport',
  PwermCalculation = 'PwermCalculation',
  PwermInputsCalculation = 'PwermInputsCalculation',
  BenchmarkingInputsCalculation = 'BenchmarkingInputsCalculation',
  CapitalStructureCalculation = 'CapitalStructureCalculation',
  BuildStructureCalculation = 'BuildStructureCalculation',
  ExitScenarioCalculation = 'ExitScenarioCalculation',
}

export enum EventKey {
  EmptyEventSet = '00000000-0000-0000-0000-000000000000',
  InitialCapitalStructure = '1001c28f-0157-4466-9509-c2b8ffc27035',
  OpmOnly = 'a1b2c3d4-e5f6-7890-abcd-ef1234567890',
}

export enum TrancheKey {
  DefaultTrancheKey = '00000000-0000-0000-0000-000000000000',
}

export enum InstrumentRank {
  OrdinaryEquity = 9999,
}

export enum StructureDimension {
  Height = 380,
  Width = 362,
  MinHeight = 28,
  MinWidth = 24,
  ErfFixedHeightElements = 125,
  ErfAdditionalHeightElements = 6,
  NonErfFixedHeightElements = 35,
  ModalHeight = 550,
  ModalWidth = 750,
  ModalWithoutFormWidth = 1250,
}

export enum TooltipWidth {
  EventFault = 300,
  OrdinaryEquityDealThesisSection = 330,
  PwermWaterfallValuationsSection = 340,
}

export enum ReduceYearsType {
  Forecast = 'forecast',
  Historic = 'historic',
}

export enum BuildStructureErrorCodes {
  CSB_MISC_ERROR = 5000,
  CSB_TOO_MANY_EVENTSETS = 5001,
  CSB_TOO_MANY_EVENTS = 5002,
  CSB_EVENTSET_NOT_FOUND = 5003,
  CSB_NO_STRUCTURE_FOR_DATE = 5004,
  CSB_DEF_INSTRUMENT_NARRATIVE_NOT_UNIQUE = 5020,
  CSB_DEF_ORDINARY_RANK_ON_NON_ORDINARY = 5021,
  CSB_DEF_RANK_OUT_OF_RANGE = 5022,
  CSB_DEF_VALUED_SET_ON_INSTRUMENT_NOT_PRESENT_AT_INVESTMENT = 5023,
  CSB_DEF_TOO_MANY_INSTRUMENTS = 5024,
  CSB_DEF_TOO_MANY_TRANCHES = 5025,
  CSB_DEF_INSTRUMENT_NARRATIVE_TOO_LONG = 5026,
  CSB_INITIAL_INVESTED_MISSING_OR_INVALID = 5040,
  CSB_INITIAL_SHARES_MISSING_OR_INVALID = 5041,
  CSB_INITIAL_REFERENCE_NON_EXISTENT_DEF = 5042,
  CSB_INITIAL_INVESTED_AND_SHARES_VALUES_NOT_PROVIDED = 5043,
  CSB_INITIAL_COUPON_MISSING = 5044,
  CSB_INITIAL_COUPON_VALUE_INVALID = 5045,
  CSB_INITIAL_COUPON_DATE_PREDATES_INVESTMENT = 5046,
  CSB_INITIAL_COUPON_CASHPAY_INVALID = 5047,
  CSB_EVENTSET_REFERENCE_NON_EXISTENT_EVENT = 5060,
  CSB_EVENTSET_HAS_NO_EVENTS = 5061,
  CSB_EVENTSET_CONTAINS_DUPLICATE_EVENT_DATES = 5062,
  CSB_EVENTSET_TOO_MANY_EVENTS = 5063,
  CSB_EVENTSET_DUPLICATE_EVENT_REFERENCES = 5064,
  CSB_EVENT_INSTRUMENT_NO_MOVEMENT = 5080,
  CSB_EVENT_NO_INSTRUMENTS = 5081,
  CSB_EVENT_REFERENCE_NON_EXISTENT_INSTRUMENT_DEF = 5082,
  CSB_EVENT_DATE_PREDATES_INVESTMENT = 5083,
  CSB_EVENT_NARRATIVE_NOT_UNIQUE = 5084,
  CSB_EVENT_NARRATIVE_TOO_LONG = 5085,
  CSB_EVENT_LEAVES_NO_ORDINARY_IN_STRUCTURE = 5086,
  CSB_EVENT_LEAVES_NO_INSTRUMENTS_IN_STRUCTURE = 5087,
  CSB_EVENT_DATE_TOO_FAR_BEFORE_VALUATION_DATE = 5088,
  CSB_MOVEMENT_NEW_INSTRUMENT_ALREADY_EXISTS = 5100,
  CSB_MOVEMENT_ON_ZERO_VALUE_INSTRUMENT = 5101,
  CSB_MOVEMENT_VALUE_REDEMPTION_RESULTS_NEGATIVE_VALUE = 5102,
  CSB_MOVEMENT_QUANTITY_REDEMPTION_RESULTS_NEGATIVE_SHARES = 5103,
  CSB_MOVEMENT_COUPON_UPDATE_ON_INSTRUMENT_NOT_PRESENT = 5104,
  CSB_MOVEMENT_UPDATE_ON_NON_EXISTENT_INSTRUMENT_IMPLICIT_ADD = 5105,
  CSB_MOVEMENT_HAS_NO_EFFECT = 5106,
  CSB_MOVEMENT_COUPON_UPDATE_ON_NON_COUPONABLE_INSTRUMENT = 5107,
  CSB_MOVEMENT_CAUSES_OVERFLOW = 5108,
  CSB_MOVEMENT_COUPON_DATE_PREDATES_PREVIOUS = 5110,
  CSB_MOVEMENT_SPECIFIES_BOTH_ADJUSTMENT_AND_ABSOLUTE_VALUES = 5111,
  CSB_MOVEMENT_ADJUSTMENT_CAUSES_FULL_REDEMPTION = 5112,
  CSB_MOVEMENT_NEW_INSTRUMENT_INITIAL_VALUE_NOT_PROVIDED = 5113,
  CSB_MOVEMENT_NEW_INSTRUMENT_INITIAL_SHARES_NOT_PROVIDED = 5114,
  CSB_MOVEMENT_VALUE_NOT_PROVIDED_FOR_OWNER = 5115,
  CSB_MOVEMENT_ADJUSTMENT_CAUSES_INCOMPLETE_REDEMPTION = 5116,
  CSB_MOVEMENT_COUPON_VALUE_INVALID = 5117,
  CSB_MOVEMENT_COUPON_CASHPAY_INVALID = 5118,
  CSB_MOVEMENT_COUPON_DATE_PREDATES_INVESTMENT = 5119,
}

export enum ViewChangeType {
  Next = 'Next',
  Previous = 'Previous',
}

export enum ProjectCompatibilityLevel {
  Version_3_9 = 1,
  Version_4_0 = 2,
}

export enum InstrumentBlockKey {
  NetDebtId = '0b756944-9820-4e7d-a57a-7578b8882a94',
}

export enum DealThesisHeaderTypes {
  HistoricalYearsWithoutNetDebt = 'HistoricalYearsWithoutNetDebt',
  HistoricalYearsWithNetDebt = 'HistoricalYearsWithNetDebt',
  HistoricalEvents = 'HistoricalEvents',
}

export enum OrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ClientScheduleExportType {
  PWERM = 0,
  OPM = 1,
  PWERM_AND_OPM = 2,
}
