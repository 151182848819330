import { Role } from '@app/shared/models/contracts/enums/shared-enums';
import { UserInfoDto } from '@app/shared/models/contracts/userinfo-dto';

export const featureAvailability = (
  grantedRoles: (keyof typeof Role | string)[],
  userInfo: UserInfoDto,
  hasAccess?: boolean
): boolean => {
  return typeof hasAccess !== 'undefined'
    ? grantedRoles.includes(userInfo.role) && hasAccess
    : grantedRoles.includes(userInfo.role);
};

export const featureAvailabilityWithRegions = (
  grantedRoles: (keyof typeof Role | string)[],
  userInfo: UserInfoDto
): boolean => {
  return (
    grantedRoles.includes(userInfo.role) &&
    userInfo.regions !== undefined &&
    userInfo.regions.length > 0
  );
};
