import Button from '@app/shared/components/button/Button';
import Plus from '@app/shared/icons/Plus';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { useState } from 'react';
import NewEventFormModal from './NewEventFormModal';
import {
  CalcMethod,
  EventKey,
  ProjectCompatibilityLevel,
} from '@app/shared/models/contracts/enums/shared-enums';
import { useLocale } from '@app/core/hooks/useLocale';
import { selectCapitalStructure } from '@app/core/store/capital-structure-slice-selectors';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import styles from '../../build-structure-tab/build-structure.module.scss';

export const AddEvent = () => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const maxEventsCount = useAppSelector((state) => state.uiValues.maxRefinancingEventsPerCalc) ?? 5;
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const capitalStructureKey = project.pwermInput.cases[0].capitalStructureId;
  const projectWithInstruments =
    buildStructures &&
    buildStructures[capitalStructureKey]?.eventSets[EventKey.EmptyEventSet]?.events[0]?.ranks
      .length > 0;
  const existingEvents = isOpmOnly
    ? Object.fromEntries(
        capitalStructure.eventSets[EventKey.OpmOnly]?.events.map((eventId) => [
          eventId,
          capitalStructure.events[eventId],
        ]) ?? []
      )
    : Object.fromEntries(
        Object.entries(capitalStructure.events).filter(
          ([eventId, _]) => !capitalStructure.eventSets[EventKey.OpmOnly]?.events.includes(eventId)
        )
      );

  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);

  const shouldButtonBeDisabled =
    !projectWithInstruments ||
    Object.keys(existingEvents).length === maxEventsCount ||
    project.compatibilityLevel < ProjectCompatibilityLevel.Version_4_0;

  const tooltipMessages = [];

  if (!projectWithInstruments) {
    tooltipMessages.push(l('_ProjectContainsNoInstruments'));
  }
  if (Object.keys(existingEvents).length === maxEventsCount) {
    tooltipMessages.push(l('_NumberOfEventsIsLimitedToMaxEventsCount', { maxEventsCount }));
  }
  if (project.compatibilityLevel < ProjectCompatibilityLevel.Version_4_0) {
    tooltipMessages.push(l('_ProjectNeedsToBeUpgradedToTheCurrentCompatibilityLevel'));
  }

  const tooltipContent =
    tooltipMessages.length > 0 ? (
      <ul className={styles['add-event-tooltip-list']}>
        {tooltipMessages.map((message, index) => (
          <li key={index} className={styles['add-event-tooltip-item']}>
            {message}
          </li>
        ))}
      </ul>
    ) : undefined;

  return (
    <Tooltip
      content={shouldButtonBeDisabled ? tooltipContent : undefined}
      useFixedPositioning
      shouldTooltipRender={shouldButtonBeDisabled}
      placement={TooltipPlacement.Left}>
      <div>
        <Button
          appearance={ButtonAppearance.DEFAULT_PRIMARY}
          startIcon={<Plus />}
          onClick={() => setIsCreateEventModalOpen(true)}
          disabled={shouldButtonBeDisabled}>
          {l('_AddEvent')}
        </Button>
        <NewEventFormModal setIsOpen={setIsCreateEventModalOpen} isOpen={isCreateEventModalOpen} />
      </div>
    </Tooltip>
  );
};
