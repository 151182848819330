import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { selectCapitalStructure } from '@app/core/store/capital-structure-slice-selectors';
import {
  getRequiredValidator,
  greaterDateThanValidator,
  greaterOrEqualDateThanValidator,
  maxLengthValidator,
  uniqueEventNarrative,
} from '@app/core/validations/hook-forms/validators';
import FormCheckbox from '@app/shared/components/form-controls/form-checkbox/FormCheckbox';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { CalcMethod, EventKey } from '@app/shared/models/contracts/enums/shared-enums';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const NewEventFormElements: FC = (): JSX.Element => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const maxEventNameLength = useAppSelector(
    (state) => state.uiValues.maxRefinancingEventNameLength
  );
  const maxYearsBetweenEventsAndValuation = useAppSelector(
    (state) => state.uiValues.maxYearsBetweenEventsAndValuation
  );
  const options = project.pwermInput.cases.map((cases) => ({
    viewValue: cases.narrative,
    value: cases.caseId,
  }));
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;
  const existingEvents = isOpmOnly
    ? Object.fromEntries(
        capitalStructure.eventSets[EventKey.OpmOnly]?.events.map((eventId) => [
          eventId,
          capitalStructure.events[eventId],
        ]) ?? []
      )
    : Object.fromEntries(
        Object.entries(capitalStructure.events).filter(
          ([eventId, _]) => !capitalStructure.eventSets[EventKey.OpmOnly]?.events.includes(eventId)
        )
      );
  const earliestAllowedEventDate = new Date(project.valuationDate).addYears(
    -maxYearsBetweenEventsAndValuation
  );
  const valuationDate = new Date(project.valuationDate);
  const { watch, setValue } = useFormContext();
  const eventDate = watch('eventDate');
  const isEventDateBeforeValuationDate = eventDate && new Date(eventDate) < valuationDate;

  useEffect(() => {
    if (isEventDateBeforeValuationDate) {
      setValue(
        'selectedCases',
        project.pwermInput.cases.map((cases) => cases.caseId)
      );
    }
  }, [isEventDateBeforeValuationDate, setValue, project.pwermInput.cases]);

  return (
    <>
      <FormField
        name="narrative"
        label={l('_EventNarrative')}
        placeholder={l('_Narrative')}
        {...textFieldFormattingProps}
        rules={{
          ...getRequiredValidator(),
          ...uniqueEventNarrative(existingEvents),
          ...maxLengthValidator(maxEventNameLength),
        }}
      />
      <FormDatePicker
        data-testid="eventDate"
        name="eventDate"
        label={l('_EventDate')}
        rules={{
          ...getRequiredValidator(),
          validate: {
            greaterDateThanInvestmentDate: greaterDateThanValidator(
              project.investmentDate,
              l('_EventDateMustBeGreaterThanInvestmentDate')
            ).validate,
            notTooFarBeforeValuationDate: greaterOrEqualDateThanValidator(
              earliestAllowedEventDate.toISOString(),
              l('_EventDateCannotBeMoreThanXYearsBeforeValuationDate', {
                maxYearsBetweenEventsAndValuation: maxYearsBetweenEventsAndValuation,
              })
            ).validate,
          },
        }}
      />
      {!isOpmOnly && (
        <FormCheckbox
          name="selectedCases"
          label={isEventDateBeforeValuationDate ? l('_AllCasesSelected') : l('_SelectCases')}
          rules={{
            ...(!isEventDateBeforeValuationDate && getRequiredValidator()),
          }}
          options={options}
          disabled={isEventDateBeforeValuationDate}
        />
      )}
    </>
  );
};

export default NewEventFormElements;
