import { useAppSelector } from '@app/core/hooks/redux-hooks';
import Button from '../button/Button';
import { ButtonAppearance } from '../button/button-enums';
import {
  selectCurrentProjectCompatibilityLevel,
  selectUpgradePathCompatibilityLevels,
} from '@app/core/store/ui-values-slice-selectors';
import styles from './compatibility-level-upgrade.module.scss';
import { useLocale } from '@app/core/hooks/useLocale';
import { FC } from 'react';

const PARENT_CLASSNAME = 'compatibility-level-upgrade';

interface UpgradeDetailsModalContentProps {
  triggerUpgrade: () => void;
  setShowModal: (showModal: boolean) => void;
}

export const UpgradeDetailsModalContent: FC<UpgradeDetailsModalContentProps> = ({
  triggerUpgrade,
  setShowModal,
}) => {
  const { l } = useLocale();
  const upgrades = useAppSelector(selectUpgradePathCompatibilityLevels);
  const upgradeChanges = upgrades.flatMap((upgrade) => upgrade.changes);
  const resultsInvalidated = upgrades.some((upgrade) => upgrade.opmResultsInvalidated);
  const projectLevel = useAppSelector(selectCurrentProjectCompatibilityLevel);

  return (
    <>
      <p>
        <strong>{l('_CurrentProjectCompatibilityLevel')}</strong> {projectLevel?.longLabel}.
      </p>
      <br />
      <p>{l('_IfYouChooseToUpgradeThisProjectTheFollowingChangesWillApply')}</p>
      <ul>
        {upgradeChanges.map((change, index) => (
          <li key={index}>{change}</li>
        ))}
      </ul>
      {resultsInvalidated && (
        <>
          <p>
            <strong>{l('_Important')}</strong>
          </p>
          <ul>
            <li>{l('_UpgradeProjectRemovesExistingResults')}</li>
            <li>{l('_UpgradeProjectRequiresReRunOpmCalc')}</li>
          </ul>
        </>
      )}
      <div className={styles[`${PARENT_CLASSNAME}__actions`]}>
        <Button
          appearance={ButtonAppearance.DEFAULT_SECONDARY}
          onClick={() => triggerUpgrade()}
          data-testid="upgrade-button">
          {l('_Upgrade')}
        </Button>
        <Button
          appearance={ButtonAppearance.DEFAULT_PRIMARY}
          data-testid="cancel-button"
          onClick={() => {
            setShowModal(false);
          }}>
          {l('_Cancel')}
        </Button>
      </div>
    </>
  );
};
