import { FC, InputHTMLAttributes, useState } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import { FormControlPropsBase } from '../shared-types';
import styles from '../form-field.module.scss';
import Button from '../../button/Button';
import { ButtonAppearance } from '../../button/button-enums';
import SvgSuccessIcon from '@app/shared/icons/SuccessIcon';
import SvgIncorrectIcon from '@app/shared/icons/IncorrectIcon';
import SvgHideIcon from '@app/shared/icons/HideIcon';
import SvgShowIcon from '@app/shared/icons/ShowIcon';
import store from '@app/core/store/store';
import * as notificationActions from '@app/core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import { useLocale } from '@app/core/hooks/useLocale';
import SvgCopyIcon from '@app/shared/icons/CopyIcon';
import { Tooltip, TooltipPlacement } from '../../tooltip/Tooltip';
import { translationKeys } from '@locale/setupI18n';

export interface FormPasswordFieldProps
  extends FormControlPropsBase,
    InputHTMLAttributes<HTMLInputElement> {
  name: string;
  isValid?: boolean;
  minLength?: number;
  hasMinLength?: boolean;
  requiredCriteriaCount?: number;
  types: { regex: RegExp; isValid: boolean; name: translationKeys }[];
}

export const PARENT_CLASSNAME = 'form-field';
const FormPasswordField: FC<FormPasswordFieldProps> = ({
  name,
  isValid,
  minLength,
  hasMinLength,
  requiredCriteriaCount,
  types,
  ...rest
}): JSX.Element => {
  const { l } = useLocale();
  const {
    field: { value: fieldValue, onChange: fieldOnChange, ref },
  } = useController({ name });

  const [inputValue, setInputValue] = useState<string>(fieldValue || '');
  const [showPassword, setShowPassword] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(inputValue);
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.success,
          message: l('_PasswordCopied'),
          autoHide: true,
        })
      );
    } catch (err) {
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.error,
          message: l('_FailedToCopyText'),
          autoHide: true,
        })
      );
    }
  };

  const tooltipContent = (
    <>
      <div className={classNames(styles[`${PARENT_CLASSNAME}__password-tooltip-content`])}>
        <div>{l('_PasswordMustContain')}</div>
        <ul className={classNames(styles[`${PARENT_CLASSNAME}__password-tooltip-list`])}>
          <li>
            <div className={classNames(styles[`${PARENT_CLASSNAME}__password-tooltip-status`])}>
              {minLength && l('_MinimumOfNumberCharacters', { number: minLength })}
              {hasMinLength ? <SvgSuccessIcon /> : <SvgIncorrectIcon />}
            </div>
          </li>
        </ul>
        <div>
          {requiredCriteriaCount && l('_NumberFromTheFollowing', { number: requiredCriteriaCount })}
          <ul className={classNames(styles[`${PARENT_CLASSNAME}__password-tooltip-list`])}>
            {types.map((type) => (
              <li key={type.name}>
                <div className={classNames(styles[`${PARENT_CLASSNAME}__password-tooltip-status`])}>
                  {l(type.name)}
                  {type.isValid ? <SvgSuccessIcon /> : <SvgIncorrectIcon />}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );

  return (
    <div className={classNames(styles[PARENT_CLASSNAME])}>
      <div className={classNames(styles[`${PARENT_CLASSNAME}__password-container`])}>
        <div className={classNames(styles[`${PARENT_CLASSNAME}__password-input`])}>
          <input
            type={showPassword ? 'text' : 'password'}
            id={name}
            className={classNames(
              styles[`${PARENT_CLASSNAME}__control`],
              styles[`${PARENT_CLASSNAME}__password-text-field`]
            )}
            ref={ref}
            value={inputValue}
            onChange={(e) => {
              const value = e.target.value;
              setInputValue(value);
              fieldOnChange(value);
            }}
            {...rest}
          />
          <div className={styles[`${PARENT_CLASSNAME}__password-status-icon`]}>
            <Tooltip placement={TooltipPlacement.Right} content={tooltipContent}>
              {isValid ? <SvgSuccessIcon /> : <SvgIncorrectIcon />}
            </Tooltip>
          </div>
        </div>
        <div className={classNames(styles[`${PARENT_CLASSNAME}__password-controls`])}>
          <Button
            appearance={ButtonAppearance.CLEAN}
            onClick={() => setShowPassword(!showPassword)}
            className={classNames(styles[`${PARENT_CLASSNAME}__password-view-toggle`])}>
            {showPassword ? <SvgHideIcon /> : <SvgShowIcon />}
          </Button>
          {inputValue.length > 0 ? (
            <Button
              appearance={ButtonAppearance.CLEAN}
              onClick={() => handleCopy()}
              className={classNames(styles[`${PARENT_CLASSNAME}__password-copy`])}>
              <SvgCopyIcon />
            </Button>
          ) : (
            <Button
              appearance={ButtonAppearance.CLEAN}
              disabled
              className={classNames(styles[`${PARENT_CLASSNAME}__password-copy-disabled`])}>
              <SvgCopyIcon />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormPasswordField;
