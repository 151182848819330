import { PropsWithChildren } from 'react';
import Header from '@app/shared/layout/header/Header';
import styles from './main-layout.module.scss';
import PwcLink from '@app/shared/routing/PwcLink';
import { showDevFeaturesLink } from '@app/core/utils/dev-feature';
import { useAppSelector } from '@core/hooks/redux-hooks';
import MaintenanceBanner from '@app/modules/projects/maintenance/MaintenanceBanner';
import { useLocale } from '@app/core/hooks/useLocale';

const PARENT_CLASSNAME = 'main-layout';

export const MainLayout = ({ children }: PropsWithChildren<Record<never, never>>): JSX.Element => {
  const { l } = useLocale();
  const commitId = useAppSelector((state) => state.uiValues.commitId);
  const branchName = useAppSelector((state) => state.uiValues.branchName);
  const versionTag = useAppSelector((state) => state.uiValues.versionTag);

  return (
    <div className={styles[`${PARENT_CLASSNAME}`]}>
      <div className={styles[`${PARENT_CLASSNAME}__header-wrapper`]}>
        <MaintenanceBanner />
        <Header />
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__content-wrapper`]}>{children}</div>
      {showDevFeaturesLink() && (
        <div className={styles[`${PARENT_CLASSNAME}__dev-feature-link-container`]}>
          <PwcLink
            route="/dev-feature-setup"
            className={styles[`${PARENT_CLASSNAME}__dev-feature-link`]}>
            {l('_FeaturesUnderDevelopment')}
          </PwcLink>
          {!versionTag && commitId && branchName && (
            <div className={styles[`${PARENT_CLASSNAME}__commit-id`]}>
              {branchName} ({commitId})
            </div>
          )}
        </div>
      )}
    </div>
  );
};
