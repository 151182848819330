import { FC, useEffect, useMemo, useState } from 'react';
import {
  percentageValidator,
  getRequiredValidator,
  greaterThanValidator,
  greaterThanOrNullValidator,
} from '@app/core/validations/hook-forms/validators';
import { useFormContext } from 'react-hook-form';
import styles from './opm-inputs.module.scss';
import {
  numericBaseFieldFormattingProps,
  percentageFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { OpmInputDto, ProjectDto } from '@app/shared/models/contracts/project-dto';
import { SelectOption } from '@app/shared/models/option';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { GridSelectField } from '@app/shared/components/grid-controls/grid-select-field/GridSelectField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { TABLE_LABELS_COL_WIDTH_ALTERNATIVE } from '@app/shared/constants/table-config';
import { useLocale } from '@app/core/hooks/useLocale';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  CalcMethod,
  OpmInputDataSource,
  OpmSimulatedValue,
  ReduceYearsType,
} from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import { OpmInputFormModel } from './OpmInputs';
import SvgRevert from '@app/shared/icons/Revert';
import { useOpmInputsFromCapitalStructure } from './hooks/useOpmInputsFromCapitalStructure';
import { useOpmInputsFromPwerm } from './hooks/useOpmInputsFromPwerm';
import * as projectActions from '@core/store/project-slice';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { ReduceYearsModal } from '@app/shared/components/modal/reduce-years-modal/ReduceYearsModal';
import useTriggerEffect from '@app/core/hooks/useTriggerEffect';

interface OpmKeyInputsProps {
  submitData: () => void;
  formValues: OpmInputFormModel;
  notifyForecastYearsChangeInProgress: (changeInProgress: boolean) => void;
}

export const OpmKeyInputs: FC<OpmKeyInputsProps> = ({
  submitData,
  formValues,
  notifyForecastYearsChangeInProgress,
}) => {
  const project = useAppSelector((state) => state.project?.projectDraft);
  const dispatch = useAppDispatch();
  const [isForecastYearRemovalModalVisible, setIsForecastYearRemovalModalVisible] = useState(false);
  const formMethods = useFormContext<OpmInputFormModel>();
  const { l } = useLocale();
  const { getMaxForecastYearsFromPwerm } = useOpmInputsFromPwerm();
  const { getEvOrEquityValueFromCapitalStructure } = useOpmInputsFromCapitalStructure();
  const [countOfForecastYears, setCountOfForecastYears] = useState<number>(
    formValues.forecastYears
  );
  const { getValues, trigger } = formMethods;
  const opmInputs = project.opmInput;
  const isPwermAndOpm =
    project.details.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM);
  const isOpmOnly = project.details.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.OPM);

  const abbrevOpmValue =
    opmInputs.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue)
      ? 'EV'
      : OpmSimulatedValue.Equity;

  const forecastYearsOptions: SelectOption[] = [...Array(10).keys()].map((item) => ({
    viewValue: item + 1,
    value: item + 1,
  }));

  const caseSelectionOptions: SelectOption[] = project.pwermInput.cases.map((caseItem) => ({
    viewValue: caseItem.narrative,
    value: caseItem.caseId,
  }));

  // determine if the project is an ERF project
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const capitalStructureId = project.pwermInput.cases[0].capitalStructureId;
  const isErfBuildStructure = buildStructures?.[capitalStructureId].isErf;

  useEffect(() => {
    setCountOfForecastYears(formValues.forecastYears);
  }, [formValues.forecastYears]);

  useTriggerEffect(trigger);

  const maxForecastYearsFromPwerm = useMemo(
    () => getMaxForecastYearsFromPwerm(),
    [getMaxForecastYearsFromPwerm]
  );

  const handleForecastYearQuantityChange = (event?: React.FocusEvent) => {
    if (event) {
      // don't submit the data before we set the state that the modal is open (if applicable)
      event.stopPropagation();
    }
    const updatedForecastYears = getValues().forecastYears;
    notifyForecastYearsChangeInProgress(true);

    if (Number(countOfForecastYears) > Number(updatedForecastYears)) {
      setIsForecastYearRemovalModalVisible(true);
      return;
    } else if (Number(countOfForecastYears) < Number(updatedForecastYears)) {
      updateOpmForecastYears(updatedForecastYears);
    }

    notifyForecastYearsChangeInProgress(false);
  };

  const updateOpmForecastYears = async (updatedCount: number) => {
    const forecastYearsSource =
      Number(updatedCount) !== Number(maxForecastYearsFromPwerm)
        ? OpmInputDataSource.Override
        : OpmInputDataSource.FromPWERM;
    const updatedProject: ProjectDto = {
      ...project,
      opmInput: {
        ...project.opmInput,
        forecastYears: updatedCount,
        forecastYearsSource: forecastYearsSource,
      },
    };
    await dispatch(projectActions.updateProjectDraft({ project: updatedProject }));
  };

  const revertForecastYears = () => {
    setIsForecastYearRemovalModalVisible(false);
    formMethods.setValue('forecastYears', countOfForecastYears);
    notifyForecastYearsChangeInProgress(false);
  };

  const removeForecastYears = async () => {
    const updatedForecastYearsCount = getValues().forecastYears;
    await updateOpmForecastYears(updatedForecastYearsCount);
    setIsForecastYearRemovalModalVisible(false);
    notifyForecastYearsChangeInProgress(false);
  };

  const displayRevert = (rowSource: keyof OpmInputDto) => {
    if (
      (rowSource === 'evValueSource' && (isPwermAndOpm || isOpmOnly)) ||
      (isPwermAndOpm && rowSource !== 'evValueSource')
    ) {
      return opmInputs[rowSource] === OpmInputDataSource.Override;
    }
  };

  const handleRevert = (rowSource: keyof OpmInputDto) => {
    if (rowSource === 'evValue') {
      formMethods.setValue('evValue', getEvOrEquityValueFromCapitalStructure());
      submitData();
    } else if (rowSource === 'forecastYears') {
      formMethods.setValue('forecastYears', getMaxForecastYearsFromPwerm());
      handleForecastYearQuantityChange();
    }
  };

  return (
    <div>
      <table
        style={{ marginBottom: '1.6rem' }}
        className={`table-primary table-primary--zebra ${styles[`opm-table-header`]}`}>
        <thead>
          <tr>
            <th
              colSpan={2}
              className={`table-primary__cell--header-tertiary ${styles[`table-title`]}`}>
              {l('_KeyInputs')}
            </th>
          </tr>
        </thead>
        <tbody>
          {isErfBuildStructure && isPwermAndOpm && (
            <tr>
              <th className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
                {l('_CaseSelected', { valuationType: abbrevOpmValue })}
                <sup className={styles['required-field']}>*</sup>
              </th>
              <td>
                <GridSelectField
                  aria-label={l('_SelectorAriaLabel', { label: l('_Case') })}
                  className={`${styles['selector-dropdown']}`}
                  name="selectedCaseId"
                  data-testid="selectedCaseId"
                  options={caseSelectionOptions}
                  rules={{ ...getRequiredValidator() }}
                />
              </td>
            </tr>
          )}
          <tr>
            <th
              style={{ minWidth: `${TABLE_LABELS_COL_WIDTH_ALTERNATIVE}px` }}
              className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
              {l('_ValuationTypeSpotValue', { valuationType: abbrevOpmValue })}
              <sup className={styles['required-field']}>*</sup>
              {displayRevert('evValueSource') && (
                <SvgRevert
                  // stop the onBlur event occurring so we can control when the data is submitted
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => handleRevert('evValue')}
                  className={styles['revert-button']}
                />
              )}
            </th>
            <td>
              <GridTextField
                className={
                  opmInputs.evValueSource === OpmInputDataSource.Override
                    ? styles['revertable-value']
                    : ''
                }
                name="evValue"
                data-testid="evValue"
                alignment={Alignment.Right}
                rules={{ ...getRequiredValidator(), ...greaterThanValidator(0) }}
                {...numericBaseFieldFormattingProps}
              />
            </td>
          </tr>
          <tr>
            <th className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
              {l('_NumberOfForecastYears')}
              <sup className={styles['required-field']}>*</sup>
              {displayRevert('forecastYearsSource') && (
                <SvgRevert
                  // stop the onBlur event occurring so we can control when the data is submitted
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => handleRevert('forecastYears')}
                  className={styles['revert-button']}
                />
              )}
            </th>
            <td>
              <GridSelectField
                aria-label={l('_SelectorAriaLabel', { label: l('_Year') })}
                className={`${styles['selector-dropdown']}
                  ${
                    isPwermAndOpm && opmInputs.forecastYearsSource === OpmInputDataSource.Override
                      ? styles['revertable-value']
                      : ''
                  }
                `}
                name="forecastYears"
                data-testid="forecastYears"
                onBlur={(event) => handleForecastYearQuantityChange(event)}
                options={forecastYearsOptions}
                rules={{ ...getRequiredValidator() }}
              />
            </td>
          </tr>
          <tr>
            <th className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
              {l('_ValuationTypeVolatility', { valuationType: abbrevOpmValue })}
              <sup className={styles['required-field']}>*</sup>
            </th>
            <td>
              <GridTextField
                name="evVolatility"
                data-testid="evVolatility"
                rules={{
                  ...getRequiredValidator(),
                  ...percentageValidator,
                }}
                {...percentageFieldFormattingProps}
              />
            </td>
          </tr>
          <tr>
            <th className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
              {l(
                opmInputs.opmSimulatedValue ===
                  enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue)
                  ? '_RealWorldRateWacc'
                  : '_RealWorldRateCostOfEquity'
              )}
            </th>
            <td>
              <GridTextField
                name="realWorldRate"
                data-testid="realWorldRate"
                rules={{
                  ...greaterThanOrNullValidator(0),
                }}
                tooltipVariation={GridFieldTooltipVariation.Top}
                {...percentageFieldFormattingProps}
                alignment={Alignment.Right}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <ReduceYearsModal
        onCloseButtonClick={() => revertForecastYears()}
        onCancel={() => revertForecastYears()}
        onConfirm={() => removeForecastYears()}
        isOpen={isForecastYearRemovalModalVisible}
        type={ReduceYearsType.Forecast}
      />
    </div>
  );
};
