import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  amountWithDecimalRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import {
  currencyDenominationPair,
  enumKeyByValue,
  instrumentOwnersSortFn,
  instrumentKeysByTypeSortFn,
} from '@app/shared/helpers';
import SvgChevron from '@app/shared/icons/Chevron';
import { CapitalStructureCapTableEventDto } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import {
  Denomination,
  InstrumentType,
  OwnerType,
} from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './expand.module.scss';

interface ExpandCapTableProps {
  capTableEvent: CapitalStructureCapTableEventDto;
  expandedInstruments: Dictionary<boolean>;
  setExpandedInstruments: Dispatch<SetStateAction<Dictionary<boolean>>>;
}

export const ExpandCapTable: FC<ExpandCapTableProps> = ({
  capTableEvent,
  expandedInstruments,
  setExpandedInstruments,
}) => {
  const {
    details: { currency, denomination },
  } = useAppSelector((state) => state.project.projectDraft);

  const { l } = useLocale();
  const percentageValueHeader = l('_PrefixInPc', {
    prefix: currencyDenominationPair(currency, enumKeyByValue(Denomination, Denomination.Units)),
  });
  const percentageSharesHeader = l('_PrefixInPc', { prefix: l('_CountSymbol') });

  return (
    <table className={styles['table']}>
      <thead>
        <tr>
          <th />
          <th className={styles['upper-header']}>
            <CellValue
              strong
              alignment={Alignment.Right}
              value={currencyDenominationPair(currency, denomination)}
            />
          </th>
          <th className={styles['upper-header']}>
            <CellValue strong alignment={Alignment.Right} value={l('_CountSymbol')} />
          </th>
          <th className={styles['upper-header']}>{percentageValueHeader}</th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>{l('_TotalInvestmentSummary')}</th>
          <th>
            <CellValue {...amountRules} value={capTableEvent.summary.totalInvestment.total.value} />
          </th>
          <th>
            <CellValue
              {...amountWithDecimalRules}
              value={capTableEvent.summary.totalInvestment.total.shares}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(capTableEvent.summary.instrumentTypes)
          .sort((a, b) =>
            instrumentKeysByTypeSortFn(
              a[0] as keyof typeof InstrumentType,
              b[0] as keyof typeof InstrumentType
            )
          )
          .map(([instrumentTypeKey, values]) => {
            const instrumentType = InstrumentType[instrumentTypeKey as keyof typeof InstrumentType];
            return (
              <tr key={instrumentTypeKey}>
                <td>{instrumentType}</td>
                <td>
                  <CellValue {...amountRules} value={values.total.value} />
                </td>
                <td>
                  <CellValue {...amountWithDecimalRules} value={values.total.shares} />
                </td>
                <td>
                  <CellValue
                    {...enteredPercentageViewRules}
                    value={getCalculatedProjectValueInPercent(values.total.valuePercentage)}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
      <tbody>
        <tr>
          <td>
            <div className={styles['tbody-separator']} />
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>{l('_TotalInvestmentByHolders')}</th>
          <th>
            <CellValue {...amountRules} value={capTableEvent.summary.totalInvestment.total.value} />
          </th>
          <th>
            <CellValue
              {...amountWithDecimalRules}
              value={capTableEvent.summary.totalInvestment.total.shares}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(capTableEvent.summary.totalInvestment.owners)
          .sort((a, b) =>
            instrumentOwnersSortFn(a[0] as keyof typeof OwnerType, b[0] as keyof typeof OwnerType)
          )
          .map(([ownerKey, values]) => {
            const ownerType = OwnerType[ownerKey as keyof typeof OwnerType];
            return (
              <tr key={ownerKey}>
                <td>{ownerType}</td>
                <td>
                  <CellValue {...amountRules} value={values.value} />
                </td>
                <td>
                  <CellValue {...amountWithDecimalRules} value={values.shares} />
                </td>
                <td>
                  <CellValue
                    {...enteredPercentageViewRules}
                    value={getCalculatedProjectValueInPercent(values.valuePercentage)}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
      <tbody>
        <tr>
          <td>
            <div className={styles['tbody-separator']} />
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>{l('_InvestmentByInstrument')}</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(capTableEvent.summary.instrumentTypes)
          .sort((a, b) =>
            instrumentKeysByTypeSortFn(
              a[0] as keyof typeof InstrumentType,
              b[0] as keyof typeof InstrumentType
            )
          )
          .map(([instrumentTypeKey]) => {
            const instrumentType = InstrumentType[instrumentTypeKey as keyof typeof InstrumentType];

            const instrumentList = Object.entries(capTableEvent.instruments).filter(
              ([, instrument]) => instrument.type === instrumentTypeKey
            );

            return (
              <React.Fragment key={instrumentTypeKey + '-details'}>
                {instrumentType === InstrumentType.OrdinaryEquity && (
                  <>
                    <tr>
                      <th />
                      <th className={styles['upper-header']}>
                        <CellValue
                          strong
                          alignment={Alignment.Right}
                          value={currencyDenominationPair(currency, denomination)}
                        />
                      </th>
                      <th className={styles['upper-header']}>
                        <CellValue strong alignment={Alignment.Right} value={l('_CountSymbol')} />
                      </th>
                      <th className={styles['upper-header']}>{percentageSharesHeader}</th>
                    </tr>
                  </>
                )}
                <tr key={instrumentTypeKey + '-details'}>
                  <td>{instrumentType}</td>
                </tr>
                {instrumentList.map(([instrumentKey, instrument]) => {
                  return (
                    <React.Fragment key={instrumentType + '-' + instrument.narrative}>
                      <tr key={instrumentType + '-' + instrument.narrative}>
                        <td className={styles['instrument']}>
                          <div style={{ display: 'flex' }}>
                            <SvgChevron
                              onClick={() => {
                                setExpandedInstruments({
                                  ...expandedInstruments,
                                  [instrumentKey]: !expandedInstruments[instrumentKey],
                                });
                              }}
                              className={classNames(styles['chevron'], {
                                [styles['chevron__collapsed']]: !expandedInstruments[instrumentKey],
                                [styles['chevron__expanded']]: expandedInstruments[instrumentKey],
                              })}
                            />
                            {instrument.narrative}
                          </div>
                        </td>
                        <td>
                          <CellValue {...amountRules} value={instrument.total.value} />
                        </td>
                        <td>
                          <CellValue {...amountWithDecimalRules} value={instrument.total.shares} />
                        </td>
                        <td>
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              instrumentType === InstrumentType.OrdinaryEquity
                                ? instrument.total.sharePercentage
                                : instrument.total.valuePercentage
                            )}
                          />
                        </td>
                      </tr>
                      {expandedInstruments[instrumentKey] &&
                        Object.entries(instrument.owners)
                          .sort((a, b) =>
                            instrumentOwnersSortFn(
                              a[0] as keyof typeof OwnerType,
                              b[0] as keyof typeof OwnerType
                            )
                          )
                          .map(([ownerKey, owner]) => {
                            const ownerType = OwnerType[ownerKey as keyof typeof OwnerType];
                            return (
                              <React.Fragment key={instrument.narrative + '-' + ownerKey}>
                                <tr key={instrument.narrative + '-' + ownerKey}>
                                  <td className={styles['owner']}>{ownerType}</td>
                                  <td>
                                    <CellValue {...amountRules} value={owner.value} italics />
                                  </td>
                                  <td>
                                    <CellValue {...amountRules} value={owner.shares} italics />
                                  </td>
                                  <td>
                                    <CellValue
                                      {...enteredPercentageViewRules}
                                      value={getCalculatedProjectValueInPercent(
                                        instrumentType === InstrumentType.OrdinaryEquity
                                          ? owner.sharePercentage
                                          : owner.valuePercentage
                                      )}
                                      italics
                                    />
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td>
                    <div className={styles['tbody-separator']} />
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
      </tbody>
    </table>
  );
};
