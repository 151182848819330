import SvgWarning from '@app/shared/icons/Warning';
import styles from './sources-and-uses-content.module.scss';
import { FC } from 'react';
import { Tooltip } from '@app/shared/components/tooltip/Tooltip';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { amountValueFormatterProps, numberValueFormatter } from '@app/shared/formatters';
import { useLocale } from '@app/core/hooks/useLocale';

interface TotalSourcesProps {
  value: number;
  showWarning: boolean;
}

const TotalSources: FC<TotalSourcesProps> = ({ value, showWarning }) => {
  const { l } = useLocale();

  return (
    <table className="table-primary table-primary--fluid">
      <colgroup>
        <col />
        <col />
        <col />
        <col className="table-primary__col-action" />
      </colgroup>
      <tbody>
        <tr>
          <th
            className="table-primary__cell--header-secondary table-primary__cell--section-start-separator table-primary__cell--highlight"
            colSpan={2}>
            {l('_TotalSources')}
          </th>
          <td className="table-primary__cell--section-start-separator table-primary__cell--highlight table-primary__cell--strong table-primary__cell--actions">
            <div className={styles['total']}>
              {showWarning && (
                <Tooltip content="Total Sources and Total Uses do not match.">
                  <Button appearance={ButtonAppearance.CLEAN} size={ButtonSize.FLUID}>
                    <SvgWarning className={styles['total__icon']} />
                  </Button>
                </Tooltip>
              )}
              <div className={styles['total__value']}>
                {numberValueFormatter({ value, ...amountValueFormatterProps })}
              </div>
            </div>
          </td>
          <td className="table-primary__cell--section-start-separator table-primary__cell--highlight table-primary__cell--strong" />
        </tr>
      </tbody>
    </table>
  );
};

export default TotalSources;
