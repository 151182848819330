import React, {
  Children,
  cloneElement,
  FC,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDom from 'react-dom';
import styles from './tooltip.module.scss';
import classNames from 'classnames';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { TOOLTIP_OFFSET } from '@app/shared/constants/tooltips';

export enum TooltipPlacement {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

interface TooltipContentProps extends HTMLAttributes<HTMLElement> {
  content?: string | JSX.Element;
  placement?: TooltipPlacement;
  width?: string;
  tooltipVariation?: GridFieldTooltipVariation;
  useFixedPositioning?: boolean;
  setMinWidthToFitContent?: boolean;
  shouldTooltipRender?: boolean;
  shouldVerticallyCenterChildElement?: boolean;
}

interface ChildWithProps {
  props: ChildProps;
}

interface ChildProps {
  className: string;
}

export const Tooltip: FC<PropsWithChildren<TooltipContentProps>> = ({
  content,
  children,
  placement,
  className,
  style,
  tooltipVariation,
  width,
  useFixedPositioning,
  setMinWidthToFitContent,
  shouldTooltipRender = true,
  shouldVerticallyCenterChildElement = false,
  ...rest
}): JSX.Element => {
  const [topPosition, setTopPosition] = useState<number | undefined>(undefined);
  const [leftPosition, setLeftPosition] = useState<number | undefined>(undefined);
  const [shouldRenderTooltip, setShouldRenderTooltip] = useState<boolean>(false);
  const [maxTooltipWidth, setMaxTooltipWidth] = useState<number | undefined>(undefined);

  const tooltipRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldRenderTooltip) {
      getPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRenderTooltip]);

  const getPosition = () => {
    if (tooltipRef.current && triggerRef.current) {
      const targetElement = triggerRef.current.getBoundingClientRect();
      const tooltipElement = tooltipRef.current.getBoundingClientRect();

      if (!tooltipElement || !targetElement) {
        return;
      }

      const targetElementHeight = targetElement.height;
      const targetElementWidth = targetElement.width;
      const tooltipHeight = !tooltipElement.height ? window.innerHeight : tooltipElement.height;
      const tooltipWidth = !tooltipElement.width ? window.innerWidth : tooltipElement.width;

      const distanceFromLeftEdge = targetElement.left + window.scrollX;
      const maxTopBottomTooltipWidth = Math.min(
        (distanceFromLeftEdge + targetElementWidth / 2) * 2 - TOOLTIP_OFFSET * 2
      );
      const maxLeftTooltipWidth =
        maxTopBottomTooltipWidth / 2 - targetElementWidth / 2 - TOOLTIP_OFFSET * 2;

      switch (placement) {
        case TooltipPlacement.Left:
          setTopPosition(
            targetElement.top + targetElementHeight / 2 - tooltipHeight / 2 + window.scrollY
          );
          setLeftPosition(targetElement.left - tooltipWidth - TOOLTIP_OFFSET + window.scrollX);
          setMaxTooltipWidth(maxLeftTooltipWidth);
          break;
        case TooltipPlacement.Right:
          setTopPosition(
            targetElement.top + targetElementHeight / 2 - tooltipHeight / 2 + window.scrollY
          );
          setLeftPosition(targetElement.right + TOOLTIP_OFFSET + window.scrollX);
          break;
        case TooltipPlacement.Bottom:
          setTopPosition(targetElement.bottom + TOOLTIP_OFFSET + window.scrollY);
          setLeftPosition(targetElement.left + targetElementWidth / 2 + window.scrollX);
          setMaxTooltipWidth(maxTopBottomTooltipWidth);
          break;
        case TooltipPlacement.Top:
          setTopPosition(targetElement.top - tooltipHeight - TOOLTIP_OFFSET + window.scrollY);
          setLeftPosition(targetElement.left + targetElementWidth / 2 + window.scrollX);
          setMaxTooltipWidth(maxTopBottomTooltipWidth);
          break;
        default:
          break;
      }
    }
  };

  const childrenWithClassnames = Children.map(children as ChildWithProps[], (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        className: classNames(child.props.className, styles['tooltip__trigger']),
      });
    }
    return child;
  });

  const getStyles = (): React.CSSProperties => {
    let styleObj: React.CSSProperties = {};
    if (width) {
      styleObj = { ...styleObj, maxWidth: width, width: width, minWidth: 0 };
    }

    if (useFixedPositioning) {
      styleObj = {
        ...styleObj,
        position: 'fixed',
        left: leftPosition,
        top: topPosition,
      };

      if (maxTooltipWidth) {
        styleObj = { ...styleObj, maxWidth: maxTooltipWidth, minWidth: 0 };
      }

      if (placement === TooltipPlacement.Top) {
        styleObj = { ...styleObj, bottom: 'unset' };
      } else if (placement === TooltipPlacement.Left) {
        styleObj = { ...styleObj, right: 'unset' };
      }
    }

    return styleObj;
  };

  return !useFixedPositioning ? (
    <div className={classNames(className, styles['tooltip'])} style={style}>
      <>
        {childrenWithClassnames}
        {content === undefined ? (
          <></>
        ) : (
          <div
            style={getStyles()}
            className={classNames(styles['tooltip__content'], {
              [styles[`tooltip__content--${placement}`]]: placement,
              [styles[`tooltip__content--${tooltipVariation}`]]: tooltipVariation,
              [styles['tooltip__content--fit-content-min-width']]: setMinWidthToFitContent,
            })}
            ref={tooltipRef}
            {...rest}>
            {content}
          </div>
        )}
      </>
    </div>
  ) : (
    <div className={classNames(className, styles['tooltip'])} style={style}>
      <>
        <div
          className={classNames(styles['tooltip__full-size'], {
            // ensures the tooltip is vertically centered in the child element,
            // which is useful when the tooltip is position to the left or right
            [styles['tooltip__full-size--vertically-centered']]: shouldVerticallyCenterChildElement,
          })}
          ref={triggerRef}
          onMouseEnter={() => {
            // shouldTooltipRender resolves an issue where an overlay (e.g. Modal) prevents onMouseLeave from firing
            if (shouldTooltipRender) {
              setShouldRenderTooltip(true);
              getPosition();
            }
          }}
          onMouseLeave={() => {
            setShouldRenderTooltip(false);
            setTopPosition(undefined);
            setLeftPosition(undefined);
          }}>
          {children}
        </div>
      </>
      {content === undefined
        ? null
        : ReactDom.createPortal(
            <div
              style={{ ...getStyles(), display: topPosition ? 'block' : 'none' }}
              className={classNames(styles['tooltip__content'], {
                [styles[`tooltip__content--${placement}`]]: placement,
                [styles[`tooltip__content--${tooltipVariation}`]]: tooltipVariation,
                [styles['tooltip__content--fixed']]: useFixedPositioning,
                [styles['tooltip__content--fit-content-min-width']]: setMinWidthToFitContent,
              })}
              ref={tooltipRef}
              {...rest}>
              {content}
            </div>,
            document.body
          )}
    </div>
  );
};
