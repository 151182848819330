import React from 'react';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import Button from '@app/shared/components/button/Button';
import styles from '../build-structure-tab/build-structure.module.scss';
import {
  BuildStructureErrorCodes,
  CapitalStructureEventSeverity,
  OwnerType,
  TooltipWidth,
} from '@app/shared/models/contracts/enums/shared-enums';
import {
  CapitalStructureEventFaults,
  CapitalStructureInstrumentDefinitionDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { useLocale } from '@app/core/hooks/useLocale';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { translationKeys } from '@locale/setupI18n';
import { mapBuildStructureErrorToMessage } from './utils/EventBuilderHelpers';
import { useAppSelector } from '@app/core/hooks/redux-hooks';

interface FaultIconProps {
  severity: CapitalStructureEventSeverity;
  icon: JSX.Element;
  label: translationKeys;
  eventFaults: CapitalStructureEventFaults[];
  instrumentDefinitions: Record<string, CapitalStructureInstrumentDefinitionDto>;
  isWithinEditEventModal?: boolean;
}

const FaultIcon: React.FC<FaultIconProps> = ({
  severity,
  icon,
  label,
  eventFaults,
  instrumentDefinitions,
  isWithinEditEventModal = false,
}) => {
  const { l } = useLocale();
  const maxYearsBetweenEventsAndValuation = useAppSelector(
    (state) => state.uiValues.maxYearsBetweenEventsAndValuation
  );

  if (eventFaults.length === 0) {
    return null;
  }

  const filteredFaults = eventFaults.filter((fault: CapitalStructureEventFaults) => {
    if (
      isWithinEditEventModal &&
      (fault.errorCode === BuildStructureErrorCodes.CSB_MOVEMENT_HAS_NO_EFFECT ||
        fault.errorCode === BuildStructureErrorCodes.CSB_EVENT_NO_INSTRUMENTS)
    ) {
      return false;
    }
    return fault.severity === severity;
  });

  const groupedFaults = filteredFaults.reduce((acc, fault) => {
    const instrument = fault.instrument ?? 'no-instrument';
    if (!acc[instrument]) {
      acc[instrument] = [];
    }
    acc[instrument].push(fault);
    return acc;
  }, {} as Record<string, CapitalStructureEventFaults[]>);

  if (Object.keys(groupedFaults).length === 0) {
    return null;
  }

  return (
    <Tooltip
      placement={TooltipPlacement.Left}
      width={`${TooltipWidth.EventFault}px`}
      // applying useFixedPositioning only outside of the modal as the z-index of the modal is higher than the tooltip
      useFixedPositioning={!isWithinEditEventModal}
      content={
        <>
          <div>
            <b>{l(label)}</b>
          </div>
          {Object.entries(groupedFaults).map(([instrument, faults], index) => (
            <div key={index}>
              {instrument !== 'no-instrument' && (
                <>
                  <br />
                  <b>{l('_Instrument')}: </b>
                  {instrumentDefinitions[instrument]?.instrumentNarrative ?? 'N/A'}
                  <br />
                </>
              )}
              <ul className={styles['fault-list']}>
                {faults.map((fault, faultIndex) => (
                  <li key={faultIndex}>
                    {fault.ownerType
                      ? `${OwnerType[fault.ownerType as keyof typeof OwnerType]}: `
                      : ''}
                    {mapBuildStructureErrorToMessage(fault, { maxYearsBetweenEventsAndValuation })}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      }>
      <Button
        startIcon={icon}
        appearance={ButtonAppearance.DEFAULT_SECONDARY}
        size={ButtonSize.SMALL}
      />
    </Tooltip>
  );
};

export default FaultIcon;
