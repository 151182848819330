import { FC } from 'react';
import PwcNavLink from '@app/shared/routing/PwcNavLink';
import styles from './admin-menu-item.module.scss';

export const PARENT_CLASSNAME = 'menu-item';

interface AdminMenuItemProps {
  route: string;
  title: string;
}

export const AdminMenuItem: FC<AdminMenuItemProps> = ({ route, title }): JSX.Element => {
  const Link = () => {
    const navLink = (
      <span>
        <PwcNavLink className={styles[PARENT_CLASSNAME]} route={route} data-testid={route}>
          {title}
        </PwcNavLink>
      </span>
    );

    return navLink;
  };

  return <Link />;
};
