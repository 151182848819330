import { useLocale } from '@app/core/hooks/useLocale';
import styles from './admin-navigation-sidebar.module.scss';
import Sidebar, { SidebarModifier } from '@app/shared/components/sidebar/Sidebar';
import { FC } from 'react';
import { RouteItem } from '@app/Routes';
import { RouteConstants } from '@app/modules/admin/RouteConstants';
import AdminPagesList from '@app/modules/admin/admin-pages-list/AdminPagesList';

export const PARENT_CLASSNAME = 'admin-navigation';

const AdminNavigationSidebar: FC = (): JSX.Element => {
  const { l } = useLocale();

  const routes: RouteItem[] = [
    {
      route: RouteConstants.UserManagement,
      title: l('_UserManagement'),
    },
  ];

  return (
    <Sidebar data-testid="admin-sidebar" sidebarModifier={SidebarModifier.Primary}>
      <div
        className={`${styles[`${PARENT_CLASSNAME}__heading`]} ${
          styles[`${PARENT_CLASSNAME}__heading--no-border-no-margin`]
        }`}>
        {l('_AdminPanel')}
      </div>
      <AdminPagesList routes={routes} />
    </Sidebar>
  );
};

export default AdminNavigationSidebar;
