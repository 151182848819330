import { SVGProps } from 'react';
const SvgHideIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 25.5 22.5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1.556 0.0479736L24.476 22.872L23.948 23.424L23.42 23.952L0.5 1.10397L1.556 0.0479736ZM4.652 7.36797L5.708 8.42397L2.684 11.304L7.868 16.224C8.812 17.12 9.924 17.688 11.204 17.928C12.484 18.168 13.724 18.064 14.924 17.616L16.076 18.744C15.084 19.208 14.028 19.472 12.908 19.536C11.788 19.584 10.708 19.424 9.668 19.056C8.596 18.688 7.652 18.112 6.836 17.328L0.5 11.304L4.652 7.36797ZM18.164 5.27997L24.5 11.304L19.484 16.056L18.404 15.024L22.316 11.304L17.132 6.35997C16.38 5.63997 15.5 5.13597 14.492 4.84797C13.5 4.54397 12.484 4.47197 11.444 4.63197C10.42 4.77597 9.484 5.14397 8.636 5.73597L7.532 4.67997C8.572 3.91197 9.732 3.41597 11.012 3.19197C12.308 2.95197 13.572 3.01597 14.804 3.38397C16.1 3.75197 17.22 4.38397 18.164 5.27997ZM8.756 11.448L12.356 15.048H12.308C11.668 15.016 11.076 14.84 10.532 14.52C10.004 14.184 9.58 13.752 9.26 13.224C8.94 12.68 8.772 12.088 8.756 11.448ZM12.5 7.53597C13.172 7.53597 13.796 7.70397 14.372 8.03997C14.948 8.37597 15.404 8.83197 15.74 9.40797C16.076 9.98397 16.244 10.616 16.244 11.304C16.244 11.8 16.156 12.272 15.98 12.72L14.732 11.544C14.748 11.496 14.756 11.416 14.756 11.304C14.756 10.68 14.532 10.152 14.084 9.71997C13.652 9.27197 13.124 9.04797 12.5 9.04797C12.34 9.04797 12.22 9.05597 12.14 9.07197L10.916 7.89597C11.444 7.65597 11.972 7.53597 12.5 7.53597Z"
      fill="#474747"
    />
  </svg>
);
export default SvgHideIcon;
